import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';

export const SupportSuccessNotification =
({onBackToDashboard}: {onBackToDashboard: () => void,}) =>
  <div className='flex size-full flex-col items-center justify-center gap-2'>
    <Svgicon className='size-4 text-main-700' id='check-circle-2f' />
    <div className='flex flex-col items-center gap-1'>
      <div className='flex flex-col items-center gap-0.25'>
        <span className='flex flex-col items-center gap-0.25 text-title-3'>
          Thank you for getting in touch!
        </span>
        <span className='text-font-2 text-black-700'>We will come back to you soon!</span>
      </div>
      <Button onClick={onBackToDashboard}>Back to dashboard</Button>
    </div>
  </div>;
