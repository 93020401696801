import ChevronSvg from '../../assets/icons/global/blue-chevron.svg?react';
import { GlobalElementBusContext } from '../../contexts';
import BooleanButton from 'components/BooleanButton';
import { type FC,
  type ReactNode,
  useContext,
  useEffect,
  useRef} from 'react';
import { createPortal } from 'react-dom';
import VARIABLES from 'styles/variables';

export type FullScreenModalProps = {
  children: ReactNode,
  close: () => void,
  isOpen: boolean,
};

export const FullScreenModal: FC<FullScreenModalProps> = ({ isOpen,
  close,
  children,
}) => {
  const {
    addRef,
  } = useContext(GlobalElementBusContext);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    addRef(containerRef);
  }, [
    addRef,
    containerRef,
  ]);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div
      className='fixed inset-0 z-[12] overflow-auto rounded-main bg-black-100'
      ref={containerRef}
    >
      <div className='flex h-screen flex-col gap-7 px-7 py-8'>
        <div className='flex items-center gap-[1rem]'>
          <BooleanButton
            onClick={close}
            style={{
              background: VARIABLES.booleanButtonInactiveColor,
              borderRadius: '150px',
              borderWidth: '0px',
              cursor: 'pointer',
              height: '2rem',
              width: '78px',
            }}
            testName='close-fullscreen-table-view'
          >
            <div
              className='flex items-center gap-[0.5rem]'
              style={{ color: VARIABLES.booleanButtonContentInactiveColor }}
            >
              <ChevronSvg />
              Back
            </div>
          </BooleanButton>
          <h1 className='m-0 text-title-3 landscape:hidden'>Rotate your device</h1>
        </div>
        {children}
      </div>
    </div>,
    document.body,
  );
};
