import './Modal.scss';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { type CSSProperties} from 'react';
import { useEffect } from 'react';
import { isMobile } from 'services/mobile';

export type ModalProps = {
  children?: JSX.Element | JSX.Element[] | string,
  className?: string[] | string,
  onSubmit?: () => void,
  onToggle?: (value: boolean) => void,
  showCloseIcon?: boolean,
  size?: 'fit-content' | 'l' | 'm' | 's' | 'xs',
  style?: CSSProperties,
  toggle?: boolean,
};

export const Modal = ({
  className,
  toggle = true,
  onToggle = () => {},
  onSubmit,
  showCloseIcon = false,
  size = 'fit-content',
  children,
  style,
}: ModalProps) => {
  let classes = '';
  if (className) {
    classes = Array.isArray(className) ? className.join(' ') : className;
  }

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onToggle(false);
      }
    };

    const handleEnterKey = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && onSubmit) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (toggle) {
      document.addEventListener('keydown', handleEscapeKey);
      document.addEventListener('keydown', handleEnterKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('keydown', handleEnterKey);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    toggle,
    onToggle,
  ]);

  if (!toggle) {
    return null;
  }

  return (
    <div className={`modal-window ${classes}`} style={style || {}}>
      <div className='modal-container'>
        <div className={`modal-card size-${size}`}>
          {showCloseIcon &&
            <div className='close-circle' onClick={() => onToggle(false)}>
              {isMobile() ?
                <div className='svg-close'>
                  <Svgicon id='menu-close' />
                </div> :
                <div className='svg-close'>
                  <Svgicon id='close-1f' />
                </div>
              }
            </div>
          }
          {children}
        </div>
      </div>
    </div>
  );
};
