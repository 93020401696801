import {firmActionTypes} from '../actions';

const initialState = {
  accounts: [],
  accountsLoading: false,
  details: {},
  firmLoading: false,
  funds: [],
  fundsLoading: false,
  notification: false,
  verify: false,
};

// eslint-disable-next-line default-param-last
export const firm = (state = initialState, {type,
  payload}) => {
  switch (type) {
  case firmActionTypes.GET_FIRM_FUNDS_SUCCESS:
    return {...state,
      funds: payload};
  case firmActionTypes.GET_FIRM_ACCOUNTS_SUCCESS:
    return {...state,
      accounts: payload};
  case firmActionTypes.GET_FIRM_NOTIFICATION:
    return {...state,
      notification: payload};
  case firmActionTypes.FIRM_LOADING:
    return {...state,
      firmLoading: payload};
  case firmActionTypes.FUNDS_LOADING:
    return {...state,
      fundsLoading: payload};
  case firmActionTypes.ACCOUNTS_LOADING:
    return {...state,
      accountsLoading: payload};
  case firmActionTypes.GET_FIRM_VERIFY:
    return {...state,
      verify: payload};
  case firmActionTypes.GET_FIRM_DETAILS:
    return {...state,
      details: payload};
  case firmActionTypes.RESET:
    return {...initialState};
  default:
    return state;
  }
};
