import VARIABLES from '../styles/variables';

export const inputHighlight = (event) => {
  if (event?.target?.value?.length) {
    event.target.parentElement.style.border =
      `2px solid ${VARIABLES.borderColorActive}`;
    event.target.parentElement.style.borderRadius = '3px';
  } else {
    event.target.parentElement.style.border =
      `2px solid ${VARIABLES.borderColor}`;
  }
};

export const removeSpaces = (event) => {
  if (event.keyCode === 32) {
    event.preventDefault();
  }
};
