import { type FC,
  useCallback,
  useMemo} from 'react';
import { createContext,
  useEffect,
  useState } from 'react';

export type RoomObserver = (focused: boolean) => void;

export enum FIRABLE_FILTERS {
  ACCOUNT_SELECT = 'AccountSelect',
  FIRM_DROPDOWN = 'FirmDropDown',
  FUND_DROPDOWN = 'FundDropDown',
  UNOCCUPIED = 'unoccupied'
}

type FiltersControllerContextType = {
  changeFocusedOnController: (nameOfObserver: FIRABLE_FILTERS) => void,
  registerRoomObserver: (
    observer: RoomObserver, nameOfObserver: FIRABLE_FILTERS) => void,
  unregisterRoomObserver: (nameOfObserver: FIRABLE_FILTERS) => void,
};

type FiltersControllerProviderProps = {
  children?: React.ReactNode,
};

const FILTERS_CONTROLLER_INITIAL_STATE = {
  changeFocusedOnController: () => {},
  registerRoomObserver: () => {},
  unregisterRoomObserver: () => {},
};

export const FiltersControllerContext = createContext<
FiltersControllerContextType>(FILTERS_CONTROLLER_INITIAL_STATE);

export const FiltersControllerProvider: FC<
FiltersControllerProviderProps> = ({children}) => {
  const [
    occupyingObserver,
    setOccupyingObserver,
  ] = useState(FIRABLE_FILTERS.UNOCCUPIED);
  const [
    observerMap,
    setObserverMap,
  ] = useState<Map<FIRABLE_FILTERS, RoomObserver>>(new Map());
  const [
    observerFocusedMap,
    setObserverFocusedMap,
  ] = useState<Map<FIRABLE_FILTERS, boolean>>(new Map());

  const notifyAllObservers = useCallback((
    observerFocusStateMap: Map<FIRABLE_FILTERS, boolean>,
  ) => {
    for (const [
      name,
      focused,
    ] of observerFocusStateMap.entries()) {
      const observer = observerMap.get(name);
      if (!observer) {
        // should never come here
        continue;
      }

      observer(focused);
      continue;
    }
  }, [
    observerMap,
  ]);

  useEffect(() => {
    notifyAllObservers(observerFocusedMap);
  }, [
    notifyAllObservers,
    observerFocusedMap,
  ]);

  const setNewOccupyingObserver = useCallback(
    (nameOfObserver: FIRABLE_FILTERS) => {
      setOccupyingObserver(nameOfObserver);

      const keys = Array.from(observerFocusedMap.keys());
      const newMap: Map<FIRABLE_FILTERS, boolean> = new Map();

      if (nameOfObserver === FIRABLE_FILTERS.UNOCCUPIED) {
        return setObserverFocusedMap(() => {
          for (const key of keys) {
            newMap.set(key, false);
          }

          return newMap;
        });
      }

      return setObserverFocusedMap(() => {
        for (const key of keys) {
          if (key === nameOfObserver) {
            newMap.set(key, true);
          } else {
            newMap.set(key, false);
          }
        }

        return newMap;
      });
    }, [
      observerFocusedMap,
    ],
  );

  const registerRoomObserver = useCallback((
    observer: RoomObserver, nameOfObserver: FIRABLE_FILTERS,
  ) => {
    setObserverMap(
      new Map(observerMap.set(nameOfObserver, observer)),
    );

    setObserverFocusedMap(
      new Map(observerFocusedMap.set(nameOfObserver, false)),
    );
  }, [
    observerFocusedMap,
    observerMap,
  ]);

  const unregisterRoomObserver = useCallback(
    (nameOfObserver: FIRABLE_FILTERS) => {
      observerMap.delete(nameOfObserver);
      observerFocusedMap.delete(nameOfObserver);
      setObserverMap(
        new Map(observerMap),
      );
      setObserverFocusedMap(
        new Map(observerFocusedMap),
      );
    }, [
      observerFocusedMap,
      observerMap,
    ],
  );

  const changeFocusedOnController = useCallback(
    (nameOfObserver: FIRABLE_FILTERS) => {
      if (nameOfObserver === FIRABLE_FILTERS.UNOCCUPIED) {
        setNewOccupyingObserver(nameOfObserver);

        return;
      }

      if (
        occupyingObserver === FIRABLE_FILTERS.UNOCCUPIED ||
      occupyingObserver !== nameOfObserver) {
      // either controller is unoccupied or observer calling is not current occupyingObserver

        // set occupyingObserver to be name of new observer and ensure all observers are of right state
        setNewOccupyingObserver(nameOfObserver);

        return;
      }

      // occupyingObserver is observer calling, make occupyingObserver UNOCCUPIED_CONTROLLER
      setNewOccupyingObserver(FIRABLE_FILTERS.UNOCCUPIED);
    }, [
      occupyingObserver,
      setNewOccupyingObserver,
    ],
  );

  const values = useMemo(() => ({
    changeFocusedOnController,
    registerRoomObserver,
    unregisterRoomObserver,
  }), [
    changeFocusedOnController,
    registerRoomObserver,
    unregisterRoomObserver,
  ]);

  return (
    <FiltersControllerContext.Provider value={values}>
      {children}
    </FiltersControllerContext.Provider>
  );
};

