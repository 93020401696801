// eslint-disable-next-line import/no-unassigned-import
import '../../Authorization/antd.scss';
import userApi from '../../../api/user';
import {
  Blocks,
  FormList,
  SuccessNotification,
  VerifyInfo,
} from '../../../components';
import style from './settings.module.scss';
import authApi from 'api/auth';
import {Spinner} from 'components';
import {useDocumentTitle} from 'hooks';
import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {notificationsService} from 'services';
import {
  CHANNEL_MAP,
  getItemsFromStorage,
} from 'types';
import {isEmail} from 'validator';

let savedData = {};
let savedValue = {};

export const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {auth,
    firm,
  } = useSelector((state) => state);
  const [
    data,
    setData,
  ] = useState(null);
  const [
    key,
    setKey,
  ] = useState('');
  const [
    isLoading,
    setIsLoading,
  ] = useState(false);

  const refreshToken = sessionStorage.getItem('refresh_token');

  const {loginAs} = getItemsFromStorage([
    'loginAs',
  ], sessionStorage);

  const [
    currentVerificationChannel,
    setCurrentVerificationChannel,
  ] = useState('');

  useDocumentTitle('Settings');

  useEffect(() => {
    if (Object.keys(auth).length) {
      setData({
        email: auth.email,
        phone: auth.phone ? '+' + auth.phone : '',
      });
      setCurrentVerificationChannel(
        CHANNEL_MAP.get(auth.verificationChannel)?.display,
      );
    }
  }, [
    auth,
  ]);

  // eslint-disable-next-line unicorn/prefer-query-selector
  const error = document.getElementsByClassName(style.error);

  const handleOnChange = (event) => {
    setData((previous) => ({
      ...previous,
      [event.target.name]: event.target.value.replaceAll(' ', ''),
    }));
  };

  const collectDataForNotification = (name) => {
    if (data) {
      switch (name) {
      case 'email': {
        savedValue = {Email: data.email};
        return;
      }

      default: {
        savedValue = {Phone: data.phone};
      }
      }
    }
  };

  const onChangeButton = (input) => {
    if (data) {
      input.value = data[input.name];
      savedData = JSON.parse(JSON.stringify(data));
    }
  };

  const onCancelButton = (name) => {
    collectDataForNotification(name);

    setData((previous) => ({
      ...previous,
      [name]: savedData[name],
    }));
    error[0].innerText = '';
  };

  // eslint-disable-next-line consistent-return
  const onSaveButton = async (name) => {
    if (data) {
      setIsLoading(true);
      savedData = JSON.parse(JSON.stringify(data));
      collectDataForNotification(name);
      switch (name) {
      case 'email':
        if (data.email && isEmail(data.email)) {
          if (auth.email !== data.email) {
            setKey('email');
            await userApi.editMail({email: data.email});
          }
        } else {
          error[0].innerText = 'Invalid email.';
        }

        break;
      case 'phone':
        if (loginAs || loginAs === 'true') {
          notificationsService.error('You cannot change a user\'s phone number as an admin.');
          return;
        }

        if ('+' + auth.phone !== data.phone) {
          setKey('phone');
          await dispatch(authApi.changePhoneNumber({
            loginAs,
            phoneNumber: data.phone,
            refreshToken,
          }, navigate));
        }

        break;
      default:
        setIsLoading(false);
      }
    }
  };

  const onClickPassword = () => navigate('/firm/change-password');

  const onClickChangeAuth = () => {
    if (loginAs || loginAs === 'true') {
      notificationsService.error('You cannot change a user\'s authentication method as an admin.');
      return;
    }

    const accessToken = auth.accessToken;
    const parameters = new URLSearchParams();
    parameters.append('token', accessToken);
    navigate(`/auth/select-auth-method?${parameters.toString()}`);
  };

  return (
    <div className={[
      style.container,
    ].join(' ')}
    >
      {
        firm.notification ?
          <SuccessNotification data={savedValue} visible /> :
          null
      }
      {(firm.verify || Boolean(sessionStorage.getItem('verify'))) && <VerifyInfo
        data={savedData}
        requestKey={key}
        setData={setData}
      />}
      {
        isLoading ?
          <Spinner /> :
          <div className={[
            style.block,
          ].join(' ')}
          >
            <Blocks title='Account settings'>
              <div className={style.form}>
                <div className={style.error} />
                <FormList
                  defaultValue={data?.email}
                  label='Email address'
                  name='email'
                />
                <FormList
                  defaultValue={data?.phone}
                  label='Mobile number'
                  name='phone'
                  onCancel={onCancelButton}
                  onChange={handleOnChange}
                  onChangeBtn={onChangeButton}
                  onSave={onSaveButton}
                />
                <FormList
                  label='Password'
                  name='password'
                  onChangeBtn={onClickPassword}
                  text='•••••••••'
                />
                <FormList
                  defaultValue={currentVerificationChannel}
                  hasInputField={false}
                  label='Verification method'
                  name='verification-method'
                  onChangeBtn={onClickChangeAuth}
                />

              </div>
            </Blocks>
            <Blocks
              onClick={() => navigate('/firm/about')}
              title='About'
            />
            <Blocks
              onClick={() => navigate('/firm/privacy')}
              title='Privacy policy'
            />
          </div>
      }
    </div>

  );
};
