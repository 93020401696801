// eslint-disable-next-line eslint-rules/no-bad-svg-import
import ChevronLeft from '../../../assets/icons/global/chevron-left.svg?react';
import Logo from '../../../assets/images/orca-logo.svg?react';
import style from './privacy.module.scss';
import {Blocks} from 'components';
import {useDocumentTitle} from 'hooks';
import {useNavigate} from 'react-router-dom';

export const Privacy = () => {
  const navigate = useNavigate();

  useDocumentTitle('Privacy');

  return <div className={style.container}>
    <Blocks
      onClick={() => navigate('/firm/settings')}
      title={
        <>
          {/* eslint-disable-next-line react/forbid-component-props */}
          <ChevronLeft style={{marginRight: 10}} />
          Privacy Policy
        </>
      }
    >
      <div className={style['text-center']}>
        <div>
          <Logo />
          <h2 className={style.title}>Orca Data</h2>
          <h2 className={style.title}>Privacy Policy</h2>
        </div>
      </div>
      <h3>Orca’s Privacy Pledge</h3>
      <p>
        Orca’s fundamental goal is to provide asset managers around the
        world with comprehensive fund administration solutions. Our products
        and services deliver cross-jurisdictional reporting capabilities,
        fund administration services and more to enable your fund to focus
        on what matters. Orca is committed to respecting your privacy,
        keeping your data safe and keeping the decision as to how your
        information is being used and shared in your hands.
      </p>
      <p>Our privacy pledge:</p>
      <ul>
        <li>
          Orca will only collect data that is of legitimate interest and
          which is useful and necessary for improving its products, services
          and your experience.
        </li>
        <li>
          Orca will always maintain fairness and transparency with regards
          to how we collect and process data at all times.
        </li>
        <li>
          Orca will never sell your data and will only share your personal
          data when given your explicit consent to do so for a specific
          reason.
        </li>
        <li>
          Orca will never make decisions based solely on automated
          processing or profiling of data.
        </li>
        <li>
          To learn more about the data collected by Orca, please read our
          full Privacy Policy below.
        </li>
      </ul>
      <p>
        To learn more about the data collected by Orca, please read our
        full Privacy Policy below.
      </p>
      <h3>Data collection and processing</h3>
      <p>
        Subject to the relevant local laws, Orca may process certain
        information about you if this information is of Legitimate Interest.
        This data may include what is known as Personal Data under GDPR
        and/or Personally Identifiable Information (PII).
      </p>
      <p>
        Orca processes your personal information in line with its Privacy
        Policy and Terms and Conditions to provide you with the information
        that is of most interest to you and deliver the services you have
        requested. Orca does not carry out automated profiling and will not
        make any decisions based on the automated processing of data.{' '}
      </p>
      <h3>Sensitive Data</h3>
      <p>
        Orca does not seek to collect special categories of personal data
        (“sensitive data”), including but not limited to data revealing
        racial, or ethnic origin, political opinions, gender, religious or
        philosophical beliefs, health, genetic and biometric data, or trade
        union memberships. Please note that, by freely providing Orca with
        an unsolicited piece of sensitive data, subject to the relevant
        laws, you are consenting Orca to use the said data in conformity
        with this Privacy Policy.
      </p>
      <h3>Cross Boarder Transfers and Third Parties</h3>
      <p>Orca may share your personal data with:</p>
      <ul>
        <li>
          Any Orca subsidiaries and affiliates part of the Orca network of
          companies;
        </li>
        <li>
          Orca service providers (third parties) who process information on
          Orca's behalf, including providers of information technology,
          communication, identity management, website hosting and
          management, data analysis, data back-up, security and storage
          services;
        </li>
        <li>
          Other third parties where you have given consent to the disclosure
          (either to Orca or the third party)
        </li>
      </ul>
      <p>
        Orca operates in compliance with the relevant data protection laws
        and regulations. Orca services providers are legally obliged to
        follow Orca’s instructions in respect of the use of your personal
        information and they must comply with appropriate security measures
        to protect your personal information. The transfer of personal
        information to other countries is based on a business need or to
        comply with applicable laws.
      </p>
      <p>
        Personal information stored or processed in a foreign jurisdiction
        may be accessed under a lawful order made in that jurisdiction. By
        providing your information to Orca in the course of your access and
        use of the site or the services, you agree that your personal
        information, for the purposes explained in this Privacy Notice, may
        be transferred to and stored in other countries where Orca and
        their service providers conduct business, which may not have the
        same data protection laws as the country in which you reside.
      </p>
      <h3>Information Security</h3>
      <p>
        Orca is committed to protecting the security of your personal
        information. Orca has implemented generally accepted standards of
        technology and operational security in order to protect personal
        data and information from loss, misuse, alteration or destruction.
        Orca ensures that all appropriate confidentiality obligations,
        technical, and organizational security measures are in place to
        prevent any unauthorized or unlawful disclosure or processing of
        such information and data and the accidental loss or destruction of
        or damage to such information and data.
      </p>
      <p>
        Only authorized Orca employees and service providers are allowed to
        access the data, and they are legally obliged to ensure
        confidentiality of this information.
      </p>
      <h3>Data Retention</h3>
      <p>
        The data you submit to Orca will only be retained for as long as is
        required for the purposes for which it was collected or as required
        by the relevant laws. When the data is no longer necessary to the
        purpose for which it was collected, Orca will proceed to erase all
        your personal data held in its systems without undue delay.
      </p>
      <h3>Right of Access and Correction</h3>
      <p>
        Subject to the relevant laws, you have the right to know what
        personal information we hold about you. If you would like a copy of
        the information you are entitled to, you may contact Orca by email
        to <a href='mailto: helpdesk@servc.co.il'>helpdesk@servc.co.il</a>.
        Please note that for the execution of this request, you must provide
        us with a clear identification of yourself and the information you
        require.
      </p>
      <p>
        Also, you have the right to request rectification of inaccurate
        personal data concerning you. If you would like to exercise this
        right, you may provide Orca with a supplementary statement by email
        to <a href='mailto: helpdesk@servc.co.il'>helpdesk@servc.co.il</a>.
        Please note Orca does not assume responsibility for verifying the
        ongoing accuracy of the personal information provided.
      </p>
      <h3>Right to be Forgotten</h3>
      <p>
        If at any point you wish to withdraw your consent from Orca holding
        your data you can contact us and request this via the following
        email address:{' '}
        <a href='mailto: helpdesk@servc.co.il'>helpdesk@servc.co.il</a>
      </p>
      <h3>Legal Disclosure</h3>
      <p>
        Orca may disclose your personal information to law enforcement,
        regulatory, or other government agencies or to other third parties
        are required by, and in accordance with, applicable laws or
        regulations.
      </p>
      <h2>Contact</h2>
      <p>
        If you have any questions about this Privacy Policy, you may contact
        us via{' '}
        <a href='mailto: helpdesk@servc.co.il'>helpdesk@servc.co.il</a>
      </p>
      <h2>Data Protection Notice</h2>
      <h3>Introduction</h3>
      <p>
        At Orca we take data privacy seriously and that with Data
        Protection Laws, individuals have more transparency and stronger
        rights regarding their Personal Data in turn giving you more control
        over how organizations use and manage your data.
      </p>
      <p>
        This Data Protection Notice explains what personal data is
        collected, the purposes for which it is used, the third parties to
        whom it may be disclosed and the data protection rights of
        individuals (“Data Subjects”). “Data Protection Laws” includes the
        GDPR and any applicable laws regarding the processing, privacy and
        use of Personal Data as applicable to Clients and Orca, including
        but not limited to the laws and regulations of the Cayman Islands
        (DPL).
      </p>
      <p>
        Orca Ltd., its affiliates and subsidiaries (“Orca”) as well as our
        service providers, may collect and use your Personal Data for our
        legitimate business purposes.
      </p>
      <h3>Why does Orca collect data?</h3>
      <p>We collect data for legitimate business purposes which include:</p>
      <ul>
        <li>
          to validate authorized signatories for processing agreements and
          transactions;
        </li>
        <li>
          to contact nominated individuals in connection with transactions
          and contractual agreements;
        </li>
        <li>
          to respond to enquiries and fulfil requests from you/our clients,
          service providers and/or relevant third parties who may require
          information for providing services and to administer account(s)
          and manage our relationships;
        </li>
        <li>
          to verify an individual’s identity and/or location (or the
          identity or location of our client’s or service provider’s
          representative or agent) in order to allow access to relevant
          client accounts or conduct online transactions;
        </li>
        <li>to protect the security of accounts and Personal Data;</li>
        <li>
          for risk management/audit, compliance with our legal and
          regulatory obligations and for fraud detection, prevention and
          investigation, including “know your customer”, anti-money
          laundering, conflict and other necessary onboarding and ongoing
          client checks, due diligence and verification requirements, credit
          checks, credit risk analysis, compliance with sanctions procedures
          or rules, and tax reporting;
        </li>
        <li>
          to provide, and perform our obligations with respect to the
          services provided or otherwise in connection with fulfilling
          instructions; and
        </li>
        <li>
          to comply with applicable law including treaties or agreements
          with or between foreign or domestic governments (including in
          relation to tax reporting laws), (which may include laws outside
          the country you are located in), to respond to requests from
          public and government authorities (which may include authorities
          outside your country), to cooperate with law enforcement,
          governmental, regulatory, securities exchange or other similar
          agencies.
        </li>
      </ul>
      <h3>What personal information does Orca collect?</h3>
      <p>
        We collect information about you when you provide it to us, when you
        use our services, and when other sources provide it to us, as
        further described below.{' '}
      </p>
      <p>
        The kinds of personal information that Orca may collect and process
        includes (but is not limited to):
      </p>
      <ul>
        <li>
          contact details, including name, address, email addresses and
          telephone numbers;
        </li>
        <li>
          information required by Orca to meet legal and regulatory
          requirements in respect of anti-money laundering legislation,
          including personal details such as gender, date of birth, passport
          number(s), other government issued number(s), nationality, images
          of passports, IDs and driving licenses, signatures, occupation,
          source of funds and source of wealth, criminal records;
        </li>
        <li>
          information required by Orca to meet legal and regulatory
          requirements relating to the automatic exchange of tax information
          (e.g. US FATCA and OECD CRS), including details of tax residency,
          tax classification and tax identification numbers;
        </li>
        <li>
          financial information, including billing address, bank account
          numbers, instruction records, transaction details, counterparty
          details, and specimen signatures;
        </li>
        <li>
          the details of your fund, its investors and investments on the
          Fund administration software, the portfolio metrics we track in
          the Portfolio management software, investor documents and contact
          details provided by either you or your fund shared on the Investor
          portal
        </li>
        <li>
          information of how and when you access the portal and its
          documents (e.g. page interaction information, records of
          activities such as downloads, views and access data)
        </li>
        <li>
          information of how and when you interacted with emails sent to you
          by the Orca portal from our third-party providers
        </li>
        <li>
          details of meetings and telephone calls or any other interactions
          with our offices and employees; and
        </li>
        <li>
          any other information you may provide to us or might be provided
          to us by our clients, third parties etc.
        </li>
      </ul>
      <h3>Disclosure and Sharing of Data</h3>
      <p>
        Personal Data may be disclosed to third parties in connection with
        the services we are providing. The recipients of such information
        will depend on the services that are being agreed and provided.
      </p>
      <p>
        Subject to any restrictions around confidentiality may include
        disclosures:
      </p>
      <ul>
        <li>
          to affiliates and subsidiaries of Orca for the purposes described
          in this Data Protection Notice;
        </li>
        <li>
          to our third-party service providers who provide services such as
          website hosting, data analysis, payment processing, order
          fulfillment, information technology and related infrastructure
          provision, customer service, email delivery, auditing and other
          services;
        </li>
        <li>
          to third party experts and advisors (including external legal
          counsel, notaries, auditors and tax advisors);
        </li>
        <li>
          to payment, banking and communication infrastructure providers
          including SWIFT, financial institutions or intermediaries with
          which we may have dealings (including correspondent banks),
          insurers/insurance brokers, central counterparties, clearing
          houses, clearing and settlement systems, exchanges, trading
          platforms, regulated markets, credit institutions and other
          service providers assisting on transactions;
        </li>
        <li>
          to third party storage providers and trade data repositories;
        </li>
        <li>
          to third party distribution platforms and to operators of private
          or common carrier communications or transmission facilities, time
          sharing suppliers and mail or courier services;
        </li>
        <li>
          to other deal/transaction participants including issuers,
          borrowers, advisors, translation service providers and within
          prospectuses and marketing materials;
        </li>
        <li>
          to counterparties, vendors and beneficiaries, and other entities
          connected with our clients;
        </li>
        <li>
          to other persons as agreed with a client or as required or
          expressly permitted by applicable law;
        </li>
        <li>
          to central banks, regulators, trade data repositories, or approved
          reporting mechanisms which may be outside your country;
        </li>
        <li>
          to courts, litigation counterparties and others, pursuant to
          subpoena or other court order or process or otherwise as
          reasonably necessary, including in the context of litigation,
          arbitration and similar proceedings to enforce our terms and
          conditions, and as reasonably necessary to prepare for or conduct
          any litigation, arbitration and/or similar proceedings.
        </li>
      </ul>
      <p>
        Disclosures of Personal Data which we make to our third-party
        service providers, as described in this section, will be made
        subject to conditions of confidentiality and security as we may
        consider appropriate to the specific circumstances of each such
        disclosure.
      </p>
      <h3>Data Subject Rights</h3>
      <p>
        You have the following rights, in certain circumstances, in relation
        to your personal information:
      </p>
      <ul>
        <li>the right to access your personal information;</li>
        <li>
          the right to amend and rectify any inaccuracies in your personal
          information;
        </li>
        <li>the right to restrict the use of your personal information;</li>
        <li>
          the right to request that your personal information is erased;
        </li>
        <li>
          the right to object to the processing of your personal
          information;
        </li>
        <li>the right to data portability.</li>
      </ul>
      <h3>Accessing, Amending or Deletion of Personal Data</h3>
      <p>
        If you would like to request to review, correct, amend, restrict or
        delete Personal Data that you have previously provided to us, or if
        you would like to request to receive an electronic copy of your
        Personal Data for the purposes of transmitting it to another company
        (to the extent this right to data portability is provided to you by
        applicable law), you may contact us using our email address
        <a href='mailto: helpdesk@servc.co.il'> helpdesk@servc.co.il</a>
      </p>
      <h3>Retention Period</h3>
      <p>
        We will retain Personal Data for as long as needed or permitted in
        light of the purpose(s) for which it was obtained. The criteria used
        to determine our retention periods include: (i) the length of time
        we have an ongoing relationship with our client and provide the
        Services; (ii) whether there is a legal obligation to which we are
        subject; and (iii) whether retention is advisable in light of our
        legal position.
      </p>
      <h3>
        We will retain Personal Data for as long as needed or permitted in
        light of the purpose(s) for which it was obtained. The criteria used
        to determine our retention periods include: (i) the length of time
        we have an ongoing relationship with our client and provide the
        Services; (ii) whether there is a legal obligation to which we are
        subject; and (iii) whether retention is advisable in light of our
        legal position.
      </h3>
      <p>
        Personal Data may be stored and processed in any country where we
        have facilities or in which we engage service providers. In certain
        circumstances, courts, law enforcement agencies, regulatory agencies
        or security authorities in those other countries may be entitled to
        access Personal Data.
      </p>
      <h3>Recording of communications</h3>
      <p>
        When individuals communicate with Orca, telephone conversations and
        electronic communications, including emails, text messages and
        instant messages, may be recorded and/or monitored for evidentiary,
        compliance, quality assurance and governance purposes or as required
        by any applicable law.
      </p>
      <p>
        Under the GDPR, where a data breach is likely to result in a ‘risk
        for the rights and freedoms of individuals’ we must notify the
        customers and data controllers ‘without undue delay’. We will ensure
        we inform them within 72 hours.
      </p>
    </Blocks>
  </div>;
};
