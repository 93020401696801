/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
// eslint-disable-next-line import/no-unassigned-import
import './antd.scss';
import authApi from '../../../api/auth';
import {SuccessNotification} from '../../../components';
import {
  inputHighlight,
  removeSpaces,
} from '../../utils';
import style from './changePassword.module.scss';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import {
  Button,
  Input,
} from 'antd';
import {useDocumentTitle} from 'hooks';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  passwordRequirements,
  strongPasswordMessage,
} from 'resources/constants';
import {getItemsFromStorage} from 'types';
import {isStrongPassword} from 'validator';

const value = {};
let savedData = {};

export const ChangePass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {firm} = useSelector((state) => state);
  const {details} = firm;
  const {loginAs} = getItemsFromStorage([
    'loginAs',
  ], sessionStorage);
  useDocumentTitle('Change password');

  // eslint-disable-next-line unicorn/prefer-query-selector
  const inputs = document.getElementsByClassName('.change-password input');

  const handleOnChange = (_, nothing, event) => {
    for (const input of inputs) {
      input.classList.remove(style.error);
    }

    inputHighlight(event);
    value[event.target.name] = event.target.value.replaceAll(' ', '');
  };

  const handleOnClick = () => {
    // eslint-disable-next-line unicorn/prefer-query-selector
    const error = document.getElementsByClassName(style.error)[0];
    value.email = sessionStorage.getItem('email');
    const valueClone = JSON.parse(JSON.stringify(value));

    if (valueClone?.newPassword && value?.password) {
      if (!isStrongPassword(value.newPassword, passwordRequirements)) {
        error.innerText = strongPasswordMessage;
        for (const input of inputs) {
          input.classList.add(style.error);
        }

        return;
      }

      if (value.newPassword === value.password) {
        error.innerText = 'New password should not be same as old password';
        for (const input of inputs) {
          input.classList.add(style.error);
        }

        return;
      }

      if (value.newPassword === value.confirmPassword) {
        for (const input of inputs) {
          input.classList.remove(style.error);
        }

        delete valueClone.confirmPassword;
        savedData = {Password: valueClone.password};
        // eslint-disable-next-line consistent-return
        return dispatch(authApi.changePassword({loginAs,
          valueClone}, navigate));
      }

      error.innerText = 'Passwords are not matching';
    } else {
      if (!value.password) {
        error.innerText = 'The password field is required.';
        return;
      }

      if (!value.newPassword) {
        error.innerText = 'The new password field is required.';
        return;
      }

      if (!value.confirmPassword) {
        error.innerText = 'The password confirmation field is required.';
        return;
      }

      error.innerText = 'Current password or new password is missing.';
    }
  };

  return (
    <div className={[
      style.container,
      'change-password',
    ].join(' ')}
    >
      <div className={style['firm-name']}>{details.firmName}</div>
      {firm.notification && <SuccessNotification data={savedData} />}
      <div className={style.block}>
        <div className='max-w-[500px] px-[25px] py-[20px]'>
          <div className={style.header}>Change password</div>
          <div className={style.error} />
          <label>
            <div className={style.label}>Current password</div>
            <Input.Password
              autoComplete='new-password'
              // eslint-disable-next-line no-confusing-arrow
              iconRender={(visible) => visible ?
                <EyeTwoTone /> :
                <EyeInvisibleOutlined />}
              name='password'
              onChange={(event, _, nothing) => handleOnChange(_, nothing, event)}
              onKeyDown={removeSpaces}
              onKeyPress={(event) => {
                if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                  handleOnClick();
                }
              }}
              onPaste={(event, _, nothing) => handleOnChange(_, nothing, event)}
            />
          </label>
          <label>
            <div className={[
              style.label,
            ].join(' ')}
            >New password</div>
            <Input.Password
              autoComplete='new-password'
              // eslint-disable-next-line no-confusing-arrow
              iconRender={(visible) => visible ?
                <EyeTwoTone /> :
                <EyeInvisibleOutlined />}
              name='newPassword'
              onChange={(event, _, nothing) => handleOnChange(_, nothing, event)}
              onKeyDown={removeSpaces}
              onKeyPress={(event) => {
                if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                  handleOnClick();
                }
              }}
              onPaste={(event, _, nothing) => handleOnChange(_, nothing, event)}
            />
          </label>
          <label>
            <div className={[
              style.label,
            ].join(' ')}
            >Confirm password</div>
            <Input.Password
              autoComplete='new-password'
              // eslint-disable-next-line no-confusing-arrow
              iconRender={(visible) => visible ?
                <EyeTwoTone /> :
                <EyeInvisibleOutlined />}
              name='confirmPassword'
              onChange={(event, _, nothing) => handleOnChange(_, nothing, event)}
              onKeyDown={removeSpaces}
              onKeyPress={(event) => {
                if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                  handleOnClick();
                }
              }}
              onPaste={(event, _, nothing) => handleOnChange(_, nothing, event)}
            />
          </label>
          <div style={{display: 'flex',
            marginBottom: '15px'}}
          >
            <div className={[
              style.label,
              style.hidden,
            ].join(' ')}
            />
            <Button
              // eslint-disable-next-line react/forbid-component-props
              className={style['submit-btn']}
              id='save'
              onClick={handleOnClick}
              type='primary'
            >Save</Button>
          </div>
          <div style={{display: 'flex'}}>
            <div className={[
              style.label,
              style.hidden,
            ].join(' ')}
            />
            <Button
              // eslint-disable-next-line react/forbid-component-props
              className={style['cancel-btn']}
              id='cancel'
              onClick={() => navigate('/firm/settings')}
            >Cancel</Button>
          </div>
        </div>
      </div>
    </div>

  );
};
