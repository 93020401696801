import style from './LoginContactSupport.module.scss';
import { Link,
  useLocation } from 'react-router-dom';

export const LoginContactSupport = () => {
  const { pathname } = useLocation();
  const excludedPaths = [
    '/auth/forgot-password',
    '/auth/verification',
    '/auth/reset-password-confirm',
  ];
  // It returns false if the current path matches any in the excludedPaths array
  const showLinkToForgotPassword = !excludedPaths.some(
    (path) => pathname === path || pathname.startsWith(path),
  );

  return (
    <div className={style.loginContactSupportWrapper}>
      {showLinkToForgotPassword ?
        <div className={style.resetPasswordWrapper}>
          <Link
            className='text-font-2 !text-label-g-300 hover:text-label-g-300 hover:underline'
            data-test='contact-support-forget-password-link'
            onClick={() => sessionStorage.setItem('canResetPassword', 'true')}
            to='/auth/forgot-password'
          >
            Reset Password
          </Link>
        </div> :
        null}
      <a className='text-font-2 !text-label-g-300 hover:text-label-g-300 hover:underline' href='mailto:helpdesk@servc.co.il?cc=fms@servc.co.il'>
        Contact Support
      </a>
    </div>
  );
};
