import { type TableColumnDisplayValues } from '../../../types';
import { CapitalAccountsTotalsChart,
  type TotalsChartItem } from '../CapitalAccountsTotalsChart';
import { type FC } from 'react';

export const NetGainLossTotals: FC<TableColumnDisplayValues> = ({
  unrealizedGainLoss,
  realizedGainLoss,
  other,
}) => {
  const totalValue = unrealizedGainLoss +
  realizedGainLoss +
  other;

  const items: TotalsChartItem[] = [
    {
      legend: 'Unreal. portf. Gain/(Loss)',
      value: unrealizedGainLoss,
    },
    {
      legend: 'Real. portf. Gain/(Loss)',
      value: realizedGainLoss,
    },
    {
      legend: 'Other',
      value: other,
    },
  ];

  if (!unrealizedGainLoss && !realizedGainLoss && !other) {
    return null;
  }

  return <CapitalAccountsTotalsChart
    items={items}
    title={'Net Gain/(Loss)'}
    totalValue={totalValue}
  />;
};
