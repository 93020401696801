import {Authorization,
  Firms,
  FirmWithModalContexts} from './pages';
import {FiltersProvider} from 'contexts';
import {useCheckUserAuthenticationFromMe} from 'hooks';
import {Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import {environmentService,
  scriptsService} from 'services';

const App = () => {
  useCheckUserAuthenticationFromMe([
    '/firm/*',
    '/firms',
  ]);

  return (
    <div className='app'>
      <FiltersProvider>
        <Routes>
          <Route element={<Authorization />} path='/auth/*' />
          <Route element={<FirmWithModalContexts />} path='/firm/*' />
          <Route element={<Firms />} path='/firms' />
          <Route element={<Navigate replace to='/auth/login' />} path='*' />
        </Routes>
      </FiltersProvider>
      {
        environmentService.isProduction() &&
          scriptsService.insertJsScriptSource(
            scriptsService.SCRIPTS.googleAnalyticsTagSrc,
          )
      }
      {
        environmentService.isProduction() &&
          scriptsService.insertJsScriptText(
            scriptsService.SCRIPTS.googleAnalyticsTagScript,
          )
      }
    </div>
  );
};

export default App;
