export const MAX_SIZE_OF_FILES_IN_MB = 2;

export const convertMBToBytes = (numberOfMb: number) => numberOfMb * 2 ** 20;

export const FILES_ARE_TOO_BIG_ERROR_MESSAGE = (numberOfMb: number) => {
  return (
    `Your newly selected files exceed the limit of ${numberOfMb}MB.
    Please remove some existing files or select files with smaller file sizes.`
  );
};

export const DUPLICATE_FILES_MESSAGE = 'You can only upload files with unique names.';

export const ERROR_INITIAL_STATE = {
  message: '',
  status: false,
};
