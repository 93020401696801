import authApi from 'api/auth';
import {Alert,
  unauthorized} from 'api/utils';
import axios from 'axios';
import environment from 'config/environment';
import {DateTime} from 'luxon';
import {getItemsFromStorage,
  removeItemsFromStorage,
  storeItemsInStorage} from 'types';

const REFRESH_THRESHOLD_LIMIT = 10;

const refreshTokens = (accessToken, refreshToken) => {
  return (dispatch) => axios({
    data: {
      refreshToken,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'post',
    url: environment.apiUrl + '/api/auth/refresh-token',
    withCredentials: true,
  // eslint-disable-next-line promise/prefer-await-to-then
  }).then(({data}) => {
    dispatch(authApi.setAccessToken(data.accessToken, true));
    dispatch(authApi.setRefreshToken(data.refreshToken, true));
  // eslint-disable-next-line promise/prefer-await-to-then
  }).catch((error) => {
    Alert(error?.response?.data?.message);
    unauthorized(error?.response?.status);
  });
};

const checkTokenExpiry = ({dispatch}) => (next) => (action) => {
  // only need to check if async action (likely need to get or post something)

  if (typeof action !== 'function') {
    return next(action);
  }

  const {
    exp: expiryDate,
    access_token: accessToken,
    refresh_token: refreshToken,
  } = getItemsFromStorage([
    'exp',
    'access_token',
    'refresh_token',
  ], sessionStorage);

  const isSendingRefreshToken = () => Boolean(getItemsFromStorage([
    'sending_refresh_token',
  ], sessionStorage));

  if (!expiryDate) {
    return next(action);
  }

  const tokenExpiryDate = DateTime.fromISO(expiryDate);
  const thresholdExpiryDate = DateTime.fromJSDate(new Date(), {zone: 'Israel'}).plus({minutes: REFRESH_THRESHOLD_LIMIT});

  if (tokenExpiryDate >= thresholdExpiryDate) {
    return next(action);
  }

  if (isSendingRefreshToken()) {
    return next(action);
  }

  storeItemsInStorage(
    {sending_refresh_token: 'true'},
    sessionStorage,
  );
  return dispatch(refreshTokens(accessToken, refreshToken))
    // eslint-disable-next-line promise/prefer-await-to-then
    .then(() => {
      removeItemsFromStorage([
        'sending_refresh_token',
      ], sessionStorage);
      return next(action);
    });
};

export default checkTokenExpiry;
