import { type DocumentPeriodsFilter } from 'api/documentsApi';
import { type TreeNode } from 'components/TreeMultiSelector/TreeMultiSelector';
import TreeMultiSelector from 'components/TreeMultiSelector/TreeMultiSelector';
import { debounce,
  getPeriodOptions } from 'helpers/helpers';
import { useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { type AppDispatch } from 'store';
import {
  fetchCategories,
  type Filters,
  type FilterStoreState,
  type OrganizationsStoreState,
  type PeriodFilters,
  setUsualDocumentsFilters,
} from 'store/slices';
import { type NestedOrganization } from 'types';

const getSelectedOptions = (periodStrings: string[]): TreeNode[] => {
  const yearMap = new Map<number | string, Set<string>>();

  for (const period of periodStrings) {
    const [
      year,
      quarter,
    ] = period.split('/');

    const quarters = yearMap.get(year) || yearMap.set(year, new Set()).get(year);
    quarters?.add(quarter);
  }

  return Array.from(yearMap.entries()).map(([
    year,
    quarters,
  ]) => {
    return {
      children: Array.from(quarters).map((quarter) => {
        return {
          id: `${year}/${quarter}`,
          name: `${quarter}`,
        };
      }),
      id: `${year}`,
      name: `${year}`,
    };
  });
};

export const DocumentsPeriodSelector = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    displayedOrganizationWithPermissions,
  } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  const periodFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.usualDocumentsFilter) as PeriodFilters | null;

  const debouncedFetchCategories = useCallback(
    debounce((filters: DocumentPeriodsFilter) => {
      dispatch(fetchCategories(filters));
    }, 700),
    [
      dispatch,
    ],
  );

  const checkPeriod = (years: string[], quarters: string[]) => {
    dispatch(setUsualDocumentsFilters({
      quarters,
      years,
    } as PeriodFilters));

    if (activeFilters) {
      debouncedFetchCategories({
        ...activeFilters,
        firmId: activeFilters.organizationId,
        quarters,
      });
    }
  };

  return <TreeMultiSelector
    dataSelectionValue='period-filter-multiselector'
    disabled={!getPeriodOptions(
      displayedOrganizationWithPermissions as NestedOrganization).length}
    inputStyle='bg-rgba-black-200 relative flex w-auto items-center justify-center h-[2rem] max-w-[8rem] pr-[0.5rem] py-0 rounded-large'
    isResettable
    onCheck={checkPeriod}
    placeholder='All periods'
    selectedData={getSelectedOptions(periodFilters?.quarters as string[])}
    treeData={getPeriodOptions(
      displayedOrganizationWithPermissions as NestedOrganization)}
  />;
};
