import './DataRoom.scss';
import { type FundForDataRoom } from 'api/dataRoomApi';
import { Modal } from 'components/InteractiveUIControls/Modal/Modal';
import { ModalTop } from 'components/InteractiveUIControls/Modal/Modaltop';
import { Svgicon } from 'components/Svgicon/Svgicon';

const FundOption = ({
  fund,
  onFundSelected,
}: {
  fund: FundForDataRoom,
  onFundSelected: (fundId: string) => void,
}) => {
  return (
    <div
      className='border-bottom-1 flex w-full cursor-pointer justify-between border-b-[1px] border-b-black-grey-border px-0.5 py-0.75 hover:bg-black-100 active:bg-black-200'
      onClick={() => onFundSelected(fund.fundId)}
    >
      <span className='truncate text-font-2'>{fund.fundName}</span>
      <Svgicon className='size-1 min-w-[1rem] text-black-500' id='arrow-right-1f' />
    </div>
  );
};

export const DataRoomFundSelector = ({
  funds,
  onClose,
  onFundSelected,
}: {
  funds: FundForDataRoom[],
  onClose: () => void,
  onFundSelected: (fundId: string) => void,
}) => {
  return (
    <Modal onToggle={onClose} showCloseIcon>
      <ModalTop centred title='Select fund' />
      <div className='main-scroll w-[28rem] p-1.25'>
        <div className='max-h-[18.75rem] overflow-y-auto'>
          {funds.map((fund) =>
            <FundOption
              fund={fund}
              key={fund.fundId}
              onFundSelected={onFundSelected}
            />,
          )}
        </div>
      </div>
    </Modal>
  );
};
