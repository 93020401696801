export type AuthenticationOptionsDisplay = 'Email' | 'None' | 'SMS' | 'WhatsApp';

export enum VerificationChannel {
  Email = 'email',
  None = 'none',
  Sms = 'sms',
  WhatsApp = 'whatsapp'
}

export type IVerificationChannel = {
  api: VerificationChannel,
  display: AuthenticationOptionsDisplay,
  enabled: boolean,
};

export const CHANNEL_MAP: Map<
VerificationChannel | undefined, IVerificationChannel> = new Map([
  [
    VerificationChannel.WhatsApp,
    { api: VerificationChannel.WhatsApp,
      display: 'WhatsApp',
      enabled: false,
    },
  ],
  [
    VerificationChannel.Sms,
    { api: VerificationChannel.Sms,
      display: 'SMS',
      enabled: false,
    },
  ],
  [
    VerificationChannel.Email,
    { api: VerificationChannel.Email,
      display: 'Email',
      enabled: false,
    },
  ],
  [
    VerificationChannel.None,
    { api: VerificationChannel.None,
      display: 'None',
      enabled: false,
    },
  ],
  [
    undefined,
    { api: VerificationChannel.WhatsApp,
      display: 'WhatsApp',
      enabled: false,
    },
  ],
]);

export const CHANNEL_VALUES = Array.from(CHANNEL_MAP.values());

export const WITHOUT_NONE = CHANNEL_VALUES.filter(
  (channel) => channel.api !== VerificationChannel.None,
);

export const ONLY_EMAIL = CHANNEL_VALUES.filter(
  (channel) => channel.api === VerificationChannel.Email,
);
