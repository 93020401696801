import userApi from '../../../api/user';
import {getItemsFromStorage,
  type PageRedirectInfoFromStorage } from '../../../types';
import { removeItemsFromStorage } from '../../../types';
import style from './Login.module.scss';
import authApi from 'api/auth';
import { LoginButton,
  LoginHeading,
  LoginInput,
  LoginPasswordInput,
  WelcomeMessage} from 'components';
import { useDocumentTitle } from 'hooks';
import { useEffect,
  useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate } from 'react-router-dom';
import { analyticsService,
  notificationsService } from 'services';
import { type AppDispatch } from 'store';

export const Login = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const {
    loginAs,
    token,
  } = Object.fromEntries(queryParameters.entries());

  useEffect(() => {
    sessionStorage.removeItem('onLoginPage');
    sessionStorage.removeItem('canResetPassword');
  }, []);

  const isLoading = useSelector<{
    global: {loading: boolean, },
  }>((state) => state.global.loading) as boolean;

  const [
    email,
    setEmail,
  ] = useState('');
  const [
    password,
    setPassword,
  ] = useState('');

  useDocumentTitle('Investor Portal Login');

  const {
    loginMessage,
    nextPage,
  } = getItemsFromStorage<PageRedirectInfoFromStorage>([
    'loginMessage',
    'nextPage',
  ], sessionStorage);

  const targetNextPage = nextPage || '/firms';
  const verificationPage = '/auth/verification';
  // eslint-disable-next-line require-unicode-regexp, unicorn/no-unsafe-regex, unicorn/better-regex, regexp/no-unused-capturing-group, regexp/no-super-linear-backtracking, regexp/prefer-w, max-len, regexp/prefer-d
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sessionStorage.setItem('onLoginPage', 'true');
    const payload = { email,
      password,
    };

    if (!emailRegex.test(email.toLowerCase())) {
      notificationsService.error('Email is invalid');
      return;
    }

    await dispatch(
      authApi.login(
        payload,
        navigate,
        verificationPage,
        targetNextPage,
      ),
    );
    analyticsService.sendLoginEvent();

    removeItemsFromStorage<PageRedirectInfoFromStorage>([
      'loginMessage',
      'nextPage',
    ], sessionStorage);
  };

  useEffect(() => {
    const checkLoginAs = async () => {
      if (loginAs && token) {
        dispatch(authApi.setAccessToken(token, true));
        await authApi.impersonateByAdmin({token});
        dispatch(userApi.me());
        navigate('/firms');
      }
    };

    checkLoginAs();
  }, [
    dispatch,
    loginAs,
    navigate,
    token,
  ]);

  return <div className={style.loginWrapper}>
    <div className='flex flex-col gap-1.5'>
      {
        loginMessage ?
          <LoginHeading htmlContent={loginMessage} /> :
          <WelcomeMessage />
      }
    </div>
    <form onSubmit={onSubmit}>
      <div className={style.inputsWrapper}>
        <LoginInput name='login-username-input' onChange={setEmail} placeholder='Email' type='email' value={email} />
        <LoginPasswordInput name='login-password-input' onChange={setPassword} placeholder='Password' value={password} />
      </div>
      <LoginButton isLoading={isLoading} name='login-button' text='Sign In' type='submit' />
    </form>
  </div>;
};
