const getIntOrDefault = (value: string | undefined, defaultValue: number) => {
  if (value === undefined) {
    return defaultValue;
  }

  const parsedValue = Number.parseInt(value, 10);
  if (Number.isNaN(parsedValue)) {
    return defaultValue;
  }

  return parsedValue;
};

export const resendVerificationTimeoutInS = getIntOrDefault(
  process.env.RESEND_VERIFICATION_TIMEOUT_IN_S, 30,
);
