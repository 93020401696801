import {
  type ComputedCapitalAccountFund } from '../../../../types';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {type FC,
  type MouseEvent } from 'react';

export type CapitalAccountsExpandIconProps = {
  expanded: boolean,
  onExpand: (record: ComputedCapitalAccountFund, event: MouseEvent<HTMLElement>) => void,
  record: ComputedCapitalAccountFund,
};

export const CapitalAccountsExpandIcon: FC<CapitalAccountsExpandIconProps> = ({
  expanded,
  onExpand,
  record,
}) => {
  return <div
    className={`${!expanded && 'rotate-[270deg]'} h-[0.875rem] transition-transform duration-200`}
    data-test={`table-dropdown-icon-${expanded ? 1 : 2}`}
    onClick={(event) => {
      event?.preventDefault();
      onExpand(record, event as React.MouseEvent<HTMLElement>);
    }}
  >
    <Svgicon color={`${expanded ? '#0076E6' : 'black'}`} id='UP-arrow-down' />

  </div>;
};
