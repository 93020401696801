/* eslint-disable canonical/filename-match-regex */
/* eslint-disable canonical/filename-match-exported */
import Api from '.';

class UserPublicApi extends Api {
  public constructor () {
    super('/api/v1/public/user');
  }

  public async getUserIsActive (token: string) {
    const path = `/is-active?token=${token}`;
    const { data } = await this.get(path);
    return data as {isActive: boolean,};
  }
}

const userPublicApi = new UserPublicApi();
export default userPublicApi;
