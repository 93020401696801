export const formatNumber = (
  number?: number,
  fractionDigits = 2,
  zeroIfNullish = false,
): string => {
  if (!number) {
    return zeroIfNullish ? '0' : '-';
  }

  const formattedNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(Math.abs(number));

  return number < 0 ? `(${formattedNumber})` : formattedNumber;
};

export const formatWithAbbreviation = (
  number: number,
  decimalDigits = 2,
  tensToNextAbbreviation = 2,
): string => {
  if (number >= 10 ** (tensToNextAbbreviation + 6)) {
    return `${formatNumber(number / 10 ** 9, decimalDigits)}B`;
  }

  if (number >= 10 ** (tensToNextAbbreviation + 3)) {
    return `${formatNumber(number / 10 ** 6, decimalDigits)}M`;
  }

  if (number >= 10 ** tensToNextAbbreviation) {
    return `${formatNumber(number / 10 ** 3, decimalDigits)}K`;
  }

  return number.toFixed(decimalDigits);
};
