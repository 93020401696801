// eslint-disable-next-line canonical/filename-match-exported,@typescript-eslint/comma-dangle
enum AppEnvironment {
  demo = 'demo',
  development = 'development',
  production = 'production',
  staging = 'staging',
  test = 'test',
}

const env: AppEnvironment = (import.meta.env.VITE_APP_ENVIRONMENT || 'test') as AppEnvironment;

const getAdobePdfEmbedApiKey = () => {
  const domain = window.location.hostname;
  return domain.includes('orcainc.com')
    ? '1873189a09124df7b529f447d5ac02a9'
    : '53145200219e4f6ca34483e116fa2b1a';
};

const environments = {
  demo: {
    adobePdfEmbedApiKey: getAdobePdfEmbedApiKey(),
    apiUrl: import.meta.env.VITE_APP_API_URL || 'https://api.portal.demo.orcainc.com',
    env,
    fmsLoginUrl: import.meta.env.VITE_APP_FMS_URL || 'https://demo.orcainc.com',
    resendVerificationTimeout:
      import.meta.env.VITE_APP_VERIFICATION_TIMEOUT || 30,
  },
  development: {
    adobePdfEmbedApiKey: '1afabdc412cd4cd18e73faddd0e719e7',
    apiUrl: import.meta.env.VITE_APP_API_URL || 'http://localhost:5000',
    env,
    fmsLoginUrl: import.meta.env.VITE_APP_FMS_URL || 'https://staging.orcainc.com',
    resendVerificationTimeout:
      import.meta.env.VITE_APP_VERIFICATION_TIMEOUT || 15,
  },
  production: {
    adobePdfEmbedApiKey: getAdobePdfEmbedApiKey(),
    apiUrl: import.meta.env.VITE_APP_API_URL || 'https://api.portal.orcainc.com',
    env,
    fmsLoginUrl: import.meta.env.VITE_APP_FMS_URL || 'https://fms.orcainc.com',
    resendVerificationTimeout:
      import.meta.env.VITE_APP_VERIFICATION_TIMEOUT || 15,
  },
  staging: {
    adobePdfEmbedApiKey: getAdobePdfEmbedApiKey(),
    apiUrl: import.meta.env.VITE_APP_API_URL || 'https://api.portal.staging.orcainc.com',
    env,
    fmsLoginUrl: import.meta.env.VITE_APP_FMS_URL || 'https://staging.orcainc.com',
    resendVerificationTimeout:
      import.meta.env.VITE_APP_VERIFICATION_TIMEOUT || 15,
  },
  test: {
    adobePdfEmbedApiKey: '1afabdc412cd4cd18e73faddd0e719e7',
    apiUrl: import.meta.env.VITE_APP_API_URL,
    env,
    fmsLoginUrl: import.meta.env.VITE_APP_FMS_URL,
    resendVerificationTimeout:
      import.meta.env.VITE_APP_VERIFICATION_TIMEOUT || 15,
  },
};

export default environments[env];
