import { getItemsFromStorage,
  storeItemsInStorage,
  VerificationChannel } from 'types';

export type To = {
  email: string,
  phone: string,
};

type VerificationData = {
  channel: VerificationChannel,
  to: To,
};

const halfHideEmail = (email: string): string => {
  const atIndex = email.indexOf('@');
  if (atIndex === -1) {
    throw new Error('Invalid email format');
  }

  const [
    username,
    domain,
  ] = email.split('@');
  const firstLetter = username.charAt(0);
  const lastLetter = username.charAt(username.length - 1);
  const hiddenUsername = `${firstLetter}***${lastLetter}`;

  return `${hiddenUsername}@${domain}`;
};

export const getSendToParagraph = (channel: VerificationChannel, to: To) => {
  const { phone,
    email } = to;
  const getToWhat = (ch: VerificationChannel) => {
    if (ch === VerificationChannel.Sms) {
      return 'SMS';
    }

    if (ch === VerificationChannel.Email) {
      return 'email';
    }

    return 'WhatsApp';
  };

  const getSendTo = (ch: VerificationChannel) => {
    if (ch === VerificationChannel.Sms) {
      return phone;
    }

    if (ch === VerificationChannel.Email) {
      return halfHideEmail(email);
    }

    return phone;
  };

  const toWhat = getToWhat(channel);
  const sendTo = getSendTo(channel);
  return <div className='text-center text-title-1 dt:text-left dt:text-title-3'>
    We have sent you the code via {toWhat} to {sendTo}
  </div>;
};

type VerificationDataFromStorage = To & {
  channel: VerificationChannel,
};

const getVerificationData = () => {
  const { channel,
    email,
    phone } = getItemsFromStorage<VerificationDataFromStorage>([
    'channel',
    'email',
    'phone',
  ], sessionStorage);
  return { channel,
    to: { email,
      phone } } as VerificationData;
};

const setVerificationData = (to: To, channel: VerificationChannel) => {
  storeItemsInStorage<VerificationDataFromStorage>(
    {
      channel,
      email: to.email,
      phone: to.phone,
    },
    sessionStorage,
  );
};

type useVerificationDataType = () =>
[VerificationData, (to: To, channel: VerificationChannel) => void];
export const useVerificationData: useVerificationDataType = () => [
  getVerificationData(),
  setVerificationData,
];
