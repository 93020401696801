import { Alert } from '../../api/utils';
import {
  type DocumentsPendingState,
  type DocumentsStoreState,
  fetchLatestDocuments,
  type Filters,
  type FilterStoreState,
  type Pagination,
  resetLatestDocuments,
  setPagination,
} from '../../store/slices';
import { NoFilesMessage } from '../NoFilesMessage';
import { Spinner } from '../Spinner';
import style from './DocumentCategories.module.scss';
import { DocumentCategory } from './DocumentCategory';
import { Document } from './DocumentCategory/DocumentGroup/Documents/Document';
import { DocumentsPeriodSelector } from './DocumentsPeriodSelector/DocumentsPeriodSelector';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { isMobile } from 'services/mobile';
import { type AppDispatch } from 'store';
import { type DocumentDTO } from 'types';

export const DocumentCategories = () => {
  const dispatch: AppDispatch = useDispatch();
  const mobile = isMobile();
  const parentRef = useRef<HTMLDivElement>(null);
  const SCROLL_AMOUNT = 600;
  const [
    rightButtonVisible,
    setRightButtonVisible,
  ] = useState(true);
  const [
    leftButtonVisible,
    setLeftButtonVisible,
  ] = useState(false);

  const {
    categoriesError,
    categoriesPending,
    newCategory,
    otherCategories,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;

  const latestDocuments = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents.latestDocuments,
  ) as DocumentDTO[] | null;

  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  const documentPendingState = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents.documentsPending,
  ) as DocumentsPendingState;

  const pagination = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents.pagination,
  ) as Pagination;

  const totalNumberOfLatestDocuments = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents.newCategory?.totalNumberOfDocuments,
  ) as number;

  const allDocsLoaded = totalNumberOfLatestDocuments === latestDocuments?.length;

  const loadMore = (
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    event?.stopPropagation();
    if (activeFilters) {
      dispatch(fetchLatestDocuments({
        filter: {
          filterByLatestDocuments: true,
          filterByNewDocuments: false,
          firmId: activeFilters.organizationId,
          ...activeFilters,
        },
        page: pagination.page + 1,
        size: pagination.size,
      }));
    }

    dispatch(setPagination({
      page: pagination.page + 1,
      size: pagination.size,
    }));
  };

  const onScroll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, direction: 'left' | 'right') => {
    event.stopPropagation();
    if (parentRef.current) {
      parentRef.current.scrollBy({
        behavior: 'smooth',
        left: (direction === 'left' ? -1 : 1) * SCROLL_AMOUNT,
      });
    }
  };

  useEffect(() => {
    if (
      !latestDocuments &&
      !documentPendingState.pending &&
      !documentPendingState.error &&
      activeFilters
    ) {
      dispatch(fetchLatestDocuments({
        filter: {
          filterByLatestDocuments: true,
          filterByNewDocuments: false,
          firmId: activeFilters.organizationId,
          ...activeFilters,
        },
        page: pagination.page,
        size: pagination.size,
      }));
    }
  }, [
    latestDocuments,
    documentPendingState,
    activeFilters,
    dispatch,
    pagination,
  ]);

  useEffect(() => {
    dispatch(resetLatestDocuments());
  }, [
    activeFilters,
    dispatch,
  ]);

  useEffect(() => {
    const ref = parentRef.current;
    if (ref) {
      const { scrollLeft,
        scrollWidth,
        clientWidth } = ref;
      const isAtEnd = scrollLeft + clientWidth > scrollWidth - 1;
      const isAtStart = scrollLeft < 1;
      setRightButtonVisible(totalNumberOfLatestDocuments >= 5 || !isAtEnd);
      setLeftButtonVisible(!isAtStart);
    }
  }, [
    totalNumberOfLatestDocuments,
    latestDocuments,
  ]);

  useEffect(() => {
    const ref = parentRef.current;

    const handleScroll = () => {
      if (ref) {
        const { scrollLeft,
          scrollWidth,
          clientWidth } = ref;
        const isAtEnd = scrollLeft + clientWidth > scrollWidth - 1;
        const isAtStart = scrollLeft < 1;

        setLeftButtonVisible(!isAtStart);

        if (!mobile && isAtEnd && !allDocsLoaded) {
          loadMore();
        }

        setRightButtonVisible(!isAtEnd || !allDocsLoaded);
      }
    };

    if (ref) {
      ref.addEventListener('scroll', handleScroll);
    }

    return () => {
      ref?.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (categoriesError) {
      Alert(categoriesError);
    }
  }, [
    categoriesError,
  ]);

  const desktopScrollArea = () =>
    <div className='absolute left-0 top-[1.875rem] flex w-full items-center justify-between bg-transparent '>
      <div className={`${leftButtonVisible && style.leftGradient}`}>
        {leftButtonVisible && <Button
          className='!h-8 !w-8 !rounded-[3.5rem]'
          data-test='export-documents'
          icon='only'
          iconid='scroll-to-the-left'
          onClick={(event) =>
            onScroll(event as React.MouseEvent<HTMLButtonElement, MouseEvent>, 'left')}
          size='medium'
        />}
      </div>
      {rightButtonVisible && <div className={`${style.rightGradient}
      z-[3] flex h-[5.625rem] w-[5rem] items-center justify-end pr-[1rem]`}
      >
        {rightButtonVisible && <Button
          className='!h-8 !w-8 !rounded-[3.5rem] '
          data-test='export-documents'
          icon='only'
          iconid='scroll-to-the-right'
          isLoading={documentPendingState.pending}
          onClick={(event) =>
            onScroll(event as React.MouseEvent<HTMLButtonElement, MouseEvent>, 'right')}
          size='medium'
        />}
      </div>}
    </div>;

  const mobileScrollArea = () =>
    <div className='ml-[1rem]'>
      {latestDocuments && !allDocsLoaded &&
        <Button
          className='!h-8 !w-8 !rounded-[3.5rem] '
          data-test='export-documents'
          icon='only'
          iconid='scroll-to-the-right'
          isLoading={documentPendingState.pending}
          onClick={(event) => loadMore(event)}
          size='medium'
        />}
    </div>;

  const renderCategories = () =>
    <>
      {
        newCategory && <div className='relative flex w-full max-w-[90rem] flex-col gap-[1rem]'>
          {!mobile && desktopScrollArea()}
          <h3 className={style.allDocumentsText}>
            Recent documents
          </h3>
          <div
            className={`${style.scrollContainer}
          p> relative flex w-full max-w-[90rem]
           items-center overflow-auto overflow-y-hidden pb-[1rem] pl-[0.2rem]`}
            ref={parentRef}
          >
            {documentPendingState.pending && !latestDocuments && <div className='flex max-h-[5.625rem] w-full justify-center'><Spinner /></div>}
            <div className='flex gap-[0.5rem]'>{latestDocuments?.map((document, index) =>
              <Document
                categoryId={newCategory.categoryId}
                documentObject={document}
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}_${document._id}`}
              />)}</div>
            {mobile && mobileScrollArea()}
          </div>
        </div>
      }
      <div className={style.categoriesContainer}>
        <div className='flex items-center justify-between'>
          <h3 className={style.allDocumentsText}>
            All documents
          </h3>
          <DocumentsPeriodSelector />
        </div>
        {
          categoriesPending ?
            <div className='flex min-h-80 items-center justify-center'>
              <Spinner />
            </div>
            // eslint-disable-next-line @typescript-eslint/no-extra-parens
            : (
              newCategory || otherCategories && otherCategories.length > 0 ?
                otherCategories?.map((category) =>
                  <DocumentCategory
                    key={category.categoryId}
                    {...category}
                  />)
                : <NoFilesMessage />
            )
        }
      </div>

    </>;

  return (
    <>
      {
        categoriesPending && !otherCategories?.length ? <div className='flex min-h-[40rem] items-center justify-center'><Spinner /></div> :
          // eslint-disable-next-line @typescript-eslint/no-extra-parens
          (newCategory || (otherCategories && otherCategories.length > 0) ?
            renderCategories()
            :
            <NoFilesMessage />)
      }
    </>
  );
};
