import 'antd/dist/antd.css';
import './Table.scss';
import { Button } from '../InteractiveUIControls/Button/Button';
import { Table as AntTable } from 'antd';
import { type ColumnType,
  type TableProps,
} from 'antd/lib/table';

type BlankColumnOptions<RecordType> = Partial<ColumnType<RecordType>>;

export const getBlankColumnConfig = (withoutBorder: boolean) => ({
  className: withoutBorder ? 'ant-blank-column' : 'ant-blank-column-with-bottom',
  width: '0.5rem',
});

export const getBlankColumn = <RecordType extends object>(
  opts?: BlankColumnOptions<RecordType>,
  withoutBorder = true,
): ColumnType<RecordType> => {
  return {
    ...getBlankColumnConfig(withoutBorder),
    ...opts,
  };
};

type TableExpandIconProps = {
  expanded: boolean,
  onExpand: (record: unknown, e: unknown) => void,
  record: unknown,
};

export const TableExpandIcon = ({
  expanded,
  onExpand,
  record,
}: TableExpandIconProps) => {
  if (expanded) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={(event) => {
          onExpand(record, event);
        }}
      >
        <Button
          icon='only'
          iconid='sort-reorder-4f'
          onClick={() => {}}
          squared
          type='white'
        />
      </a>
    );
  } else {
    // @ts-expect-error taken from admin portal
    if (record.children !== undefined) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={(event) => {
            onExpand(record, event);
          }}
        >
          {' '}
          <Button
            icon='only'
            iconid='sort-reorder-3f'
            onClick={() => {}}
            squared
            type='white'
          />
        </a>
      );
    }

    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const Table = <RecordType extends object,>(
  props: TableProps<RecordType>,
): JSX.Element => {
  return (
    <div className='table-component-wrapper'>
      <AntTable
        bordered
        expandable={{
          // @ts-expect-error taken from admin portal
          expandIcon: TableExpandIcon,
        }}
        pagination={false}
        {...props}
      />
    </div>
  );
};
