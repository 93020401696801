import './DataRoom.scss';
import { DataRoomFundSelector } from './DataRoomFundSelector';
import { dataRoomApi,
  type FundForDataRoom } from 'api/dataRoomApi';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { BASE_FMS_URL } from 'components/ReportsModule/ReportSelectors';
import { useState } from 'react';

const onFundSelected = async (fundId: string) => {
  const token = await dataRoomApi.getDataRoomToken({fundId});

  if (token) {
    const newWindow = window.open('', '_blank');
    const fmsUrl = `${BASE_FMS_URL}/documents/data-room/external?token=${token}`;
    if (newWindow) {
      newWindow.location.href = fmsUrl;
    }
  }
};

export const DataRoom = ({funds}: {funds: FundForDataRoom[],}) => {
  const [
    showModal,
    setShowModal,
  ] = useState(false);

  const handleButtonClick = () => {
    if (funds.length === 1) {
      onFundSelected(funds[0].fundId);
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <div className='data-room-card flex w-full flex-col justify-between gap-0.75 rounded-main p-0.875'>
        <div className='bold-[600] text-font-1 text-white-999'>
          Data room
        </div>
        <Button
          className='!h-8 w-[5.5rem]'
          data-test='data-room-modal-open'
          onClick={handleButtonClick}
          size='medium'
          tooltipWrapperClass='!justify-start'
          type='white'
        >
          View
        </Button>
      </div>
      {showModal && <DataRoomFundSelector
        funds={funds}
        onClose={() => setShowModal(false)}
        onFundSelected={onFundSelected}
      />}
    </>
  );
};
