import { organizationsApi } from '../../api/organizationsApi';
import {
  type NestedOrganization,
} from '../../types';
import { type Filters } from './filtersSlice';
import { type PayloadAction } from '@reduxjs/toolkit';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

export type OrganizationsStoreState = {
  displayedOrganization: NestedOrganization | null,
  displayedOrganizationWithPermissions: NestedOrganization | null,
  organizations: NestedOrganization[],
  organizationsError: string | null,
  organizationsPending: boolean,
  selectedDocumentsData: NestedOrganization | null,
};

const initialState: OrganizationsStoreState = {
  displayedOrganization: null,
  displayedOrganizationWithPermissions: null,
  organizations: [],
  organizationsError: null,
  organizationsPending: false,
  selectedDocumentsData: null,
};

export const fetchOrganizations = createAsyncThunk(
  'organizations/fetchOrganizations',
  async () => {
    const organizations = await organizationsApi.getNestedOrganizations();
    return organizations;
  },
);

export const fetchCurrentOrganization = createAsyncThunk(
  'organizations/fetchCurrentOrganization',
  async (id: string) => {
    const organization = await organizationsApi.getCurrentOrganizations(id);
    return organization;
  },
);

const organizationsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.organizationsPending = true;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizationsPending = false;
        state.organizations = action.payload;
      })
      .addCase(fetchOrganizations.rejected, (state) => {
        state.organizationsPending = false;
        state.organizationsError = 'Could not fetch organizations';
      })
      .addCase(fetchCurrentOrganization.pending, (state) => {
        state.organizationsPending = true;
      })
      .addCase(fetchCurrentOrganization.fulfilled, (state, action) => {
        state.organizationsPending = false;
        state.displayedOrganizationWithPermissions = action.payload;
      })
      .addCase(fetchCurrentOrganization.rejected, (state) => {
        state.organizationsPending = false;
        state.organizationsError = 'Could not fetch organizations';
      });
  },
  initialState,
  name: 'organizations',
  reducers: {
    filterDocumentsDataByAccounts: (state, action: PayloadAction<string[]>) => {
      const accountIds = action.payload;
      const foundOrganization = state.displayedOrganizationWithPermissions;

      if (foundOrganization) {
        const updatedFunds = foundOrganization.funds.map((fund) => ({
          ...fund,
          accounts: fund.accounts.filter((account) =>
            accountIds.includes(account.accountId)),
        }));

        state.selectedDocumentsData = {
          ...foundOrganization,
          funds: updatedFunds.filter((fund) => Boolean(fund.accounts.length)),
        };
      } else {
        state.selectedDocumentsData = null;
      }
    },
    filterDocumentsDataByFunds: (state, action: PayloadAction<string[]>) => {
      const fundIds = action.payload;
      const foundOrganization = state.displayedOrganizationWithPermissions;

      if (!foundOrganization) {
        state.selectedDocumentsData = null;
        return;
      }

      state.selectedDocumentsData = {
        ...foundOrganization,
        funds: foundOrganization.funds.filter((fund) => fundIds.includes(fund.fundId)),
      };
    },
    filterDocumentsDataByTypes: (state, action: PayloadAction<string[]>) => {
      const typeIds = action.payload;
      const foundOrganization = state.displayedOrganizationWithPermissions;

      if (!foundOrganization) {
        return;
      }

      if (state.selectedDocumentsData) {
        const allAccounts = foundOrganization.funds?.flatMap((fund) => fund.accounts);
        for (const fund of state.selectedDocumentsData.funds) {
          for (const account of fund.accounts) {
            const allPermissions = allAccounts
              .find((acc) => acc.accountId === account.accountId)?.permissions || [];
            account.permissions = allPermissions
              .filter((type) => typeIds.includes(type.code));
          }
        }
      }
    },
    filterDocumentsDataByYears: (state, action: PayloadAction<string[]>) => {
      const yearIds = action.payload;
      const foundOrganization = state.displayedOrganizationWithPermissions;
      if (!foundOrganization) {
        return;
      }

      if (state.selectedDocumentsData) {
        const allAccounts = foundOrganization.funds.flatMap((fund) => fund.accounts);
        for (const fund of state.selectedDocumentsData.funds) {
          for (const account of fund.accounts) {
            const allYears = allAccounts
              .find((acc) => acc.accountId === account.accountId)?.yearsAndQuarters || [];
            account.yearsAndQuarters = allYears
              .filter((year) => yearIds.includes(year.year.toString()));
          }
        }
      }
    },

    filterOrganizationByAccounts: (state, action: PayloadAction<string[]>) => {
      const accountIds = action.payload;
      const foundOrganization = state.organizations
        ?.find((org) => org.organizationId ===
          state.displayedOrganization?.organizationId);

      if (!foundOrganization) {
        state.displayedOrganization = null;
        return;
      }

      const updatedFunds = foundOrganization.funds.map((fund) => ({
        ...fund,
        accounts: fund.accounts.filter((account) =>
          accountIds.includes(account.accountId)),
      }));

      state.displayedOrganization = {
        ...foundOrganization,
        funds: updatedFunds.filter((fund) => Boolean(fund.accounts.length)),
      };
    },
    resetDisplayedOrganizations: (state, action: PayloadAction<Filters>) => {
      const { fundIds,
        organizationId,
        accountIds } = action.payload;
      const currentOrganization = state.organizations
        .find((org) => org.organizationId === organizationId);

      if (!currentOrganization) {
        return;
      }

      const updatedFunds = currentOrganization.funds
        .filter((fund) => fundIds.includes(fund.fundId))
        .map((fund) => ({
          ...fund,
          accounts: fund.accounts.filter((acc) => accountIds.includes(acc.accountId)),
        }));

      if (state.displayedOrganization) {
        state.displayedOrganization = {
          ...currentOrganization,
          funds: updatedFunds,
        };
      }
    },
    resetOrganizations: () => ({ ...initialState }),
    resetSelectedDocumentsData: (state) => {
      state.selectedDocumentsData = null;
    },
    selectDocumentsData: (state) => {
      const foundOrganization = state.displayedOrganizationWithPermissions;
      if (foundOrganization) {
        state.selectedDocumentsData = { ...foundOrganization };
      }
    },
    selectOrganization: (state, action: PayloadAction<string>) => {
      const organizationId = action.payload;
      const foundOrganization = state.organizations?.find(
        (org) => org.organizationId === organizationId,
      );

      if (foundOrganization) {
        state.displayedOrganization = foundOrganization;
      } else {
        state.displayedOrganization = null;
      }

      state.selectedDocumentsData = null;
    },
    toggleFilterOrganizationByAllFunds: (
      state,
      action: PayloadAction<{
        selectedFundIds: string[],
      }>,
    ) => {
      const { selectedFundIds } = action.payload;
      const foundOrganization = state.organizations
        ?.find((org) => org.organizationId ===
          state.displayedOrganization?.organizationId);

      if (state.displayedOrganization && foundOrganization) {
        const updatedFunds = foundOrganization.funds
          .filter((fund) => selectedFundIds.includes(fund.fundId));
        state.displayedOrganization = {
          ...foundOrganization,
          funds: updatedFunds,
        };
      }
    },
  },
});

const organizationsReducer = organizationsSlice.reducer;

export const { selectOrganization,
  filterOrganizationByAccounts,
  resetOrganizations,
  filterDocumentsDataByFunds,
  filterDocumentsDataByAccounts,
  filterDocumentsDataByTypes,
  resetSelectedDocumentsData,
  filterDocumentsDataByYears,
  resetDisplayedOrganizations,
  selectDocumentsData,
  toggleFilterOrganizationByAllFunds,
} = organizationsSlice.actions;

export { organizationsReducer };

