/* eslint-disable max-len */
import style from './ForgotPassword.module.scss';
import authApi from 'api/auth';
import { actionCreator } from 'api/utils';
import { LoginBackLink,
  LoginButton,
  LoginHeading,
  LoginInput,
  LoginParagraph } from 'components';
import { useAuthCheck,
  useDocumentTitle } from 'hooks';
import React, { useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notificationsService } from 'services';
import { messages } from 'services/notifications';
import { globalActionTypes } from 'store/actions';
import isEmail from 'validator/lib/isEmail';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    email,
    setEmail,
  ] = useState('');

  useAuthCheck();

  // @ts-expect-error state not typed :(
  const isLoading = useSelector((state) => state.global.loading) as boolean;

  useDocumentTitle('Forgot password');

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isEmail(email)) {
      notificationsService.error(messages.wrongEmail);
      return;
    }

    dispatch(actionCreator(globalActionTypes.LOADING, true));
    const payload = { email };
    try {
      await authApi.forgotPassword(payload);
      notificationsService.success(messages.resetPasswordSuccess);
      navigate('/auth/login');
    } catch {
      notificationsService.error(messages.standartError);
    }

    dispatch(actionCreator(globalActionTypes.LOADING, false));
  };

  return <div className={style.loginWrapper}>
    <div className='flex flex-col gap-[0.5rem]'>
      <LoginBackLink />
      <div className='flex flex-col gap-[0.5rem]'>
        <LoginHeading>Trouble logging in?</LoginHeading>
        <LoginParagraph>Enter your email address, and we’ll send you a link to create new password.</LoginParagraph>
      </div>
    </div>
    <form onSubmit={onSubmit}>
      <div className={style.inputsWrapper}>
        <LoginInput name='forgot-password-username-input' onChange={setEmail} placeholder='Email Address' type='email' value={email} />
      </div>
      <LoginButton isLoading={isLoading} name='forgot-password-button' text='Send Link' type='submit' />
    </form>
  </div>;
};
