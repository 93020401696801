import { getItemsFromStorage } from '../types';
import axios, { type AxiosRequestConfig } from 'axios';
import environment from 'config/environment';

const localhostEnvironment = environment.apiUrl;

const contentTypes = {
  formData: 'multipart/form-data' as FormData,
  json: 'application/json' as Json,
};

type FormData = 'multipart/form-data';

type Json = 'application/json';

type Pdf = 'application/pdf';

export type ContentTypes = FormData | Json | Pdf;

class Api {
  private baseUrl: string;

  public constructor (baseUrl = '/') {
    this.baseUrl = localhostEnvironment + baseUrl;
  }

  public get<T> (url: string, responseType: AxiosRequestConfig['responseType'] = 'json', contentType?: ContentTypes) {
    const config = this.buildConfig(url,
      contentType || contentTypes.json,
      undefined,
      responseType);

    return axios<T>(config);
  }

  public post<D, T> (
    url: string,
    data: D,
    options: Partial<AxiosRequestConfig>,
    params?: unknown,
    multipart = false,
  ) {
    const contentType = multipart ? contentTypes.formData : contentTypes.json;

    const config = this.buildConfig(
      url,
      contentType,
      data,
    );

    return axios<T>({
      method: 'post',
      ...config,
      params,
      ...options,
    });
  }

  private buildConfig<D> (url: string, contentType: ContentTypes, data?: D, responseType?: AxiosRequestConfig['responseType']) {
    const config: AxiosRequestConfig<D> = {};

    const token = getItemsFromStorage<{ access_token: string, }>([
      'access_token',
    ], sessionStorage).access_token;
    config.headers = {
      'Content-Type': contentType || 'application/json',
    };
    config.url = url ? this.baseUrl + url : this.baseUrl;
    config.withCredentials = true;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (responseType) {
      config.responseType = responseType;
    }

    if (data) {
      config.data = data;
    }

    return config;
  }
}

export {Api};
