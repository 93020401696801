import { type CapitalAccountsStoreState } from '../../../../../store/slices';
import {
  selectPeriodForAccount,
} from '../../../../../store/slices';
import { Dropdown } from '../../../../Dropdown';
import { type SelectOption } from 'hooks';
import { type FC } from 'react';
import {
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { type CapitalAccountAccount } from 'types';

export type CapitalAccountsPeriodSelectorProps = Pick<
CapitalAccountAccount, '_id'> & {
  closeSignal?: boolean,
  fundId: string,
  periodList: string[],
};

export const CapitalAccountsPeriodSelector: FC<CapitalAccountsPeriodSelectorProps> = ({
  _id,
  closeSignal,
  fundId,
  periodList,
}) => {
  const initialPeriod = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .selectedFundAccountPeriodMap[fundId][_id],
  ) as string;
  const dispatch = useDispatch();
  const periodOptions = periodList.map((period) => ({
    display: period,
    id: period,
  }));

  const [
    selectedPeriod,
    setSelectedPeriod,
  ] = useState<SelectOption>(
    periodOptions.find((period) => period.id === initialPeriod) || periodOptions[0],
  );

  const onSelectPeriod = (newValue: SelectOption) => {
    dispatch(selectPeriodForAccount({
      accountId: _id,
      fundId,
      period: newValue.id,
    }));

    setSelectedPeriod(newValue);
  };

  return <button
    className='flex size-full flex-row items-center justify-between px-0.5'
    type='button'
  >
    <Dropdown
      closeSignal={closeSignal}
      dataSelectionValue='ca-fund-selector-dropdown'
      displayBackgroundColor='#D9D9D9'
      displayColor='#4A5056'
      displayFontWeight='500'
      displayHeight='1.25rem'
      displaySize='1.625em'
      optionList={periodOptions}
      optionsClassName='!max-h-[6rem]'
      placeholder='Period'
      setValue={onSelectPeriod}
      type='white'
      value={selectedPeriod}
    />
  </button>;
};
