export const LoginLogo = () =>
  <svg fill='none' height='40' viewBox='0 0 91 40' width='91' xmlns='http://www.w3.org/2000/svg'>
    <path clipRule='evenodd' d='M14.738 0C6.3727 0 0 6.2491 0 14.4938C0 22.7355 6.3305 28.9876 14.738 28.9876C23.1455 28.9876 29.476 22.7385 29.476 14.4938C29.476 6.2491 23.1033 0 14.738 0ZM14.738 4.66648C20.3028 4.66648 24.5261 8.88983 24.5261 14.4938C24.5261 20.0978 20.2606 24.3212 14.738 24.3212C9.21539 24.3212 4.99205 20.0888 4.99205 14.4938C4.99205 8.89887 9.17319 4.66648 14.738 4.66648Z' fill='black' fillRule='evenodd' />
    <path d='M55.8139 7.31323C49.4834 7.31323 44.7265 11.9857 44.7265 18.1534C44.7265 24.3181 49.4834 28.9937 55.8139 28.9937C61.2159 28.9937 65.4785 25.5873 66.3286 20.7098H61.5023C60.7668 23.0219 58.536 24.6075 55.8169 24.6075C52.1663 24.6075 49.405 21.804 49.405 18.1534C49.405 14.5029 52.1663 11.6994 55.8169 11.6994C58.536 11.6994 60.7758 13.285 61.5023 15.5971H66.3286C65.4785 10.7287 61.2129 7.31323 55.8139 7.31323Z' fill='black' />
    <path d='M37.0545 7.79254H32.5026V28.5144H37.0545V18.4398C37.0545 14.6234 39.6139 12.1485 44 12.1485H44.7265V7.47601H44C41.0729 7.47601 38.6793 8.89887 37.0545 11.2502V7.79254Z' fill='black' />
    <path clipRule='evenodd' d='M67.9926 18.1534C67.9926 11.9857 72.3395 7.31323 78.1907 7.31323C81.2384 7.31323 83.7555 8.61249 85.4617 10.7709V7.8046H90.0136V28.5144H85.4617V25.5481C83.7525 27.6944 81.2384 28.9937 78.1907 28.9937C72.3395 28.9937 67.9926 24.3181 67.9926 18.1534ZM85.4527 18.1534C85.4527 14.5029 82.7305 11.6994 79.08 11.6994C75.4294 11.6994 72.6681 14.5029 72.6681 18.1534C72.6681 21.804 75.3872 24.6075 79.08 24.6075C82.7727 24.6075 85.4527 21.804 85.4527 18.1534Z' fill='black' fillRule='evenodd' />
    <path d='M14.7381 24.3724C20.2577 24.3724 24.5203 20.1521 24.5263 14.5541C24.5203 14.5481 22.4915 12.3083 14.7592 14.5541C7.02699 16.7999 4.99822 14.5601 4.99219 14.5541C4.99822 20.143 9.21855 24.3724 14.7381 24.3724Z' fill='url(#paint0_linear_315_833)' />
    <g opacity='0.6'>
      <path clipRule='evenodd' d='M56.2027 34.7303C56.061 35.0106 55.9887 35.3332 55.9887 35.6949L55.9856 35.6979C55.9856 36.0657 56.058 36.3913 56.1997 36.6746C56.3444 36.955 56.5433 37.1751 56.7935 37.3288C57.0437 37.4825 57.3211 37.5609 57.6225 37.5609C57.8908 37.5609 58.126 37.5067 58.3279 37.3981C58.5329 37.2896 58.6897 37.1479 58.8012 36.9701V37.5006H59.7206V32.7136H58.8012V34.4138C58.6776 34.2449 58.5088 34.1123 58.2978 34.0128C58.0868 33.9073 57.8637 33.8561 57.6316 33.8561C57.3241 33.8561 57.0468 33.9314 56.7966 34.0822C56.5463 34.2329 56.3504 34.4499 56.2027 34.7303ZM58.6806 35.1463C58.768 35.303 58.8103 35.4899 58.8103 35.71C58.8103 35.9301 58.768 36.12 58.6806 36.2797C58.5932 36.4335 58.4787 36.5541 58.3309 36.6415C58.1832 36.7229 58.0235 36.7651 57.8577 36.7651C57.6919 36.7651 57.5381 36.7229 57.3904 36.6355C57.2487 36.551 57.1312 36.4275 57.0407 36.2677C56.9533 36.1019 56.9111 35.915 56.9111 35.6979C56.9111 35.4809 56.9533 35.297 57.0407 35.1403C57.1312 34.9805 57.2487 34.8599 57.3904 34.7785C57.5351 34.6971 57.6888 34.6549 57.8577 34.6549C58.0265 34.6549 58.1832 34.6971 58.3309 34.7845C58.4787 34.8659 58.5932 34.9865 58.6806 35.1463Z' fill='black' fillRule='evenodd' />
      <path clipRule='evenodd' d='M62.7955 34.4409C62.9131 34.2691 63.0728 34.1274 63.2748 34.0219C63.4828 33.9133 63.7179 33.8591 63.9802 33.8591C64.2877 33.8591 64.562 33.9344 64.8092 34.0852C65.0594 34.2359 65.2553 34.4529 65.397 34.7333C65.5447 35.0106 65.6171 35.3302 65.6171 35.6979C65.6171 36.0657 65.5447 36.3913 65.397 36.6746C65.2553 36.955 65.0594 37.172 64.8092 37.3288C64.562 37.4855 64.2877 37.5609 63.9802 37.5609C63.7119 37.5609 63.4768 37.5097 63.2748 37.4042C63.0758 37.2956 62.9161 37.157 62.7955 36.9912V37.5036H61.8911V32.7166H62.7955V34.4409ZM64.6886 35.6949C64.6886 35.4779 64.6434 35.294 64.553 35.1372C64.4655 34.9775 64.351 34.8569 64.2033 34.7755C64.0616 34.6941 63.9048 34.6519 63.736 34.6519C63.5672 34.6519 63.4165 34.6941 63.2688 34.7815C63.1271 34.8629 63.0095 34.9835 62.9191 35.1433C62.8317 35.303 62.7895 35.4899 62.7895 35.707C62.7895 35.924 62.8317 36.1109 62.9191 36.2707C63.0095 36.4305 63.1271 36.5541 63.2688 36.6385C63.4165 36.7199 63.5702 36.7621 63.736 36.7621C63.9018 36.7621 64.0586 36.7199 64.2033 36.6324C64.351 36.545 64.4655 36.4244 64.553 36.2647C64.6434 36.1049 64.6886 35.915 64.6886 35.6949Z' fill='black' fillRule='evenodd' />
      <path d='M72.9032 37.5458C72.5897 37.5458 72.3033 37.4916 72.0501 37.3831C71.7999 37.2745 71.6009 37.1208 71.4562 36.9158C71.3085 36.7138 71.2332 36.4727 71.2301 36.1983H72.2008C72.2129 36.3852 72.2792 36.5299 72.3938 36.6385C72.5143 36.747 72.6771 36.8013 72.8851 36.8013C73.0931 36.8013 73.2619 36.753 73.3825 36.6535C73.5031 36.551 73.5634 36.4154 73.5634 36.2526C73.5634 36.12 73.5212 36.0084 73.4398 35.924C73.3584 35.8366 73.2529 35.7703 73.1293 35.7221C73.0087 35.6708 72.8399 35.6135 72.6259 35.5532C72.3335 35.4658 72.0923 35.3844 71.9084 35.3C71.7275 35.2126 71.5708 35.086 71.4351 34.9172C71.3055 34.7453 71.2422 34.5162 71.2422 34.2329C71.2422 33.9646 71.3085 33.7325 71.4412 33.5335C71.5738 33.3346 71.7637 33.1838 72.0049 33.0813C72.246 32.9728 72.5234 32.9185 72.8339 32.9185C73.3011 32.9185 73.6779 33.0331 73.9673 33.2622C74.2597 33.4853 74.4225 33.8018 74.4527 34.2057H73.4579C73.4488 34.049 73.3825 33.9224 73.2559 33.8229C73.1353 33.7204 72.9725 33.6661 72.7706 33.6661C72.5927 33.6661 72.451 33.7114 72.3425 33.8018C72.24 33.8922 72.1858 34.0249 72.1858 34.1967C72.1858 34.3173 72.2249 34.4198 72.3033 34.5012C72.3847 34.5795 72.4842 34.6429 72.6018 34.6941C72.7223 34.7423 72.8912 34.7966 73.1052 34.8629C73.3976 34.9503 73.6388 35.0347 73.8226 35.1222C74.0095 35.2096 74.1663 35.3392 74.3019 35.511C74.4346 35.6829 74.5039 35.909 74.5039 36.1893C74.5039 36.4305 74.4406 36.6566 74.317 36.8615C74.1934 37.0695 74.0095 37.2353 73.7684 37.3589C73.5272 37.4795 73.2408 37.5398 72.9092 37.5398L72.9032 37.5458Z' fill='black' />
      <path d='M86.0525 34.046C85.854 34.3949 85.7545 34.7918 85.7541 35.2337C85.7545 35.6786 85.854 36.0725 86.0525 36.4214C86.2575 36.7711 86.5318 37.0454 86.8755 37.2444C87.2221 37.4403 87.614 37.5368 88.0451 37.5368C88.5546 37.5368 88.9977 37.4072 89.3715 37.154C89.7513 36.8977 90.0166 36.536 90.1673 36.0748H89.1273C89.0248 36.2888 88.8771 36.4546 88.6872 36.5661C88.5003 36.6746 88.2863 36.7289 88.0391 36.7289C87.7768 36.7289 87.5417 36.6686 87.3337 36.548C87.1317 36.4214 86.9719 36.2466 86.8544 36.0235C86.7428 35.7944 86.6856 35.5352 86.6856 35.2337C86.6856 34.9323 86.7428 34.667 86.8544 34.4439C86.9719 34.2178 87.1317 34.046 87.3337 33.9254C87.5417 33.8018 87.7768 33.7385 88.0391 33.7385C88.2863 33.7385 88.5003 33.7928 88.6872 33.9013C88.8771 34.0098 89.0248 34.1696 89.1273 34.3866H90.1673C90.0166 33.9314 89.7513 33.5727 89.3715 33.3135C88.9917 33.0542 88.5485 32.9246 88.0451 32.9246C87.614 32.9246 87.2252 33.0241 86.8755 33.223C86.5288 33.419 86.2545 33.6933 86.0525 34.046Z' fill='black' />
      <path d='M83.9062 37.5006L85.5612 32.9849H84.5995L83.3636 36.5752L82.1216 32.9849H81.1509L82.8059 37.5006H83.9062Z' fill='black' />
      <path d='M79.924 34.474C80.0416 34.2841 80.1923 34.1364 80.3762 34.0279C80.5661 33.9194 80.7802 33.8651 81.0243 33.8651V34.8147H80.7862C80.5028 34.8147 80.2858 34.881 80.1381 35.0167C79.9964 35.1493 79.924 35.3844 79.924 35.716V37.5006H79.0197V33.9164H79.924V34.474Z' fill='black' />
      <path clipRule='evenodd' d='M78.4589 35.9813C78.477 35.8788 78.486 35.7612 78.486 35.6316V35.6286C78.486 35.2789 78.4107 34.9714 78.2599 34.7031C78.1152 34.4318 77.9042 34.2238 77.6329 34.0761C77.3646 33.9284 77.0722 33.8561 76.7195 33.8561C76.3668 33.8561 76.0533 33.9314 75.782 34.0822C75.5107 34.2329 75.2997 34.4499 75.149 34.7303C74.9982 35.0106 74.9229 35.3392 74.9229 35.707C74.9229 36.0747 74.9982 36.3973 75.149 36.6777C75.3027 36.958 75.5167 37.175 75.788 37.3318C76.0654 37.4825 76.3759 37.5579 76.7195 37.5579C77.1446 37.5579 77.5063 37.4494 77.7987 37.2293C78.0911 37.0062 78.2901 36.7199 78.3926 36.3762H77.4159C77.2742 36.6656 77.0361 36.8103 76.6984 36.8103C76.4663 36.8103 76.2704 36.7379 76.1106 36.5902C75.9508 36.4425 75.8604 36.2405 75.8393 35.9813H78.4589ZM77.2953 34.8147C77.4611 34.9533 77.5455 35.1402 77.5485 35.3724H75.8453C75.8785 35.1312 75.9719 34.9413 76.1227 34.8087C76.2794 34.67 76.4693 34.6006 76.6924 34.6006C76.9305 34.6006 77.1325 34.673 77.2953 34.8147Z' fill='black' fillRule='evenodd' />
      <path d='M67.3534 39.1948L69.5721 33.9164H68.6105L67.673 36.4184L66.7475 33.9164H65.7316L67.1665 37.4102L66.3888 39.1948H67.3534Z' fill='black' />
      <path clipRule='evenodd' d='M55.6903 35.6316C55.6903 35.7612 55.6812 35.8788 55.6631 35.9813H53.0435C53.0646 36.2405 53.1551 36.4425 53.3148 36.5902C53.4746 36.7379 53.6705 36.8103 53.9027 36.8103C54.2403 36.8103 54.4784 36.6656 54.6201 36.3762H55.5968C55.4943 36.7199 55.2954 37.0062 55.003 37.2293C54.7105 37.4494 54.3488 37.5579 53.9238 37.5579C53.5801 37.5579 53.2696 37.4825 52.9923 37.3318C52.721 37.175 52.5069 36.958 52.3532 36.6777C52.2025 36.3973 52.1271 36.0747 52.1271 35.707C52.1271 35.3392 52.2025 35.0106 52.3532 34.7303C52.5039 34.4499 52.7149 34.2329 52.9862 34.0822C53.2575 33.9314 53.5711 33.8561 53.9238 33.8561C54.2765 33.8561 54.5689 33.9284 54.8372 34.0761C55.1085 34.2238 55.3195 34.4318 55.4642 34.7031C55.6149 34.9714 55.6903 35.2789 55.6903 35.6286V35.6316ZM54.7527 35.3724C54.7497 35.1402 54.6653 34.9533 54.4995 34.8147C54.3367 34.673 54.1348 34.6006 53.8966 34.6006C53.6735 34.6006 53.4836 34.67 53.3269 34.8087C53.1762 34.9413 53.0827 35.1312 53.0495 35.3724H54.7527Z' fill='black' fillRule='evenodd' />
      <path d='M51.2318 34.0279C51.0479 34.1364 50.8972 34.2841 50.7796 34.474V33.9164H49.8752V37.5006H50.7796V35.716C50.7796 35.3844 50.852 35.1493 50.9936 35.0167C51.1413 34.881 51.3584 34.8147 51.6418 34.8147H51.8799V33.8651C51.6357 33.8651 51.4217 33.9194 51.2318 34.0279Z' fill='black' />
      <path clipRule='evenodd' d='M49.3417 35.6316C49.3417 35.7612 49.3327 35.8788 49.3146 35.9813H46.695C46.7161 36.2405 46.8065 36.4425 46.9663 36.5902C47.126 36.7379 47.322 36.8103 47.5541 36.8103C47.8917 36.8103 48.1299 36.6656 48.2716 36.3762H49.2483C49.1458 36.7199 48.9468 37.0062 48.6544 37.2293C48.362 37.4494 48.0002 37.5579 47.5752 37.5579C47.2315 37.5579 46.921 37.4825 46.6437 37.3318C46.3724 37.175 46.1584 36.958 46.0046 36.6777C45.8539 36.3973 45.7785 36.0747 45.7785 35.707C45.7785 35.3392 45.8539 35.0106 46.0046 34.7303C46.1554 34.4499 46.3664 34.2329 46.6377 34.0822C46.909 33.9314 47.2225 33.8561 47.5752 33.8561C47.9279 33.8561 48.2203 33.9284 48.4886 34.0761C48.7599 34.2238 48.9679 34.4318 49.1156 34.7031C49.2663 34.9714 49.3417 35.2789 49.3417 35.6286V35.6316ZM48.4042 35.3724C48.4012 35.1402 48.3168 34.9533 48.151 34.8147C47.9882 34.673 47.7862 34.6006 47.5481 34.6006C47.325 34.6006 47.1351 34.67 46.9783 34.8087C46.8276 34.9413 46.7341 35.1312 46.701 35.3724H48.4042Z' fill='black' fillRule='evenodd' />
      <path d='M44.5998 37.5006L45.6488 33.9164H44.7776L44.1446 36.6385L43.4724 33.9164H42.5137L41.8294 36.6475L41.1964 33.9164H40.277L41.3321 37.5006H42.3148L42.9689 34.9956L43.6231 37.5006H44.5998Z' fill='black' />
      <path clipRule='evenodd' d='M38.2754 37.5579C37.9317 37.5579 37.6212 37.4825 37.3439 37.3318C37.0666 37.175 36.8495 36.958 36.6897 36.6777C36.533 36.3973 36.4576 36.0747 36.4576 35.707C36.4576 35.3392 36.536 35.0167 36.6958 34.7363C36.8586 34.456 37.0816 34.2389 37.362 34.0882C37.6423 33.9314 37.9558 33.8561 38.2995 33.8561C38.6431 33.8561 38.9567 33.9344 39.237 34.0882C39.5174 34.2389 39.7374 34.456 39.8972 34.7363C40.06 35.0167 40.1444 35.3392 40.1444 35.707C40.1444 36.0747 40.06 36.3973 39.8912 36.6777C39.7284 36.958 39.5023 37.175 39.2189 37.3318C38.9386 37.4825 38.6251 37.5579 38.2754 37.5579ZM38.2754 36.7711C38.4382 36.7711 38.5919 36.7319 38.7336 36.6535C38.8813 36.5721 38.9958 36.4516 39.0833 36.2918C39.1707 36.132 39.2129 35.9391 39.2129 35.71C39.2129 35.3694 39.1225 35.1071 38.9416 34.9262C38.7637 34.7393 38.5467 34.6489 38.2874 34.6489C38.0282 34.6489 37.8111 34.7423 37.6333 34.9262C37.4615 35.1071 37.374 35.3694 37.374 35.71C37.374 36.0506 37.4584 36.3129 37.6273 36.4998C37.7991 36.6807 38.0161 36.7711 38.2754 36.7711Z' fill='black' fillRule='evenodd' />
      <path clipRule='evenodd' d='M35.9964 35.0619C36.114 34.8509 36.1713 34.6248 36.1713 34.3836L36.1682 34.3806C36.1682 34.1153 36.1049 33.8741 35.9813 33.6631C35.8608 33.4521 35.6799 33.2863 35.4327 33.1657C35.1885 33.0452 34.8901 32.9849 34.5404 32.9849H32.8794V37.5006H33.7838V35.7673H34.5404C34.9112 35.7673 35.2187 35.704 35.4598 35.5743C35.704 35.4447 35.8849 35.2729 35.9964 35.0619ZM35.0589 34.8689C34.9353 34.9805 34.7514 35.0378 34.5012 35.0378H33.7838V33.7234H34.5012C34.9926 33.7234 35.2398 33.9435 35.2398 34.3836C35.2398 34.5916 35.1795 34.7514 35.0589 34.8689Z' fill='black' fillRule='evenodd' />
    </g>
    <defs>
      <linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_315_833' x1='4.99219' x2='24.5263' y1='17.3338' y2='14.6506'>
        <stop stopColor='#FCFEFE' />
        <stop offset='0.0703818' stopColor='#EEF8F8' />
        <stop offset='0.129354' stopColor='#E1F3F2' />
        <stop offset='0.179429' stopColor='#D4EDEC' />
        <stop offset='0.223114' stopColor='#C8E8E7' />
        <stop offset='0.262922' stopColor='#BDE3E2' />
        <stop offset='0.301363' stopColor='#B0DEDC' />
        <stop offset='0.340946' stopColor='#A3D9D7' />
        <stop offset='0.384182' stopColor='#96D3D0' />
        <stop offset='0.433582' stopColor='#87CDCA' />
        <stop offset='0.491656' stopColor='#76C6C2' />
        <stop offset='0.560915' stopColor='#63BEBA' />
        <stop offset='0.643868' stopColor='#4FB5B1' />
        <stop offset='0.743026' stopColor='#37ABA7' />
        <stop offset='0.8609' stopColor='#1DA09B' />
        <stop offset='1' stopColor='#00948E' />
      </linearGradient>
    </defs>
  </svg>;

