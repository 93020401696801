import './Input.scss';
import classNames from 'classnames';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { type BaseSyntheticEvent,
  type FC } from 'react';

type InputProps = {
  className?: string,
  dataTest?: string,
  disabled?: boolean,
  errorMessage?: string,
  iconClassName?: string,
  iconid?: string,
  id?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onClick?: (e: BaseSyntheticEvent) => void,
  onReset?: () => void,
  placeholder?: string,
  style?: React.CSSProperties,
  textFieldClass?: string,
  title?: string,
  type?: string,
  value?: number | string,
};

export const Input: FC<InputProps> = ({
  id,
  className,
  textFieldClass,
  iconClassName,
  disabled,
  type = 'text',
  dataTest,
  placeholder = '',
  value,
  title,
  onChange,
  onClick,
  iconid,
  style,
  onReset,
  errorMessage,
}) => {
  return (
    <div className={`input-wrapper ${className}`} style={style}>
      {iconid &&
        <div className='prefix'>
          <Svgicon className={iconClassName} id={iconid} />
        </div>
      }
      <input
        className={classNames('text-field', textFieldClass, type)}
        data-test={dataTest}
        disabled={disabled}
        id={id}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        title={title}
        type='text'
        value={value}
      />
      {value && onReset &&
      <div className='prefix' onClick={onReset}>
        <Svgicon className={iconClassName} id='close-1f' />
      </div>
      }
      {errorMessage && <span className='error-message'>{errorMessage}</span>}
    </div>

  );
};

