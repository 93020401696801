import { type TableColumnDisplayValues } from '../../../types';
import { formatNumber } from '../../Pipes/numberPipes';
import style from './CapitalAccountsNumbersAggregationChart.module.scss';
import classNames from 'classnames';
import { type FC } from 'react';

export type AggregationBoxProps = {
  name: string,
  value: number,
};

export type CapitalAccountsNumbersAggregationChartProps = {
  values: TableColumnDisplayValues,
};

const AggregationBox: FC<AggregationBoxProps> = ({
  name,
  value,
}) => <div className={style.container}>
  <p className={style.label}>
    {name}
  </p>
  <p className={style.number}>
    {formatNumber(value, 0)}
  </p>
</div>;

export const CapitalAccountsNumbersAggregationChart: FC<
CapitalAccountsNumbersAggregationChartProps> = ({
  values,
}) => {
  return <div className={classNames(style.capitalAccountsNumbersBox, 'hidden dt:flex')}>
    <AggregationBox
      name={'Contribution (Net due/advance)'}
      value={values.contribution}
    />
    <AggregationBox
      name={'Distribution'}
      value={values.distribution}
    />
    <AggregationBox
      name={'Net Gain/(Loss)'}
      value={values.unrealizedGainLoss + values.realizedGainLoss + values.other}
    />
    <AggregationBox
      name={'Partners Capital (NAV)'}
      value={values.nav}
    />
  </div>;
};
