import style from './BooleanButton.module.scss';
import { type FC } from 'react';
import React from 'react';

type BooleanButtonProps = React.AriaAttributes &
React.DetailedHTMLProps<
React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  testName: string,
};

const BooleanButton: FC<BooleanButtonProps> = ({testName,
  children,
  ...rest}) => {
  return (
    <button
      className={style.booleanButtonContainer}
      data-test={testName}
      type='button'
      {...rest}
    >
      {children}
    </button>
  );
};

export default BooleanButton;
