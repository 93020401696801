import authApi from '../../../api/auth';
import {
  analyticsService,
  notificationsService,
} from '../../../services';
import style from './SelectAuthentication.module.scss';
import { SelectAuthMethodOptions } from './SelectAuthMethodOptions';
import {
  LoginBackLink,
  LoginButton,
  LoginHeading,
  LoginParagraph,
  LoginPhoneInput,
} from 'components';
import {
  SignUpActivationContext,
} from 'contexts';
import {
  useAuthCheck,
  useToken,
} from 'hooks';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useLocation,
  useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';
import {
  CHANNEL_VALUES,
  getItemsFromStorage,
  VerificationChannel,
} from 'types';

const LINK_BACK_TO = '/firm/settings';

const getSignUpLink = (token: string) => `/auth/signup?token=${token}`;

export const getSelectAuthLink = (
  token: string,
) => `/auth/select-auth-method?token=${token}`;

const SelectAuthentication = () => {
  const {
    currentAuthenticationMethod,
    isSignUp,
    password,
    phoneNumber,
    setAuthenticationMethodInContext,
    setPhoneNumberInContext,
    setShowSecurityModalInContext,
  } = useContext(SignUpActivationContext);
  useAuthCheck();

  const { search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const { dataToken } = Object.fromEntries(queryParameters.entries());

  const [
    displayPhoneInput,
    setDisplayPhoneInput,
  ] = useState(false);

  const isLoading = useSelector<
  { global: { loading: boolean, }, }>(
    (state) => state.global.loading,
  ) as boolean;

  const parameterToken = useToken();

  const userToken = sessionStorage.getItem('access_token');

  // require refresh token to refresh session
  const refreshToken = sessionStorage.getItem('refresh_token');

  // Ensures that users have to set auth method
  const { isMFAmethodFinalised } = getItemsFromStorage([
    'isMFAmethodFinalised',
  ], sessionStorage);
  const doNotShowBackButton = isMFAmethodFinalised === 'false';

  const currentVerificationChannel = useSelector<
  { auth: { verificationChannel: VerificationChannel | null, }, }>(
    (state) => state.auth.verificationChannel,
  ) as VerificationChannel | null;

  const currentMobilePhoneNumber = useSelector<
  { auth: { phone: number | undefined, }, }>(
    (state) => state.auth.phone,
  ) as number | undefined;

  const [
    vChannels,
    setVChannels,
  ] = useState(CHANNEL_VALUES);

  const token = parameterToken || userToken;

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (isSignUp) {
      const newVChannels = CHANNEL_VALUES.map((channel) => ({
        ...channel,
        enabled: true,
      }));

      setVChannels(newVChannels);
    } else if (currentVerificationChannel) {
      setAuthenticationMethodInContext(currentVerificationChannel);
      const newVChannels = CHANNEL_VALUES.map((channel) => {
        if (channel.api === currentVerificationChannel) {
          return {
            ...channel,
            enabled: false,
          };
        }

        return {
          ...channel,
          enabled: true,
        };
      });

      setVChannels(newVChannels);
    } else {
      const parameters = new URLSearchParams();
      parameters.append('token', token ? token : '');
      parameters.append('dataToken', dataToken ? dataToken : '');
      navigate(`/auth/signup?${parameters.toString()}`);
    }
  }, [
    currentMobilePhoneNumber,
    currentVerificationChannel,
    isSignUp,
    setAuthenticationMethodInContext,
  ]);

  useEffect(() => {
    switch (currentAuthenticationMethod) {
      case VerificationChannel.Sms:
      case VerificationChannel.WhatsApp:
        setDisplayPhoneInput(true);
        break;
      case VerificationChannel.None:
      case VerificationChannel.Email:
      default:
        setDisplayPhoneInput(false);
        setPhoneNumberInContext(null);
        break;
    }
  }, [
    currentAuthenticationMethod,
    setPhoneNumberInContext,
  ]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isPhoneRelatedMethod =
      currentAuthenticationMethod === VerificationChannel.Sms ||
      currentAuthenticationMethod === VerificationChannel.WhatsApp;

    if (isPhoneRelatedMethod && !phoneNumber) {
      notificationsService.error('Phone number is required');
      return;
    }

    if (currentAuthenticationMethod === currentVerificationChannel) {
      notificationsService.error('Please select a different authentication method.');
      return;
    }

    if (token) {
      sessionStorage.setItem(
        'prevPage',
        getSelectAuthLink(token),
      );

      sessionStorage.setItem(
        'withoutResend',
        'true',
      );
    }

    if (isSignUp) {
      dispatch(authApi.signUp({
        password,
        phone: isPhoneRelatedMethod ? phoneNumber : null,
        token,
        verificationChannel: currentAuthenticationMethod,
      }, navigate));
      analyticsService.sendSignupEvent();
      return;
    }

    dispatch(authApi.resetVerificationMethod({
      phone: isPhoneRelatedMethod ? phoneNumber : null,
      refreshToken,
      verificationChannel: currentAuthenticationMethod,
    }, navigate));
  };

  const onSkipEnhancedSecurity = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (!vChannels[3].enabled) {
      notificationsService.error('Please select a different authentication method.');
      return;
    }

    setAuthenticationMethodInContext(VerificationChannel.None);
    setShowSecurityModalInContext(true);
  };

  return (
    <div
      className={`${style.loginWrapper} flex flex-col gap-1`} style={{
        width: '-webkit-fill-available',
      }}
    >
      <div className='flex flex-col gap-0.5'>
        {
          !doNotShowBackButton && <LoginBackLink
            to={isSignUp ? getSignUpLink(token || '') : LINK_BACK_TO}
          />
        }
        <LoginHeading className={style.protectText}>
          Welcome to Investor Portal
        </LoginHeading>
        <LoginParagraph className={`${style.selectText} justify-center dt:justify-start`}>
          Select method of authentication:
        </LoginParagraph>
      </div>
      <form onSubmit={onSubmit}>
        <div className={style.inputsWrapper}>
          <SelectAuthMethodOptions
            channels={vChannels.slice(0, 3)}
          />
          {
            displayPhoneInput &&
            <div className={style.phoneContainer} >
              <LoginPhoneInput
                className={style.input}
                name='signup-phone-input'
                onChange={setPhoneNumberInContext}
                value={phoneNumber || ''}
              />
            </div>
          }
        </div>
        <LoginButton
          className={style.nextButton}
          disabled={
            vChannels.slice(0, 3).every((vChannel) => !vChannel.enabled)
          }
          isLoading={isLoading}
          name='select-verification-button'
          text='Next'
          type='submit'
        />
        <button
          className={`${style.skipButton} hover:bg-black-200 active:bg-black-300`}
          data-test='skip-button'
          name='Skip Enhanced Security'
          onClick={onSkipEnhancedSecurity}
          type='button'
        >
          <div className={style.text}>
            Skip Enhanced Security
          </div>
        </button>
      </form>
    </div>
  );
};

export { SelectAuthentication };
