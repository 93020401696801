import './Button.scss';
import { Tooltip } from '../Tooltip/Tooltip';
import { Svgicon } from 'components/Svgicon/Svgicon';

export type ButtonProps = {
  children?: JSX.Element | JSX.Element[] | string,
  className?: string,
  'data-test'?: string,
  dataTest?: string,
  disabled?: boolean,
  icon?: 'left' | 'no' | 'only' | 'right',
  iconClassName?: string,
  iconColor?: string,
  iconid?: string,
  isLoading?: boolean,
  loadingSpinnerId?: string,
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void,
  size?: 'bigger-medium' | 'large' | 'medium' | 'normal' | 'small',
  squared?: boolean,
  submit?: boolean,
  text?: string,
  tooltip?: JSX.Element | JSX.Element[] | string,
  tooltipWrapperClass?: string,
  type?:
  'black' | 'grey' | 'light-main' | 'main' | 'outline-grey' | 'outline-main' | 'red' | 'total-black' | 'transparent' | 'white-main' | 'white',
};

const defaultProps: ButtonProps = {
  size: 'medium',
  type: 'main',
};

export const Button = ({
  type = defaultProps.type,
  squared,
  size = defaultProps.size,
  icon,
  iconid,
  iconColor,
  iconClassName,
  text,
  children,
  tooltip,
  tooltipWrapperClass,
  submit,
  disabled,
  dataTest,
  className,
  isLoading,
  onClick,
  loadingSpinnerId,
  ...props
}: ButtonProps) => {
  let buttonCss = [
    type,
    size,
  ].join(' ');
  if (squared) {
    buttonCss += ' squared';
  }

  if (icon === 'only') {
    buttonCss += ` w-${size}`;
  }

  if (isLoading) {
    buttonCss += ' loading disabled';
  } else if (disabled) {
    buttonCss += ' disabled';
  }

  // eslint-disable-next-line eslint-rules/no-direct-jsx-assignment
  const spinner =
    <div className={`spinner-svg icon ${size} left`}>
      <Svgicon id={loadingSpinnerId || 'other-11f'} />
    </div>;

  const renderIcon = (position: 'left' | 'only' | 'right') => {
    if (isLoading) {
      return (
        <div
          className={`spinner-svg icon ${position}`}
        >
          <Svgicon id={loadingSpinnerId || 'other-11f'} />
        </div>
      );
    } else {
      return (
        <div
          className={[
            'icon',
            iconClassName || size,
            position,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          <Svgicon
            className={position === 'only' ? '' : `icon ${size}`}
            color={iconColor}
            id={iconid}
          />
        </div>
      );
    }
  };

  return (
    <div className={`tooltip-wrapper flex w-full justify-center ${tooltipWrapperClass}`}>
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
      <button
        className={[
          'servc-button',
          buttonCss,
          className,
        ]
          .filter(Boolean)
          // eslint-disable-next-line id-length
          .map((i) => (i as string).trim())
          .join(' ')}
        data-test={dataTest}
        disabled={disabled || isLoading}
        onClick={onClick}
        type={submit ? 'submit' : 'button'}
        {...props}
      >
        {(icon === 'no' || icon === undefined) && isLoading && spinner}
        {icon === 'left' && renderIcon('left')}
        {icon === 'only' && renderIcon('only')}
        {text}
        {children}
        {icon === 'right' && renderIcon('right')}
      </button>
    </div>
  );
};
