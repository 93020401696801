/* eslint-disable import/no-mutable-exports */
import { About } from './About';
import { CapitalAccounts } from './CapitalAccounts';
import { ChangePass } from './ChangePass';
import { Documents } from './Documents';
import { Privacy } from './Privacy';
import { Settings } from './Settings';
import { type SubRoute } from 'components';
import { getItemsFromStorage } from 'types';

const BASE_PATH = '/firm';
const { loginAs } = getItemsFromStorage([
  'loginAs',
], sessionStorage);

let ROUTE_ARRAY: SubRoute[] = [
  {
    element: Documents,
    subRouteString: 'documents',
  },
  {
    element: CapitalAccounts,
    parameter: ':fundId/:accountId',
    subRouteString: 'capital-accounts',
  },
  {
    element: CapitalAccounts,
    subRouteString: 'capital-accounts',
  },
  {
    element: Privacy,
    subRouteString: 'privacy',
  },
  {
    element: Settings,
    subRouteString: 'settings',
  },
  {
    element: About,
    subRouteString: 'about',
  },
  {
    element: ChangePass,
    subRouteString: 'change-password',
  },
];

if (loginAs) {
  ROUTE_ARRAY = ROUTE_ARRAY.filter((route) => route.subRouteString !== 'settings');
}

export {
  BASE_PATH,
  ROUTE_ARRAY,
};
