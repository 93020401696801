import { useLocation } from 'react-router-dom';

type RelativePath = {
  relativePath: string,
  valid: boolean,
};

const EMPTY_RELATIVE_PATH = {
  relativePath: '',
  valid: false,
};

const checkBasePathStringToPathName = (
  basePathString: string,
  pathname: string,
): RelativePath => {
  let index: number;

  const hasWildCard = basePathString.includes('*');

  // if chars do not match, return invalid path
  for (index = 0; index < basePathString.length; index++) {
    if (hasWildCard && index >= pathname.length) {
      return {
        relativePath: pathname.slice(Math.max(0, index)),
        valid: true,
      };
    }

    const charFromPathname = pathname.charAt(index);
    const charFromBasePathString = basePathString.charAt(index);
    if (charFromBasePathString === '*') {
      return {
        relativePath: pathname.slice(Math.max(0, index)),
        valid: true,
      };
    }

    if (charFromPathname !== charFromBasePathString) {
      return EMPTY_RELATIVE_PATH;
    }
  }

  if (pathname.length > basePathString.length) {
    return EMPTY_RELATIVE_PATH;
  }

  const slicedSubRoute = pathname.slice(Math.max(0, index));

  return {
    relativePath: slicedSubRoute,
    valid: true,
  };
};

/**
 * A hook to check if any of the basePathStrings given matches with the current pathname.
 * Supports wildcards, and accepts paths relative to base.
 *
 * @param basePathStrings the basePathStrings to check
 * @returns Object with relative path to basePathString and if it is valid
 */
const useRelativePathsToBasePath = (...basePathStrings: string[]) => {
  const { pathname } = useLocation();

  if (pathname === '/') {
    return EMPTY_RELATIVE_PATH;
  }

  const first = basePathStrings
    .map((basePathString) => {
      const check = checkBasePathStringToPathName(basePathString, pathname);
      return check;
    })
    .find((relativePath) => relativePath.valid);

  if (!first) {
    return EMPTY_RELATIVE_PATH;
  }

  return first;
};

export { useRelativePathsToBasePath };
