import { type TableColumnDisplayValues } from '../../../types';
import { CHART_COLORS } from '../../Charts/ChartVariables';
import { HorBarChart } from '../../Charts/HorBarChart/HorBarChart';
import style from '../barChart.module.scss';
import { type FC } from 'react';

export type CommitmentBarChartProps = {
  values: TableColumnDisplayValues,
};

export const CommitmentBarChart: FC<CommitmentBarChartProps> = ({
  values,
}) => {
  return <div className={style.container}>
    <HorBarChart
      colorList={CHART_COLORS.commitmentBarChart} lowerBar={[
        ...values.distribution === 0 ? [] : [
          { legendName: 'Distribution',
            value: values.distribution },
        ],
        {legendName: 'Partners capital (NAV)',
          value: values.nav},
      ]}
      upperBar={[
        {legendName: 'Contribution',
          value: values.contribution},
      ]}
    />
  </div>;
};
