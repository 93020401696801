import styles from './SelectAuthMethodOptions.module.scss';
import { type RadioChangeEvent } from 'antd';
import {
  Radio,
  Space } from 'antd';
import {
  SignUpActivationContext,
} from 'contexts';
import { type FC} from 'react';
import {
  useContext } from 'react';
import {
  type IVerificationChannel,
  type VerificationChannel} from 'types';

const SelectAuthMethodOptions: FC<{ channels: IVerificationChannel[], }> = ({
  channels,
}) => {
  const {
    currentAuthenticationMethod,
    setAuthenticationMethodInContext,
  } = useContext(SignUpActivationContext);

  const onChange = (event: RadioChangeEvent) => {
    const value = event.target.value as VerificationChannel;
    const selectedChannel = channels.find((channel) => channel.api === value);
    if (selectedChannel?.enabled) {
      setAuthenticationMethodInContext(value);
    }
  };

  return (
    <Radio.Group
      onChange={onChange}
      style={{ marginBottom: '1rem' }}
      value={currentAuthenticationMethod}
    >
      <Space direction='vertical'>
        {
          channels.map(
            (channel) => <Radio
              className={styles.radioOption}
              disabled={!channel.enabled}
              key={channel.api}
              value={channel.api}
            >
              {channel.display}
            </Radio>,
          )
        }
      </Space>
    </Radio.Group>
  );
};

export { SelectAuthMethodOptions };
