export const limitString = (str: string, charCountLimit: number): string => {
  if (charCountLimit < 0) {
    return str;
  }

  return str.length > charCountLimit
    ? `${str.slice(0, Math.max(0, charCountLimit))}...`
    : str;
};

export const pluralize = (str: string, count: number): string => {
  if (count === 1) {
    return str;
  }

  return `${str}s`;
};
