import { formatNumber } from '../../Pipes/numberPipes';
import style from './CapitalAccountsTotalsChart.module.scss';
import {
  type FC,
  useRef,
} from 'react';

export type TotalsChartItem = {
  legend: string,
  value: number,
};

type TotalsChartProps = {
  items: TotalsChartItem[],
  title: string,
  totalValue: number,
};

export const CapitalAccountsTotalsChart: FC<TotalsChartProps> = ({
  items,
  title,
  totalValue,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return <div
    className={
      style.totalsChartContainer
    }
  >
    <div className={style.container} ref={containerRef}>

      <div className={style.totalContainer}>
        <h2 className={style.title}>
          {title}
        </h2>
        <h3 className={style.totalValue}>
          {formatNumber(totalValue, 0, true)}
        </h3>
      </div>
      {items.map(({ legend,
        value }) =>
        value ?
          <div className={style.valueContainer} key={legend}>
            <h3 className={style.legend}>{legend}</h3>
            <h3 className={style.value}>{formatNumber(value, 0, true)}</h3>
          </div>
          : null,
      )}
    </div>
  </div>;
};
