import {Spinner} from '../../components';
import { FirmItem } from './FirmItem/FirmItem';
import style from './firms.module.scss';
import userApi from 'api/user';
import {Alert} from 'api/utils';
import classNames from 'classnames';
import { Input } from 'components/Input/Input';
import {Button} from 'components/InteractiveUIControls/Button/Button';
import {FiltersContext} from 'contexts';
import {useDocumentTitle} from 'hooks';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { type AppDispatch } from 'store';
import {
  type OrganizationsStoreState} from 'store/slices';
import {
  fetchCurrentOrganization,
  fetchOrganizations,
} from 'store/slices';
import {setActiveFilters,
  setUsualDocumentsFilters} from 'store/slices/filtersSlice';
import { type NestedOrganization } from 'types';

export const Firms = () => {
  const dispatch: AppDispatch = useDispatch();
  const [
    searchQuery,
    setSearchQuery,
  ] = useState('');
  const {organizations: organizationsArray,
    organizationsPending} = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;
  const navigate = useNavigate();
  const {setFirmId,
    setFundId} = useContext(FiltersContext);
  const {firmId} = useContext(FiltersContext);

  const goToFiles =
  useCallback((orgId: string, payload?: NestedOrganization) => {
    const selectedOrg: NestedOrganization | undefined = organizationsArray
      .find((org) => org.organizationId === orgId);

    const getPeriodOptions = () => {
      const yearsAndQuarters = payload?.funds
        .flatMap((fund) => fund.accounts)
        .flatMap((acc) => acc.yearsAndQuarters);

      const yearMap = new Map();

      if (yearsAndQuarters) {
        for (const item of yearsAndQuarters) {
          if (!yearMap.has(item.year)) {
            yearMap.set(item.year, new Set());
          }

          for (const quarter of item.quarters) {
            yearMap.get(item.year).add(quarter);
          }
        }
      }

      const options = Array.from(yearMap.entries()).map(([
        year,
        quarters,
      ]) => {
        return {
          children: Array.from(quarters).map((quarter) => {
            return `${year}/${quarter}`;
          }),
          id: `${year}`,
        };
      });

      return options;
    };

    if (!selectedOrg) {
      Alert('Something went wrong. Contact your administrator.');
      return;
    }

    const fundIds = selectedOrg.funds.map((fund) => fund.fundId);

    const accountIds = selectedOrg.funds.reduce((ids: string[], fund) => {
      for (const acc of fund.accounts) {
        ids.push(acc.accountId);
      }

      return ids;
    }, []);

    dispatch(setActiveFilters({
      accountIds,
      fundIds,
      organizationId: orgId,
    }));

    dispatch(
      setUsualDocumentsFilters({
        quarters: getPeriodOptions().flatMap((opt) => opt.children),
        years: getPeriodOptions().map((opt) => opt.id),
      }),
    );

    setFirmId(orgId);
    setFundId('');
    navigate('/firm/documents');
  }, [
    dispatch,
    navigate,
    organizationsArray,
    setFirmId,
    setFundId,
  ]);

  useEffect(() => {
    localStorage.removeItem('_loginAs');
  }, []);

  useEffect(() => {
    const fetchAndGoToFiles = async () => {
      if (organizationsArray?.length === 1) {
        const result =
        await dispatch(fetchCurrentOrganization(organizationsArray[0].organizationId));
        goToFiles(organizationsArray[0].organizationId,
          result.payload as NestedOrganization);
      }

      if (organizationsArray?.length && firmId) {
        goToFiles(firmId as string);
      }
    };

    fetchAndGoToFiles();
  }, [
    dispatch,
    firmId,
    goToFiles,
    organizationsArray,
    organizationsArray?.length,
  ]);

  useEffect(() => {
    if (!organizationsArray.length) {
      dispatch(fetchOrganizations());
      dispatch(userApi.getFirms());
    }
  }, []);

  useDocumentTitle('Organizations');

  const onSelectFirm = (id: string) => async () => {
    const result = await dispatch(fetchCurrentOrganization(id));
    goToFiles(id, result.payload as NestedOrganization);
  };

  const handleReset = () => {
    setSearchQuery('');
  };

  const filteredOrganizations = organizationsArray.filter((org) =>
    org.organizationName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (organizationsPending || !organizationsArray) {
    return <div className='flex min-h-[45rem] items-center'><Spinner /></div>;
  }

  if (organizationsArray?.length === 0) {
    return <div className={classNames(style.container, '!bg-white-999')}>
      <div className='flex size-full flex-col items-center justify-center gap-0.5'>
        <span className='text-font-3 text-black-700'>INVESTOR PORTAL</span>
        <span className='text-title-1 font-semibold'>Your investor account has been disabled.</span>
        <span className='text-font-1'>
          If you believe there is an error, please <a className='text-main-700 underline' href='mailto:helpdesk@servc.co.il?cc=fms@servc.co.il'> contact support.</a>
        </span>
        <div className='absolute right-4.5 top-2.5'>
          <Button icon='left' iconid='logout-1f' onClick={() => navigate('/auth/login')} text='Logout' type='grey' />
        </div>
      </div>
    </div>;
  }

  return <div className={style.container}>
    <div className={style.block}>
      <div className={style.textContainer}>
        <h1 className={style.investorPortalTitle}>INVESTOR PORTAL</h1>
        <h1 className={style.selectOrgTitle}>Select organization</h1>
      </div>
      <div className='flex h-auto flex-col gap-1 overflow-hidden rounded-lg bg-[#FFF] p-[1rem] shadow'>
        <Input
          className='min-h-12 px-0.5'
          iconClassName='size-1.5 opacity-50'
          iconid='search'
          onChange={(event) => setSearchQuery(event.target.value)}
          onReset={handleReset}
          placeholder='Search'
          value={searchQuery}
        />
        <div className={style.firmsContainer}>
          {
            filteredOrganizations.map((org) => <FirmItem
              id={org.organizationId}
              key={org.organizationId}
              logo={org.organizationLogo}
              name={org.organizationName}
              onSelect={onSelectFirm(org.organizationId)}
            />)
          }
        </div>
      </div>
    </div>
  </div>;
};
