import { AccountSelector } from '../AccountSelector';
import { FundSelector } from '../FundSelector';
import { TypeSelector } from '../TypeSelector';
import { YearSelector } from '../YearSelector';
import style from './DownloadFilesSelectFilters.module.scss';
import { Input } from 'antd';
import downloadFilesApi from 'api/download-files';
import { Alert } from 'api/utils';
import { AxiosError } from 'axios';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {
  GlobalElementBusContext,
  ModalActivationContext,
} from 'contexts';
import {
  type FC,
  useEffect,
  useRef} from 'react';
import {
  useContext,
  useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { type AppDispatch } from 'store';
import {type Filters,
  type FilterStoreState,
  type OrganizationsStoreState,
  resetLatestDocuments,
  resetSelectedDocumentsData} from 'store/slices';
import { fetchCategories } from 'store/slices';
import {type NestedOrganization } from 'types';

const getIdsFromSelectedFilters = (data: NestedOrganization | null) => {
  const firmId = data?.organizationId || '';
  const fundIds = data?.funds.map((fund) => fund.fundId) ?? [];
  const accounts = data?.funds.flatMap((fund) => fund.accounts) ?? [];
  const accountIds = accounts.map((acc) => acc.accountId);
  const types = [
    ...new Set(accounts.flatMap((acc) => acc.permissions
      .map((permission) => permission.code))),
  ];
  const years = [
    ...new Set(accounts?.flatMap((acc) => acc.yearsAndQuarters
      .map((year) => year.year).map(String))),
  ];

  return {
    accountIds,
    firmId,
    fundIds,
    types,
    years,
  };
};

const areAnyEmptyFilters = (data: NestedOrganization | null) => {
  const {
    accountIds,
    fundIds,
    types,
    years,
  } = getIdsFromSelectedFilters(data);

  return !accountIds.length || !fundIds.length || !types.length || !years.length;
};

/**
 * Renders the relevant selectors and handles validation of inputs
 */
const DownloadFilesSelectFilters: FC = () => {
  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  const containerRef = useRef(null);
  const {
    addRef,
  } = useContext(GlobalElementBusContext);

  const { toggleDownloadsModal,
    activeDocumentGroupName } = useContext(ModalActivationContext);

  const { selectedDocumentsData,
    displayedOrganizationWithPermissions } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const [
    disabled,
    setDisabled,
  ] = useState(true);
  const [
    isSent,
    setIsSent,
  ] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const userEmail = sessionStorage.getItem('email') || '';

  useEffect(() => {
    if (selectedDocumentsData) {
      setDisabled(areAnyEmptyFilters(selectedDocumentsData) || !userEmail);
    } else {
      setDisabled(!userEmail);
    }
  }, [
    selectedDocumentsData,
    userEmail,
  ]);

  const onSend = async () => {
    try {
      await downloadFilesApi.sendDownloadFilesRequest({
        email: userEmail,
        ...getIdsFromSelectedFilters(selectedDocumentsData
          || displayedOrganizationWithPermissions),
      });

      if (activeFilters) {
        dispatch(fetchCategories({
          ...activeFilters,
          firmId: activeFilters.organizationId,
        }));

        dispatch(resetLatestDocuments());
      }

      setIsSent(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        Alert(error.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      addRef(containerRef);
    }
  }, [
    addRef,
    containerRef,
  ]);

  const selectors = () => <>
    <span className='text-title-3 font-medium'>
      Export documents
    </span>
    <FundSelector style={{width: '100%'}} />
    <AccountSelector style={{width: '100%'}} />
    <TypeSelector activeDocumentGroupName={activeDocumentGroupName as string} style={{width: '100%'}} />
    <YearSelector style={{width: '100%'}} />
    <div className='flex w-full flex-col gap-0.125'>
      <div className='text-font-3 font-medium'>
        Email
      </div>
      <Input
        className={style.inputBox}
        data-test='download-files-select-filters-email-input'
        disabled
        placeholder='example@email.com'
        type='text'
        value={userEmail}
      />
    </div>
    <div className='w-full'>
      <Button
        className='w-full'
        data-test='export-documents'
        disabled={disabled}
        onClick={onSend}
        size='large'
        squared
      >
        {'Continue'}
      </Button>
    </div>
  </>;

  const onBackToDashboard = () => {
    toggleDownloadsModal(false, null);
    dispatch(resetSelectedDocumentsData());
    setIsSent(false);
  };

  const documentsSent = () => <div className='flex flex-col items-center gap-2 dt:px-4 dt:py-[10em]'>
    <Svgicon className='size-4 text-main-700' id='check-circle-2f' />
    <div className='flex flex-col items-center gap-1'>
      <div className='flex flex-col items-center gap-0.25'>
        <span className='flex flex-col items-center gap-0.25 text-title-3'>
          Documents sent to<span className='text-main-700'>{userEmail}</span>
        </span>
        <span className='text-font-2 text-black-700'>Check your email.</span>
      </div>
      <Button onClick={onBackToDashboard}>Back to dashboard</Button>
    </div>
  </div>;

  return (
    <div className={style.container} data-test='download-files-select-filters' ref={containerRef}>
      {isSent ? documentsSent() : selectors()}
    </div>
  );
};

export { DownloadFilesSelectFilters};
