export const firmActionTypes = {
  ACCOUNTS_LOADING: 'firm/ACCOUNTS_LOADING',
  FIRM_LOADING: 'firm/FIRM_LOADING',
  FUNDS_LOADING: 'firm/FUNDS_LOADING',
  GET_FIRM_ACCOUNTS_SUCCESS: 'firm/GET_FIRM_ACCOUNTS_SUCCESS',
  GET_FIRM_DETAILS: 'firm/GET_FIRM_DETAILS',
  GET_FIRM_FUNDS_SUCCESS: 'firm/GET_FIRM_FUNDS_SUCCESS',
  GET_FIRM_NOTIFICATION: 'firm/GET_FIRM_NOTIFICATION',
  GET_FIRM_VERIFY: 'firm/GET_FIRM_VERIFY',
  RESET: 'firm/RESET',
};

export const firmActions = {
  setVerify: (payload) => ({
    payload,
    type: firmActionTypes.GET_FIRM_VERIFY,
  }),
};
