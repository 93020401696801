import {
  CapitalAccountsNumbersAggregationChart,
  CommitmentBarChart,
  ContributionBarChart,
  Dropdown,
  NavTotals,
  NetGainLossTotals,
  OtherTotals,
} from '../../../../components';
import { type SelectOption } from '../../../../hooks';
import {
  type CapitalAccountsStoreState,
  getInitialTableColumnDisplayValues,
} from '../../../../store/slices';
import {
  selectPeriodForAccount,
} from '../../../../store/slices';
import {
  type Account,
  type CapitalAccountPeriodInformation,
  type CapitalAccountPeriodRecord,
  type PeriodComputedValuesRecord,
  type TableColumnDisplayValues,
} from '../../../../types';
import style from './CapitalAccountsDetails.module.scss';
import classNames from 'classnames';
import {
  type FC,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { isMobile } from 'services/mobile';

export type CapitalAccountsDetailsProps = {
  accountId: string,
  accounts: Account[],
  fundId: string,
  periods: CapitalAccountPeriodRecord,
};

export const CapitalAccountsDetails: FC<CapitalAccountsDetailsProps> = ({
  accountId,
  accounts,
  fundId,
  periods,
}) => {
  const dispatch = useDispatch();
  const mobile = isMobile();
  const initialPeriod = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .selectedFundAccountPeriodMap[fundId][accountId],
  ) as string;
  const computedPeriods = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .computedCapitalAccountsData
      ?.find((fund) => fund._id === fundId)
      ?.accounts
      ?.find((account) => account._id === accountId)
      ?.periods,
  ) as PeriodComputedValuesRecord | undefined;
  const periodOptions = Object.keys(periods).map((period) => ({
    display: period,
    id: period,
  }));

  const fundName = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .computedCapitalAccountsData
      ?.find((fund) => fund._id === fundId)
      ?.legalName,
  ) as string;

  const accountName = accounts
    ?.find((item: Account) => item._id === accountId)
    ?.legalName;

  const [
    selectedPeriod,
    setSelectedPeriod,
  ] = useState<SelectOption>(
    periodOptions.find((period) => period.id === initialPeriod) || periodOptions[0],
  );

  const [
    selectedInfo,
    setSelectedInfo,
  ] = useState<CapitalAccountPeriodInformation>(periods[selectedPeriod.id]);

  const [
    displayInfo,
    setDisplayInfo,
  ] = useState<TableColumnDisplayValues>(
    computedPeriods ?
      computedPeriods[selectedPeriod.id] :
      getInitialTableColumnDisplayValues(accountId),
  );

  const onSelectPeriod = (newValue: SelectOption) => {
    dispatch(selectPeriodForAccount({
      accountId,
      fundId,
      period: newValue.id,
    }));

    setSelectedPeriod(newValue);
    setSelectedInfo(periods[newValue.id]);
    if (computedPeriods) {
      setDisplayInfo(computedPeriods[newValue.id]);
    }
  };

  return <>
    <div>
      {mobile && <span className='font-light text-black-700'>{fundName}</span>}
      <div className={style.capitalAccountsTitleContainer}>
        <h3 className={style.capitalAccountsTitle}>
          {accountName} <span className={style.capitalAccountsTitleLight}>as of</span>
        </h3>
        <Dropdown
          className={style.dropdown}
          dataSelectionValue='ca-fund-selector-dropdown'
          displayFontWeight='500'
          displaySize='1rem'
          optionList={periodOptions}
          optionTextStyle='!text-font-2'
          placeholder='Period'
          setValue={onSelectPeriod}
          value={selectedPeriod}
          // eslint-disable-next-line react/jsx-boolean-value
          withAltDesign={true}
        />
      </div>
    </div>
    <CapitalAccountsNumbersAggregationChart values={displayInfo} />
    <div
      className={classNames(style.capitalAccountsBarChartsContainer, 'flex-col dt:flex-row')}
    >
      <ContributionBarChart values={displayInfo} />
      <CommitmentBarChart values={displayInfo} />
    </div>
    <div className={classNames(style.capitalAccountsTotalsChartsContainer, 'flex-col dt:flex-row')} >
      <NavTotals {...displayInfo} />
      <NetGainLossTotals {...displayInfo} />
      <OtherTotals {...selectedInfo} />
    </div>
  </>;
};
