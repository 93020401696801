import userApi from '../api/user';
import { Alert } from '../api/utils';
import { useRelativePathsToBasePath } from './relativePath';
import { isAxiosError } from 'axios';
import { type AxiosError } from 'axios';
import { useEffect } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { type NavigateFunction} from 'react-router-dom';
import {
  useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';

type ErrorResponse = {
  errorCode?: number,
  message: string,
};

type ErrorCallback = (error: AxiosError<ErrorResponse>) => void;

type SuccessCallback = () => void;

type AuthStoreSelectorType = {
  auth: {
    accessToken: string | null,
    justLoggedIn: boolean,
    justLoggedOut: boolean,
  },
};

type AuthStore = AuthStoreSelectorType['auth'];

const createDefaultErrorHandler = (
  navigate: NavigateFunction,
): ErrorCallback => {
  return (error: AxiosError<ErrorResponse>) => {
    navigate('/auth/login');
    Alert(error.response?.data?.message || 'An error occurred');
    Alert('Unable to authorize identity. Please try again.');
  };
};

const useCheckUserAuthenticationFromMe = (
  routes: string[],
  onError?: ErrorCallback,
  onSuccess?: SuccessCallback,
) => {
  const { valid } = useRelativePathsToBasePath(...routes);
  const { accessToken,
    justLoggedIn,
    justLoggedOut } = useSelector<
  AuthStoreSelectorType>(
    (state) => state.auth,
  ) as AuthStore;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const errorHandler = onError || createDefaultErrorHandler(navigate);

  useEffect(() => {
    if (accessToken) {
      onSuccess?.();
      return;
    }

    if (justLoggedIn || justLoggedOut) {
      return;
    }

    if (valid) {
      const checkAuthentication = async () => {
        try {
          await dispatch(userApi.me());
          onSuccess?.();
        } catch (error) {
          if (isAxiosError(error)) {
            errorHandler(error);
          }
        }
      };

      checkAuthentication();
    }
  }, [
    accessToken,
    dispatch,
    errorHandler,
    justLoggedIn,
    justLoggedOut,
    onSuccess,
    valid,
  ]);
};

export { useCheckUserAuthenticationFromMe };
