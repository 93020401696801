import { Button } from 'components/InteractiveUIControls/Button/Button';
import { limitString } from 'components/Pipes/textPipes';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { type CapitalAccountAccount } from 'types';

export type CapitalAccountsInvestorRowProps = Pick<
CapitalAccountAccount, '_id' | 'legalName'> & {
  fundId: string,
};

export const CapitalAccountsInvestorRow: FC<CapitalAccountsInvestorRowProps> = ({
  _id,
  fundId,
  legalName,
}) => {
  const navigate = useNavigate();

  const onSelectAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(`/firm/capital-accounts/${fundId}/${_id}`);
  };

  return <div
    className='group flex size-full flex-row items-center justify-between px-0.5 py-0.25'
  >
    <div
      className='flex size-full items-center justify-between gap-x-1'
      data-test={`account-${legalName}`}
    >
      <p className='m-0 text-title-1 font-normal text-custom-blue'>
        {limitString(legalName, 15)}
      </p>
      <Button
        className=' h-full max-h-[1.25rem] !gap-[0.125rem] place-self-end !rounded-medium !px-[0.5rem] !text-title-1 opacity-0 group-hover:opacity-100'
        icon='right'
        iconClassName='scale-[0.7]'
        iconColor='#005BB3'
        iconid='scroll-to-the-right'
        onClick={(event) => onSelectAccount(event as React.MouseEvent<HTMLButtonElement>)}
        size='large'
        text='Details'
        tooltipWrapperClass='!w-auto'
        type='light-main'
      />
    </div>
  </div>;
};
