import {
  createContext,
  type FC,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState} from 'react';

type GlobalElementBusContextType = {
  addRef: (newRef: RefObject<HTMLElement | SVGSVGElement>) => void,
  findClosestAncestor: (
    targetRef: RefObject<HTMLElement | SVGSVGElement>
  ) => RefObject<HTMLElement | SVGSVGElement> | null,
  findKClosestAncestors: (
    targetRef: RefObject<HTMLElement | SVGSVGElement>,
    k: number
  ) => Array<RefObject<HTMLElement | SVGSVGElement>>,
  version: number,
};

type GlobalElementBusContextProviderProps = {
  children?: React.ReactNode,
};

const INITIAL_STATE: GlobalElementBusContextType = {
  addRef: () => {},
  findClosestAncestor: () => null,
  findKClosestAncestors: () => [],
  version: 0,
};

export const GlobalElementBusContext = createContext<GlobalElementBusContextType>(
  INITIAL_STATE,
);

export const GlobalElementBusContextProvider: FC<
GlobalElementBusContextProviderProps> = ({children}) => {
  const [
    refObjects,
    setRefObjects,
  ] = useState<Array<RefObject<HTMLElement | SVGSVGElement>>>([]);

  const [
    version,
    setVersion,
  ] = useState(0);

  useEffect(() => {
    setVersion((prev) => prev + 1);
  }, [
    refObjects,
  ]);

  const addRef = useCallback(
    (
      newRef: RefObject<HTMLElement | SVGSVGElement>,
    ) => {
      const found = refObjects.find((elem) => elem.current === newRef.current);

      if (!found) {
        setRefObjects([
          ...refObjects,
          newRef,
        ]);
      }
    },
    [
      refObjects,
    ],
  );

  const findClosestAncestor = useCallback((
    targetRef: RefObject<HTMLElement | SVGSVGElement>,
  ) => {
    if (!targetRef.current) {
      return null;
    }

    let closestAncestorRef: RefObject<HTMLElement | SVGSVGElement> | null = null;

    for (const refObject of refObjects) {
      if (!refObject.current?.contains(targetRef.current)) {
        continue;
      }

      if (!closestAncestorRef) {
        closestAncestorRef = refObject;
      } else if (closestAncestorRef.current?.contains(refObject.current)) {
        closestAncestorRef = refObject;
      }
    }

    return closestAncestorRef;
  }, [
    refObjects,
  ]);

  const findKClosestAncestors = useCallback((
    targetRef: RefObject<HTMLElement | SVGSVGElement>,
    // eslint-disable-next-line id-length
    k: number,
  ) => {
    if (!targetRef.current) {
      return [];
    }

    const ancestorRefs: Array<RefObject<HTMLElement | SVGSVGElement>> = [];

    for (const refObject of refObjects) {
      if (refObject.current?.contains(targetRef.current)) {
        ancestorRefs.push(refObject);
      }
    }

    // Sort ancestors by their depth level relative to the target
    ancestorRefs.sort((a, b) => {
      let depthA = 0;
      let depthB = 0;
      let node;

      node = a.current;
      while (node && !node.contains(targetRef.current)) {
        depthA++;
        node = node.parentElement;
      }

      node = b.current;
      while (node && !node.contains(targetRef.current)) {
        depthB++;
        node = node.parentElement;
      }

      return depthA - depthB;
    });

    return ancestorRefs.slice(0, k);
  }, [
    refObjects,
  ]);

  const values = useMemo(() => ({
    addRef,
    findClosestAncestor,
    findKClosestAncestors,
    version,
  }), [
    addRef,
    findClosestAncestor,
    findKClosestAncestors,
    version,
  ]);

  return <GlobalElementBusContext.Provider value={values}>
    {children}
  </GlobalElementBusContext.Provider>;
};

