import style from './LoginHeading.module.scss';

type Props = {
  children?: React.ReactNode,
  className?: string,
  htmlContent?: string,
};

export const LoginHeading = ({ children,
  className = '',
  htmlContent }: Props) => {
  return (
    <h1 className={[
      style.loginHeading,
      className,
    ].join(' ')}
    >
      {htmlContent ?
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> :
        children}
    </h1>
  );
};
