import { notification } from 'antd';

export const messages = {
  resetPasswordSuccess: 'You will get an email with instructions to reset your password if account exists.',
  standartError: 'Something went wrong. Please try again later.',
  wrongEmail: 'Please enter correct email.',
};

export const success = (message: string) => {
  notification.success({ message });
};

export const error = (message: string) => {
  notification.error({ message });
};
