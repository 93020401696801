import { useResize } from '../../../hooks';
import { MAX_ALLOWABLE_MODULE_WIDTH_FOR_CA_IMG } from '../../../resources/constants';
import { type CapitalAccountsStoreState } from '../../../store/slices';
import backgroundImage from './capital-accounts-bg.png';
import style from './CapitalAccountsBanner.module.scss';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import {
  type CSSProperties,
  useMemo,
} from 'react';
import {
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const CapitalAccountsBanner = () => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useResize(containerRef);
  const {
    hasCapitalAccounts,
    hasCapitalAccountsPending,
  } = useSelector<{ capitalAccounts: CapitalAccountsStoreState, }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const containerStyle = useMemo(() => {
    const newContainerStyle: CSSProperties = {};

    if (width < MAX_ALLOWABLE_MODULE_WIDTH_FOR_CA_IMG) {
      newContainerStyle.backgroundImage = `url(${backgroundImage})`;
    }

    if (hasCapitalAccountsPending || !hasCapitalAccounts) {
      newContainerStyle.display = 'none';
    }

    return newContainerStyle;
  }, [
    hasCapitalAccounts,
    hasCapitalAccountsPending,
    width,
  ]);

  const onViewButtonClick = () => navigate('/firm/capital-accounts');

  return (
    <div
      className={style.container}
      ref={containerRef}
      style={containerStyle}
    >
      <div className={style.leftBlock}>
        <h3 className={style.title}>
          Capital accounts
        </h3>
        <Button
          className='!h-8 w-[5.5rem]'
          data-test='view-capital-accounts-button'
          onClick={onViewButtonClick}
          size='medium'
          tooltipWrapperClass='!justify-start'
          type='total-black'
        >
          View
        </Button>
      </div>
    </div>
  );
};
