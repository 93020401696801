import middlewares from './middlewares';
import {
  auth,
  files,
  firm,
  firms,
  global,
  support,
} from './reducers';
import {
  capitalAccountsReducer,
  documentsReducer,
  filtersReducer,
  organizationsReducer,
} from './slices';
import { type Action,
  type ReducersMapObject} from '@reduxjs/toolkit';
import {
  configureStore,
} from '@reduxjs/toolkit';

const reducer: ReducersMapObject<unknown, Action<string>> = {
  auth,
  capitalAccounts: capitalAccountsReducer,
  documents: documentsReducer,
  files,
  filters: filtersReducer,
  firm,
  firms,
  global,
  organizations: organizationsReducer,
  support,
};

const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  reducer,
});

export default store;
export type AppDispatch = typeof store.dispatch;
