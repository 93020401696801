export const filesActionTypes = {
  CLEAR_FILES: 'files/CLEAR_FILES',
  CLEAR_FIRM_FILE_BY_ID: 'files/CLEAR_FIRM_FILE_BY_ID',
  DOWNLOAD_FILES_LOADING: 'files/DOWNLOAD_FILES_LOADING',
  FILE_BY_FILETYPE_LOADING: 'files/FILE_BY_FILETYPE_LOADING',
  FILE_BY_ID_LOADING: 'files/FILE_BY_ID_LOADING',
  FILE_LOADING: 'files/FILE_LOADING',
  GET_FIRM_FILE_BY_ID_SUCCESS: 'files/GET_FIRM_FILE_BY_ID_SUCCESS',
  GET_FIRM_FILES_BY_FILETYPE_SUCCESS: 'files/GET_FIRM_FILES_BY_FILETYPE_SUCCESS',
  GET_FIRM_FILES_SUCCESS: 'files/GET_FIRM_FILES_SUCCESS',
  RESET: 'files/RESET',
  VIEWING_FILE_BY_ID_LOADING: 'files/VIEWING_FILE_BY_ID_LOADING',
};
