/* eslint-disable react/prop-types */
import style from './codeinput.module.scss';

export const CodeInput = ({handleOnChange,
  handleOnClick,
  length = 6,
  id}) => {
  return <div className={style.container}>
    <input
      autoComplete='off'
      className={style.code}
      id={id}
      maxLength={length}
      minLength={length}
      name='code'
      onKeyPress={(event) => {
        const ch = String.fromCodePoint(event.which);
        if (!/\d/u.test(ch)) {
          event.preventDefault();
        }
      }}
      onKeyUp={(event) => {
        if (handleOnChange) {
          handleOnChange(event);
        }

        if ((event.code === 'Enter' || event.code === 'NumpadEnter') && handleOnClick) {
          handleOnClick(event);
        }
      }}
      onPaste={(event) => {
        if (handleOnChange) {
          setTimeout(() => {
            handleOnChange(event);
          }, 0);
        }
      }}
      placeholder='Code...'
      type='text'
    />
  </div>;
};
