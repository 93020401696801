/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable unicorn/prefer-query-selector */
// eslint-disable-next-line import/no-unassigned-import
import './antd.scss';
import userApi from '../../api/user';
import {firmActions} from '../../store/actions';
import VARIABLES from '../../styles/variables';
import {CodeInput} from '../CodeInput';
import style from './verifyInfo.module.scss';
import {Button} from 'antd';
import {useDispatch,
  useSelector} from 'react-redux';

export const VerifyInfo = ({data,
  requestKey,
  setData}) => {
  const dispatch = useDispatch();
  const {auth} = useSelector((state) => state);
  let body = {};
  const inputs = document.getElementsByClassName('input');

  const handleOnChange = (event) => {
    for (const input of inputs) {
      input.classList.remove(style.error);
    }

    if (event.target.value.length) {
      event
        .target
        .parentElement
        .style.border = `2px solid ${VARIABLES.borderColorActive}`;
      event.target.parentElement.style.borderRadius = '3px';
      if (event.target.value) {
        body.verificationCode = event.target.value;
      }
    } else {
      event
        .target
        .parentElement
        .style.border = `2px solid ${VARIABLES.borderColor}`;
    }
  };

  const handelOnCancel = () => {
    setData((previous) => ({
      ...previous,
      [requestKey]: requestKey === 'phone' ? '+' + auth[requestKey] : auth[requestKey],
    }));
    dispatch(firmActions.setVerify(false));
  };

  // eslint-disable-next-line consistent-return
  const handleOnClick = () => {
    const error = document.getElementsByClassName(style.error)[0];
    const input = document.querySelector('#verify');

    // eslint-disable-next-line no-prototype-builtins
    if (!body.hasOwnProperty('verificationCode') || body.verificationCode?.length < 5) {
      error.innerText = 'Invalid code!';
    } else {
      error.innerText = '';
      input.value = '';
      if (requestKey === 'email') {
        return dispatch(userApi.confirmEmail(body, data.email));
      } else if (requestKey === 'phone') {
        return dispatch(userApi.confirmPhone(body, data.phone));
      }

      for (const otherInput of inputs) {
        otherInput.value = '';
      }

      body = {};
    }
  };

  return <div className={[
    style.container,
    'submit-info',
  ].join(' ')}
  >
    <div className={style.block}>
      <div className={style.header}>
        For security reasons, a verification code has been sent to {data && data[requestKey]} {requestKey}.
      </div>
      <div className={style.error} />
      <CodeInput
        handleOnChange={handleOnChange}
        handleOnClick={handleOnClick}
        id='verify'
        length={5}
      />
      <Button
        // eslint-disable-next-line react/forbid-component-props
        className={style['submit-btn']}
        onClick={handleOnClick}
        type='primary'
      >
        Submit
      </Button>
      <Button
        // eslint-disable-next-line react/forbid-component-props
        className={style['cancel-btn']}
        onClick={handelOnCancel}
        type='primary'
      >
        Close
      </Button>
    </div>
  </div>;
};
