/* eslint-disable max-len */
import authApi from '../../api/auth';
import { useToken } from '../../hooks';
import { analyticsService } from '../../services';
import { VerificationChannel } from '../../types';
import { LoginButton } from '../LoginButton/LoginButton';
import style from './SkipEnhancedSecurityModal.module.scss';
import { SignUpActivationContext } from 'contexts';
import { useContext } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';

const WARNING_DESCRIPTION =
  'By skipping Two-Factor Authentication (2FA), your account is more vulnerable to unauthorized access. Enhance your account security by enabling 2FA.';

const CONFIRM_DESCRIPTION =
  'Press confirm to disable 2FA and enter the confirmation code sent to your email or current channel.';

const getSelectAuthLink = (
  token: string,
) => `/auth/select-auth-method?token=${token}`;

const SkipEnhancedSecurityModal = () => {
  const {
    isSignUp,
    password,
    setAuthenticationMethodInContext,
    setShowSecurityModalInContext,
  } = useContext(SignUpActivationContext);

  const parameterToken = useToken();

  const isLoading = useSelector<
  {global: { loading: boolean, }, }>(
    (state) => state.global.loading,
  ) as boolean;

  const userToken = sessionStorage.getItem('access_token');

  // require refresh token to refresh session
  const refreshToken = sessionStorage.getItem('refresh_token');

  const token = parameterToken || userToken;

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const onBackButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setAuthenticationMethodInContext(VerificationChannel.Email);
    setShowSecurityModalInContext(false);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (token) {
      sessionStorage.setItem(
        'prevPage',
        getSelectAuthLink(token),
      );

      sessionStorage.setItem(
        'withoutResend',
        'true',
      );
    }

    if (isSignUp) {
      dispatch(authApi.signUp({
        password,
        token,
        verificationChannel: VerificationChannel.None,
      }, navigate));
      analyticsService.sendSignupEvent();
      setShowSecurityModalInContext(false);
      return;
    }

    dispatch(authApi.resetVerificationMethod({
      refreshToken,
      verificationChannel: VerificationChannel.None,
    }, navigate));
    setShowSecurityModalInContext(false);
  };

  return (
    <div
      className={style.container}
      data-test='skip-enhanced-security-modal-container'
    >
      <div className={[
        style.block,
        style.onOpen,
      ].join(' ')}
      >
        <div className='text-title-2 text-black-999' >
          Skip enhanced security
        </div>
        <div className='text-font-1 text-black-700' >
          { WARNING_DESCRIPTION }
        </div>
        <div className='text-font-1 text-black-700' >
          { CONFIRM_DESCRIPTION }
        </div>
        <form className={style.form} onSubmit={onSubmit}>
          <div className={style.buttons}>
            <button
              className={`${style.closeButton} hover:bg-black-200 active:bg-black-300`}
              name='Go back'
              onClick={onBackButton}
              type='button'
            >
              <div className={style.text}>
                Go back
              </div>
            </button>
            <LoginButton
              className={style.skipButton}
              isLoading={isLoading}
              name='skip-button'
              text='Confirm'
              type='submit'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export { SkipEnhancedSecurityModal };
