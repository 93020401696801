import { type CapitalAccountsStoreState } from '../../../store/slices';
import { type TableColumnDisplayValues } from '../../../types';
import { type ChartData } from '../../Charts/ChartTypes';
import { PieChartSimple } from '../../Charts/PieChartSimple/PieChartSimple';
import style from './CapitalAccountsSummaryPieChart.module.scss';
import { type FC,
  useEffect,
  useState } from 'react';
import { useSelector } from 'react-redux';

export type CapitalAccountsSummaryPieChartProps = {
  chartType: keyof TableColumnDisplayValues,
  title: string,
};

export const CapitalAccountsSummaryPieChart:
FC<CapitalAccountsSummaryPieChartProps> = ({
  chartType,
  title,
}) => {
  const [
    values,
    setValues,
  ] = useState<ChartData[]>([]);
  const {
    summaryStatisticsByFund,
  } = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;

  useEffect(() => {
    const transformedValues = Object.values(summaryStatisticsByFund)
      .map((colValues) => ({
        legendName: colValues.legalName,
        value: colValues[chartType] as number,
      }));

    setValues(transformedValues);
  }, [
    chartType,
    summaryStatisticsByFund,
  ]);

  return <div className={style.container}>
    <PieChartSimple
      data={values}
      legendProps={{ currency: '',
        decimals: 2,
        isInPercents: false }}
      maxLegendSize={6}
      title={title}
    />
  </div>;
};
