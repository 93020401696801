import {
  type FC,
  type ReactNode,
  useCallback,
  useMemo} from 'react';
import { createContext,
  useState } from 'react';
import { VerificationChannel } from 'types';

type SignUpContextType = {
  currentAuthenticationMethod: VerificationChannel,
  isSignUp: boolean,
  password: string | null,
  phoneNumber: string | null,
  setAuthenticationMethodInContext: (input: VerificationChannel) => void,
  setIsSignUpInContext: (input: boolean) => void,
  setPasswordInContext: (input: string) => void,
  setPhoneNumberInContext: (input: string | null) => void,
  setShowSecurityModalInContext: (input: boolean) => void,
  setTokenInContext: (input: string) => void,
  showSecurityModal: boolean,
  token: string | null,
};

type SignUpProviderProps = {
  children?: ReactNode,
};

const SIGNUP_INITIAL_STATE: SignUpContextType = {
  currentAuthenticationMethod: VerificationChannel.Email,
  isSignUp: false,
  password: null,
  phoneNumber: null,
  setAuthenticationMethodInContext: () => {},
  setIsSignUpInContext: () => {},
  setPasswordInContext: () => {},
  setPhoneNumberInContext: () => {},
  setShowSecurityModalInContext: () => {},
  setTokenInContext: () => {},
  showSecurityModal: false,
  token: null,
};

export const SignUpActivationContext = createContext<SignUpContextType>(
  SIGNUP_INITIAL_STATE,
);

export const SignUpActivationProvider: FC<
SignUpProviderProps> = ({children}) => {
  const [
    password,
    setPassword,
  ] = useState<string | null>(null);

  const [
    phoneNumber,
    setPhoneNumber,
  ] = useState<string | null>(null);

  const [
    currentAuthenticationMethod,
    setCurrentAuthenticationMethod,
  ] = useState<VerificationChannel>(
    VerificationChannel.WhatsApp,
  );

  const [
    token,
    setToken,
  ] = useState<string | null>(null);

  const [
    isSignUp,
    setIsSignUp,
  ] = useState(false);

  const [
    showSecurityModal,
    setShowSecurityModal,
  ] = useState(false);

  const setPasswordInContext = useCallback(
    (input: string) => setPassword(input), [
      setPassword,
    ],
  );

  const setPhoneNumberInContext = useCallback(
    (input: string | null) => setPhoneNumber(input), [
      setPhoneNumber,
    ],
  );

  const setAuthenticationMethodInContext = useCallback(
    (input: VerificationChannel) => setCurrentAuthenticationMethod(
      input,
    ), [
      setCurrentAuthenticationMethod,
    ],
  );

  const setTokenInContext = useCallback(
    (input: string) => setToken(input), [
      setToken,
    ],
  );

  const setIsSignUpInContext = useCallback(
    (input: boolean) => setIsSignUp(input), [
      setIsSignUp,
    ],
  );

  const setShowSecurityModalInContext = useCallback(
    (input: boolean) => setShowSecurityModal(input), [
      setShowSecurityModal,
    ],
  );

  const values = useMemo(() => ({
    currentAuthenticationMethod,
    isSignUp,
    password,
    phoneNumber,
    setAuthenticationMethodInContext,
    setIsSignUpInContext,
    setPasswordInContext,
    setPhoneNumberInContext,
    setShowSecurityModalInContext,
    setTokenInContext,
    showSecurityModal,
    token,
  }), [
    currentAuthenticationMethod,
    isSignUp,
    password,
    phoneNumber,
    setAuthenticationMethodInContext,
    setIsSignUpInContext,
    setPasswordInContext,
    setPhoneNumberInContext,
    setShowSecurityModalInContext,
    setTokenInContext,
    showSecurityModal,
    token,
  ]);

  return <SignUpActivationContext.Provider value={values}>
    {children}
  </SignUpActivationContext.Provider>;
};
