/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowRightIcon,
  channelIcons } from '../../pages/Authorization/ResendVerification/icons';
import style from './LoginVerificationMethod.module.scss';
import { type FC } from 'react';
import { VerificationChannel } from 'types';

type Props = {
  activeType: VerificationChannel,
  isLoading: boolean,
  onClick: (type: VerificationChannel) => void,
  type: VerificationChannel,
};

const getTextType = (type: VerificationChannel) => {
  const { Email,
    Sms,
    WhatsApp } = VerificationChannel;
  if (type === Email) {
    return 'Email';
  }

  if (type === Sms) {
    return 'SMS';
  }

  if (type === WhatsApp) {
    return 'WhatsApp';
  }

  return '';
};

const Spinner = () => <div className={style.spinner}>
  <div />
  <div />
  <div />
  <div />
</div>;

export const LoginVerificationMethod: FC<Props> = ({ type,
  activeType,
  onClick,
  isLoading }) => {
  const IconComponent = channelIcons[type];
  const textType = getTextType(type);
  const isActive = type === activeType;
  const text = isActive ?
    `Resend code by ${textType}` : textType;

  if (isLoading) {
    return <div className={style.loginVerificationMethod}>
      <div className={style.spinnerWrapper}>
        <Spinner />
      </div>
    </div>;
  }

  return (
    <div
      className={style.loginVerificationMethod}
      onClick={() => onClick(type)}
    >
      <div className={style.channelIconWrapper}>{
        IconComponent && <IconComponent />
      }</div>
      <div className={style.textWrapper}>{text}</div>
      <div className={style.arrowIconWrapper}>
        <ArrowRightIcon />
      </div>
    </div>
  );
};
