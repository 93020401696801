import { type CapitalAccountPeriodInformation } from '../../../types';
import { CapitalAccountsTotalsChart,
  type TotalsChartItem } from '../CapitalAccountsTotalsChart';
import { type FC } from 'react';

export const OtherTotals: FC<CapitalAccountPeriodInformation> = ({
  capitalAccountReport,
  data,
}) => {
  const { customColumns } = capitalAccountReport;

  if (customColumns.length === 0) {
    return null;
  }

  const items: TotalsChartItem[] = customColumns
    .map(({ key,
      label }) => ({
      legend: label,
      value: data[key],
    }));

  const totalValue = items.reduce((prev, { value }) => {
    return prev + (value || 0);
  }, 0);

  return <CapitalAccountsTotalsChart
    items={items}
    title={'Other'}
    totalValue={totalValue}
  />;
};
