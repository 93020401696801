/* eslint-disable react/forbid-component-props */
import ChevronSvg from '../../assets/icons/global/blue-chevron.svg?react';
import BooleanButton from '../BooleanButton';
import style from './GoBackButton.module.scss';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import VARIABLES from 'styles/variables';

type GoBackButtonProps = {
  to: string,
};

const GoBackButton: FC<GoBackButtonProps> = ({ to }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(to);
  };

  return (
    <BooleanButton
      onClick={onClick}
      style={{
        background: VARIABLES.booleanButtonInactiveColor,
        borderRadius: '150px',
        borderWidth: '0px',
        cursor: 'pointer',
        height: '2rem',
        width: '78px',
      }}
      testName={`go-back-to-${to}-page`}
    >
      <div
        className={style.label}
        style={{ color: VARIABLES.booleanButtonContentInactiveColor }}
      >
        <ChevronSvg />
        Back
      </div>
    </BooleanButton>
  );
};

export default GoBackButton;
