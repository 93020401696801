export const authActionTypes = {
  CHANGE_EMAIL: 'auth/CHANGE_EMAIL',
  CHANGE_PHONE_NUMBER: 'auth/CHANGE_PHONE_NUMBER',
  PASSWORD_SUCCESSFULLY_CHANGED: 'auth/PASSWORD_SUCCESSFULLY_CHANGED',
  RESET: 'auth/RESET',
  SET_ACCESS_TOKEN: 'auth/SET_ACCESS_TOKEN',
  SET_JUST_LOGGED_IN: 'auth/SET_JUST_LOGGED_IN',
  SET_JUST_LOGGED_OUT: 'auth/SET_JUST_LOGGED_OUT',
  SET_LOGIN_SUCCESS: 'auth/SET_LOGIN_SUCCESS',
  SET_REFRESH_TOKEN: 'auth/SET_REFRESH_TOKEN',
  SET_RESET_PASSWORD_SUCCESS: 'auth/SET_MIGRATION_RESET_PASSWORD_SUCCESS',
};

