/* eslint-disable canonical/filename-match-exported */
import Api from '.';

export type FileTypeLabel = {
  code: string,
  name: string,
};

export type YearAndFileType = {
  types: FileTypeLabel[],
  years: number[],
};

export type AccountIdYearAndTypeRecord = {
  [accountId: string]: YearAndFileType,
};

type DownloadFilesBody = {
  accountIds: string[],
  email: string,
  firmId: string,
  fundIds: string[],
  types: string[],
  years: string[],
};

class DownloadFilesApi extends Api {
  public constructor () {
    super('/api/v2/files/download-all');
  }

  public async getYearAndTypeFilters (firmId: string, accountIds: string[]) {
    const endpoint = `/filters?firmId=${firmId}&`;

    // eslint-disable-next-line unicorn/no-array-reduce
    const newUrl = accountIds.reduce((previous, current) => {
      return previous.concat(`accountIds=${current}&`);
    }, endpoint);

    const { data } = await this.get(newUrl);

    return data as AccountIdYearAndTypeRecord;
  }

  public async sendDownloadFilesRequest (body: DownloadFilesBody) {
    await this.post('', body);
  }
}

const downloadFilesApi = new DownloadFilesApi();
export default downloadFilesApi;
