import { InputIcon,
  LoginInput } from '../LoginInput/LoginInput';
import { useState } from 'react';

type Props = {
  // for e2e tests, so element can be find easily
  name?: string,
  onChange?: (string_: string) => void,
  placeholder?: string,
  value?: string,
};

export const LoginPasswordInput = ({ onChange,
  value = '',
  placeholder = '',
  name = '' }: Props) => {
  const [
    isShow,
    setIsShow,
  ] = useState(false);
  const type = isShow ? 'text' : 'password';
  const onIconClick = () => {
    setIsShow(!isShow);
  };

  return (
    <LoginInput
      icon={isShow ? InputIcon.OpenedEye : InputIcon.Eye}
      name={name}
      onChange={onChange}
      onIconClick={onIconClick}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  );
};
