import { type SelectDisplayOption,
  type SelectOption } from './types';
import { useCallback,
  useState } from 'react';

type SelectorProps = {
  optionList: SelectOption[],
};

const getInitialOptions = (optionList: SelectOption[]) => {
  const options = optionList.map((option) => ({
    id: option.id,
    name: option.display,
    selected: false,
  }));

  return options;
};

const getNewOptions = (options: SelectDisplayOption[], optionId: string) => {
  if (!optionId) {
    return {
      optionId: '',
      options: options.map((option) => ({
        ...option,
        selected: false,
      })),
    };
  }

  const index = options.findIndex((option) => option.id === optionId);

  if (index === -1) {
    return {
      optionId: '',
      options: options.map((option) => ({
        ...option,
        selected: false,
      })),
    };
  }

  const newOptions = options.map((option, ind) => ({
    ...option,
    selected: index === ind,
  }));

  return {
    optionId: newOptions[index].id,
    options: newOptions,
  };
};

/**
 * Hook that tracks the selected option for given
 * list of options to choose from. It can only choose 0 or 1
 * option at a time.
 */
const useSingleSelector = ({ optionList }: SelectorProps) => {
  const initialOptions = getInitialOptions(optionList);
  const [
    options,
    setOptions,
  ] = useState<SelectDisplayOption[]>(initialOptions);
  const [
    optionId,
    setOptionId,
  ] = useState('');

  const setNewOptions = useCallback((newOptionList: SelectOption[]) => {
    setOptions(getInitialOptions(newOptionList));
    setOptionId('');
  }, []);

  const markOption = (newOptionId: string) => {
    const newOptions = getNewOptions(options, newOptionId);
    setOptions(newOptions.options);
    setOptionId(newOptions.optionId);
  };

  return {
    markOption,
    optionId,
    options,
    setNewOptions,
  };
};

export { useSingleSelector };
