import { MultiSelector } from '../../MultiSelector';
import { type SelectorProps } from '../YearSelector';
import { type FC,
  useEffect,
  useMemo,
  useState} from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { filterDocumentsDataByAccounts,
  type OrganizationsStoreState } from 'store/slices';
import { type NestedAccount,
  type NestedOrganization } from 'types';

/**
 * Renders a multiselector component for accounts
 */
const getAllAccounts = (data: NestedOrganization | null) => {
  return data?.funds.flatMap((fund) => fund.accounts) ?? [];
};

const getOptions = (accounts: NestedAccount[]) => {
  const accountOptions = accounts.map((acc) => {
    return {display: acc.accountName,
      id: acc.accountId,
    };
  });

  return accountOptions;
};

const AccountSelector: FC<SelectorProps> = ({style}: SelectorProps) => {
  const dispatch = useDispatch();

  const {
    displayedOrganizationWithPermissions,
    selectedDocumentsData } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const allAccounts = useMemo(() => {
    return getAllAccounts(displayedOrganizationWithPermissions as NestedOrganization)
     || [];
  }, [
    displayedOrganizationWithPermissions,
  ]);

  const [
    selectedAccIds,
    setSelectedAccIds,
  ] = useState(getAllAccounts(displayedOrganizationWithPermissions as NestedOrganization)
    .map((acc) => acc.accountId));

  useEffect(() => {
    if (selectedDocumentsData) {
      setSelectedAccIds(
        getAllAccounts(selectedDocumentsData).map((acc) => acc.accountId),
      );
    }
  }, [
    selectedDocumentsData,
  ]);

  const checkAccount = (accountIds: string[]) => {
    dispatch(filterDocumentsDataByAccounts(accountIds));
    setSelectedAccIds(accountIds);
  };

  return (
    <MultiSelector
      dataSelectionValue='download-files-select-filters-account-multiselector'
      disabled={!getOptions(allAccounts).length}
      onOption={checkAccount}
      optionList={getOptions(allAccounts)}
      placeholder='account'
      selectedIds={selectedAccIds}
      style={style || { width: '250px' }}
      title='Account'
    />
  );
};

export { AccountSelector };
