/* eslint-disable react/jsx-key */
import {
  useVerificationData,
} from '../utils';
import authApi from 'api/auth';
import { LoginBackLink,
  LoginVerificationMethod } from 'components';
import { useAuthCheck,
  useDocumentTitle } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notificationsService } from 'services';
import { VerificationChannel } from 'types';

const { Email,
  Sms,
  WhatsApp } = VerificationChannel;
const availableChannels = [
  Email,
  Sms,
  WhatsApp,
];

export const ResendVerification = () => {
  const navigate = useNavigate();
  const [
    { channel,
      to },
    setVerificationData,
  ] = useVerificationData();
  const [
    isLoading,
    setIsLoading,
  ] = useState<VerificationChannel | null>(null);
  useAuthCheck();
  useDocumentTitle('Resend Code');

  const phone = useSelector<{ auth: { phone: string | undefined, }, }>(
    (state) => state.auth.phone,
  ) as string | undefined ||
  sessionStorage.getItem('phone') ||
  '';

  const channels = phone ? availableChannels : [
    Email,
  ];

  const onMethodChange = async (channelToResend: VerificationChannel) => {
    setIsLoading(channelToResend);
    try {
      await authApi.resendVerification({ channel: channelToResend });
      notificationsService.success('Code resent.');
      setVerificationData(to, channelToResend);
      navigate(-1);
    } catch {
      notificationsService.error('Something went wrong, please try to login again.');
      navigate('/auth/login');
    } finally {
      setIsLoading(null);
    }
  };

  return <div className='flex flex-col'>
    <div className='flex flex-col gap-0.5'>
      <LoginBackLink />
      {<div className='text-center text-title-1 dt:text-left dt:text-title-3'>
        Choose how you want to sign in:
      </div>}
      <span className='text-center text-title-4 text-black-700 dt:text-left dt:text-font-1'>
        A verification code will be sent to your selected option
      </span>
    </div>
    <div className='h-2' />
    <div className='flex flex-col gap-0.5 px-1 dt:px-0'>
      {channels.map((type) => <div className='flex flex-col gap-1'>
        <LoginVerificationMethod
          activeType={channel}
          isLoading={isLoading === type}
          onClick={onMethodChange}
          type={type}
        />
      </div>)}
    </div>
  </div>;
};
