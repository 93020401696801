import { type TableColumnDisplayValues } from '../../../types';
import { CHART_COLORS } from '../../Charts/ChartVariables';
import { HorBarChart } from '../../Charts/HorBarChart/HorBarChart';
import style from '../barChart.module.scss';
import { type FC } from 'react';

export type ContributionBarChartProps = {
  values: TableColumnDisplayValues,
};

export const ContributionBarChart: FC<ContributionBarChartProps> = ({
  values,
}) => {
  return <div className={style.container}>
    <HorBarChart
      colorList={CHART_COLORS.contributionBarChart} lowerBar={[
        {legendName: 'Contribution',
          value: values.contribution},
        {legendName: 'Unfunded commitment',
          value: values.commitment - values.contribution},
      ]}
      upperBar={[
        {legendName: 'Total commitment',
          value: values.commitment},
      ]}
    />
  </div>;
};
