// eslint-disable-next-line consistent-return
const logger = (store) => (next) => (action) => {
  if (!action.type) {
    return next(action);
  }

  // eslint-disable-next-line no-console
  console.log('type: ', action.type);
  // eslint-disable-next-line no-console
  console.log('payload: ', action.payload);
  // eslint-disable-next-line no-console
  console.log('currentState: ', store.getState());

  next(action);

  // eslint-disable-next-line no-console
  console.log('next state: ', store.getState());
};

export default logger;
