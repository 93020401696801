import './PieChartSimple.scss';
import { type ChartData } from '../ChartTypes';
import { CHART_COLORS,
  circularColor } from '../ChartVariables';
import { formatWithAbbreviation } from 'components/Pipes/numberPipes';
import { limitString } from 'components/Pipes/textPipes';

export class PieStyle implements React.CSSProperties {
  public backgroundImage = '';

  public background = 'rgb(240 242 244)';

  public setBackgroundImage (bgImage: string) {
    this.backgroundImage = bgImage;
  }
}

type LegendProps = {
  currency: string,
  decimals: number,
  isInPercents: boolean,
};

export type PieChartSimpleProps = {
  colorList?: string[],
  data?: ChartData[],
  legendProps?: LegendProps,
  maxLegendSize?: number,
  title?: string,
};

type PieChartHandledProps = {
  data: ChartData[],
  emptyState: boolean,
  legendProps: LegendProps,
  percents: number[],
  pieStyle: PieStyle,
  totalSum: number,
};

const sumValuesByLegendName = (data?: ChartData[]): ChartData[] => {
  const resultMap: { [legendName: string]: number, } = {};

  const filteredData = data?.filter((pd) => pd.value) || [];
  // Sum up the non-zero values based on legendName
  for (const {
    legendName,
    value,
  } of filteredData) {
    resultMap[legendName || 'No country'] = (resultMap[legendName] || 0) + value;
  }

  // Convert the resultMap to an array of PieChartData objects
  return Object.keys(resultMap)
    .map((legendName) => ({ legendName,
      value: resultMap[legendName] }))
    .sort((a, b) => b.value - a.value);
};

const handleData = ({
  data,
  maxLegendSize = 8,
  colorList = CHART_COLORS.default,
  legendProps = { currency: '',
    decimals: 2,
    isInPercents: true },
}: PieChartSimpleProps): PieChartHandledProps => {
  const pieStyle = new PieStyle();
  let percents: number[] = [];

  const degrees: number[] = [];
  let startDeg = 0;
  let bgImage = '';
  let finalData: ChartData[] = sumValuesByLegendName(data);
  const isEmptyState = Boolean(!finalData?.length);
  if (isEmptyState) {
    return { data: [],
      emptyState: isEmptyState,
      legendProps,
      percents,
      pieStyle,
      totalSum: 0,
    };
  }

  if (finalData.length > maxLegendSize) {
    const others = finalData.slice(maxLegendSize - 1, finalData.length);
    const othersSum = others.reduce(
      (partialSum, a) => partialSum + Math.abs(a.value),
      0,
    );
    finalData = finalData.slice(0, maxLegendSize - 1);
    finalData.push({ legendName: 'Others',
      value: othersSum });
  }

  const sum = finalData.reduce(
    (partialSum, a) => partialSum + Math.abs(a.value),
    0,
  );

  percents = [];
  for (const fd of finalData) {
    const valPcnt = Math.abs(fd.value) / sum;
    const valDeg = Math.trunc(valPcnt * 360);
    percents.push(valPcnt);
    degrees.push(startDeg + valDeg);
    startDeg += valDeg;
  }

  const degLength = degrees.length;
  for (const [
    index,
    deg,
  ] of degrees.entries()) {
    let degStr = ` ${deg}deg`;
    if (degLength === 1) {
      degStr = ' 0deg 0deg';
    } else if (index !== 0) {
      degStr = ` ${degrees[index - 1]}deg ${deg}deg`;
    }

    if (index !== degLength - 1) {
      degStr += ',';
    }

    bgImage += circularColor(index, finalData.length, colorList) + degStr;
  }

  bgImage = `conic-gradient(${bgImage})`;
  pieStyle.setBackgroundImage(bgImage);
  return {
    data: finalData,
    emptyState: isEmptyState,
    legendProps,
    percents,
    pieStyle,
    totalSum: sum,
  };
};

const renderLegend = (
  handledData: PieChartHandledProps,
  colorList: string[],
) => {
  const legend = handledData.legendProps;
  return (
    <div className='flex flex-col content-start items-start'>
      {handledData.data.map((hd, index) =>
        <div className='data-wrapper relative h-1.5 cursor-pointer items-center' key={hd.legendName}>
          <div
            className='size-0.5 rounded-[100%]'
            style={{
              background: circularColor(index, handledData.data.length, colorList),
            }}
          />
          <span className='text-font-2'>{limitString(hd.legendName, 20)}</span>
          <span className='text-font-2 font-bold'>
            {legend.isInPercents
              ? `${(handledData.percents[index] * 100).toFixed(1)}%`
              : `${legend.currency}${formatWithAbbreviation(
                hd.value,
                legend.decimals,
              )}`}
          </span>
          <div className='legend-tooltip'>
            <div className='tooltip-body'>
              {legend.isInPercents
                ? `${legend.currency}${formatWithAbbreviation(
                  hd.value,
                  legend.decimals,
                )}`
                : `${(handledData.percents[index] * 100).toFixed(1)}%`}
            </div>
            <div className='tooltip-tail' />
          </div>
        </div>,
      )}
    </div>
  );
};

// eslint-disable-next-line eslint-rules/no-direct-jsx-assignment
const emptyState = <span className='text-font-1 text-black-999/50'>No data</span>;
const renderPie = (pieStyle: PieStyle) => {
  return (
    <div
      className='pie flex flex-col content-center items-center justify-center'
      id='pie'
      style={{
        background: pieStyle.background,
        backgroundImage: pieStyle.backgroundImage,
      }}
    >
      <div className='hole' />
    </div>
  );
};

export const PieChartSimple: React.FC<PieChartSimpleProps> =
  ({
    title = '',
    data = [],
    colorList = CHART_COLORS.default,
    maxLegendSize = 8,
    legendProps,
  }: PieChartSimpleProps) => {
    const handledData = handleData({
      colorList,
      data,
      legendProps,
      maxLegendSize,
    });
    return (
      <div className='flex w-full items-start justify-between gap-1'>
        <div className='flex h-full max-w-[80%] flex-col gap-0.5'>
          <div className='flex items-center gap-0.375'>
            <span className='text-font-1 font-medium'>{title || null}</span>
            {!handledData.emptyState && <div className='blue-data-wrapper text-font-3'>
              {formatWithAbbreviation(handledData.totalSum, legendProps?.decimals)}
            </div>}
          </div>
          {handledData.emptyState
            ? emptyState
            : renderLegend(handledData, colorList)}
        </div>
        {renderPie(handledData.pieStyle)}
      </div>
    );
  };
