import VARIABLES from '../styles/variables';

export const isMobile = () => window.innerWidth < VARIABLES.mobileWidth;

export const isTablet = () => window.innerWidth < VARIABLES.tabletWidth;

export const isLandscape = () => window.innerWidth > window.innerHeight;

export const isMobileInLandscape = () =>
  isLandscape() && window.innerHeight < VARIABLES.mobileWidth;
