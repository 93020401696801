import {
  type DocumentsPendingState,
  type DocumentsStoreState,
  fetchDocuments,
  type Filters,
  type FilterStoreState,
  type PeriodFilters,
} from '../../../../store/slices';
import { toggleDocumentTypeInCategory } from '../../../../store/slices';
import {
  type DocumentDTO,
  type DocumentGroupType,
} from '../../../../types';
import style from './DocumentGroup.module.scss';
import { DocumentGroupName } from './DocumentGroupName';
import { Documents } from './Documents';
import {
  type FC,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { type AppDispatch } from 'store';

type DocumentGroupProps = DocumentGroupType & {
  isNewDocumentsCategory: boolean,
  topLevelCategoryId: string,
};

export const DocumentGroup: FC<DocumentGroupProps> = ({
  categoryId,
  documentGroupId,
  documentGroupName,
  numberOfDocuments,
  numberOfNewDocuments,
  documents,
  isOpen,
  topLevelCategoryId,
  isNewDocumentsCategory,
}) => {
  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  const periodFilter = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.usualDocumentsFilter) as PeriodFilters | null;

  const documentPendingState = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state
      .documents
      .documentsFetchQueue
      .find((pendingState) => pendingState.categoryId === categoryId &&
        pendingState.documentGroupId === documentGroupId &&
        pendingState.isNewDocumentsCategory === isNewDocumentsCategory),
  ) as DocumentsPendingState;
  const dispatch: AppDispatch = useDispatch();

  const toggleGroupTypeOnClick = (
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    event?.stopPropagation();
    dispatch(toggleDocumentTypeInCategory({
      documentGroupId,
      isNewDocumentsCategory,
      isOpen: !isOpen,
      topLevelCategoryId,
    }));
  };

  const toggleGroupTypeOnTab = (
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    event?.stopPropagation();

    if (event && event.key !== 'Tab') {
      return;
    }

    dispatch(toggleDocumentTypeInCategory({
      documentGroupId,
      isNewDocumentsCategory,
      isOpen: !isOpen,
      topLevelCategoryId,
    }));
  };

  const onDocumentsRetry = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    if (activeFilters && periodFilter) {
      dispatch(fetchDocuments({
        addToQueue: false,
        categoryId,
        fileType: documentGroupId,
        filter: {
          filterByNewDocuments: isNewDocumentsCategory,
          firmId: activeFilters.organizationId,
          ...activeFilters,
        },
        periodFilter,
      }));
    }
  };

  useEffect(() => {
    if (
      isOpen &&
      !documents &&
      !documentPendingState &&
      activeFilters && periodFilter
    ) {
      dispatch(fetchDocuments({
        addToQueue: true,
        categoryId,
        fileType: documentGroupId,
        filter: {
          filterByNewDocuments: isNewDocumentsCategory,
          firmId: activeFilters.organizationId,
          ...activeFilters,
        },
        periodFilter,
      }));
    }
  }, [
    periodFilter,
    categoryId,
    dispatch,
    activeFilters,
    documentGroupId,
    documentPendingState,
    documents,
    isNewDocumentsCategory,
    isOpen,
  ]);

  return (
    <div
      aria-expanded={isOpen}
      className='overflow-hidden'
      data-test={`document-group-${documentGroupName}`}
      onClick={toggleGroupTypeOnClick}
      onKeyDown={toggleGroupTypeOnTab}
      role='button'
      tabIndex={0}
    >
      {
        isOpen ?
          <div className={style.open}>
            <DocumentGroupName
              categoryId={categoryId}
              documentGroupId={documentGroupId}
              documentGroupName={documentGroupName}
              documents={documents as DocumentDTO[]}
              isOpen={isOpen}
              numberOfDocuments={numberOfDocuments}
              numberOfNewDocuments={numberOfNewDocuments}
            />
            <Documents
              categoryId={categoryId}
              documents={documents}
              documentsPendingState={documentPendingState}
              onDocumentsRetry={onDocumentsRetry}
            />
          </div> :
          <div className={style.closed}>
            <DocumentGroupName
              categoryId={categoryId}
              documentGroupId={documentGroupId}
              documentGroupName={documentGroupName}
              documents={documents as DocumentDTO[]}
              isOpen={isOpen}
              numberOfDocuments={numberOfDocuments}
              numberOfNewDocuments={numberOfNewDocuments}
            />
          </div>
      }
    </div>
  );
};
