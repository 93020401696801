import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // If the flag is not set in sessionStorage, redirect to login
    if (!sessionStorage.getItem('onLoginPage') && !sessionStorage.getItem('canResetPassword')) {
      navigate('/auth/login');
    }
  }, [
    navigate,
  ]);
};
