import { type FC } from 'react';
import { type VerificationChannel } from 'types';

export const channelIcons: {
  [k in VerificationChannel]: FC | null
} = {
  email: () => <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.623 14.3438C11.0332 14.6562 11.4922 14.8125 12 14.8125C12.5078 14.8125 12.9668 14.6562 13.377 14.3438L19.5 9.56836V16.2188C19.5 16.6094 19.3633 16.9414 19.0898 17.2148C18.8164 17.4883 18.4844 17.625 18.0938 17.625H5.90625C5.51562 17.625 5.18359 17.4883 4.91016 17.2148C4.63672 16.9414 4.5 16.6094 4.5 16.2188V9.56836L10.623 14.3438ZM4.96875 8.74805C4.65625 8.51367 4.5 8.19141 4.5 7.78125C4.5 7.39062 4.63672 7.05859 4.91016 6.78516C5.18359 6.51172 5.51562 6.375 5.90625 6.375H18.0938C18.4844 6.375 18.8164 6.51172 19.0898 6.78516C19.3633 7.05859 19.5 7.39062 19.5 7.78125C19.5 8.19141 19.3438 8.51367 19.0312 8.74805L12.8203 13.582C12.5664 13.7773 12.293 13.875 12 13.875C11.707 13.875 11.4336 13.7773 11.1797 13.582L4.96875 8.74805Z' fill='#060D14' />
  </svg>,
  none: null,
  sms: () => <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8.34736 19.7994C8.67681 19.7994 8.93465 19.6633 9.32856 19.3052L12.043 16.8916L16.4906 16.8987C18.6822 16.8987 20 15.5594 20 13.3893V7.70984C20 5.53258 18.6822 4.20044 16.4906 4.20044H7.5094C5.31782 4.20044 4 5.53258 4 7.70984V13.3893C4 15.5594 5.40376 16.8916 7.38765 16.8916H7.58818V18.9542C7.58818 19.4699 7.87466 19.7994 8.34736 19.7994Z' fill='#060D14' />
  </svg>,
  whatsapp: () => <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12.0001 4.31982C7.75879 4.31982 4.32007 7.75854 4.32007 11.9998C4.32007 13.4406 4.72477 14.7839 5.41507 15.9361L4.38882 19.6798L8.21257 18.6761C9.33086 19.3119 10.6218 19.6798 12.0001 19.6798C16.2413 19.6798 19.6801 16.2411 19.6801 11.9998C19.6801 7.75854 16.2413 4.31982 12.0001 4.31982ZM9.37132 8.41732C9.49612 8.41732 9.62435 8.41656 9.73507 8.42232C9.87203 8.42552 10.0211 8.43555 10.1638 8.75107C10.3334 9.12611 10.7027 10.067 10.7501 10.1623C10.7974 10.2577 10.831 10.37 10.7651 10.4948C10.7023 10.6228 10.6697 10.7003 10.5776 10.8136C10.4822 10.9237 10.3777 11.0604 10.2913 11.1436C10.196 11.2389 10.0975 11.3435 10.2076 11.5336C10.3176 11.7237 10.6999 12.3468 11.2651 12.8498C11.9915 13.4988 12.6043 13.6982 12.7951 13.7936C12.9858 13.8889 13.0962 13.8741 13.2063 13.7461C13.3196 13.6213 13.6821 13.193 13.8101 13.0023C13.9349 12.8116 14.0623 12.8446 14.2351 12.9073C14.4104 12.97 15.3456 13.4307 15.5363 13.5261C15.727 13.6214 15.8521 13.6686 15.9001 13.7461C15.9493 13.8261 15.9494 14.2069 15.7913 14.6511C15.6332 15.0946 14.857 15.5235 14.5088 15.5536C14.1575 15.5862 13.8295 15.7115 12.2251 15.0798C10.2897 14.3176 9.06918 12.3353 8.97382 12.2073C8.87846 12.0825 8.19882 11.1767 8.19882 10.2423C8.19882 9.30472 8.69041 8.84554 8.86257 8.65482C9.03793 8.4641 9.24332 8.41732 9.37132 8.41732Z' fill='black' />
  </svg>,
};

export const ArrowRightIcon = () => <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
  <path clipRule='evenodd' d='M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z' fill='#4A5C65' fillRule='evenodd' />
</svg>;

