import EmptyFile from '../../assets/icons/collapse/empty-file.svg?react';
import style from './NoFilesMessage.module.scss';
import { type FC } from 'react';

const DISPLAYED_MESSAGE_FILES = 'You don\'t have any shared documents yet.';
const DISPLAYED_MESSAGE_REPORTS = 'You don\'t have any shared reports yet.';
const DISPLAYED_MESSAGE_CAPITAL_ACCOUNTS = 'You don\'t have any shared Capital Accounts yet.';

const NoFilesMessage: FC<
{ capitalAccounts?: boolean, reports?: boolean, }> = ({
  capitalAccounts = false,
  reports = false }) => {
  const renderMessage = () => {
    if (reports) {
      return DISPLAYED_MESSAGE_REPORTS;
    }

    if (capitalAccounts) {
      return DISPLAYED_MESSAGE_CAPITAL_ACCOUNTS;
    }

    return DISPLAYED_MESSAGE_FILES;
  };

  return (
    <div className={style.noFilesContainer}>
      <div className={style.messageContainer}>
        <EmptyFile />
        <div className={style.message}>
          {renderMessage()}
        </div>
      </div>
    </div>
  );
};

export default NoFilesMessage;
