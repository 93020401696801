import style from './DocumentCategoryName.module.scss';
import ClosedChevron from 'assets/icons/documents/closed-chevron.svg?react';
import OpenedChevron from 'assets/icons/documents/opened-chevron.svg?react';
import { Tooltip } from 'components/InteractiveUIControls/Tooltip/Tooltip';
import { type FC } from 'react';

type DocumentCategoryNameProps = {
  categoryName: string,
  isNewDocumentsCategory: boolean,
  isOpen: boolean,
  totalNumberOfDocuments: number,
};

export const DocumentCategoryName: FC<DocumentCategoryNameProps> = ({
  categoryName,
  isOpen,
  totalNumberOfDocuments,
  isNewDocumentsCategory,
}) => {
  return (
    <>
      {
        isOpen ?
          <div className={style.openNameContainer}>
            <div className={style.openNameLeft}>
              <OpenedChevron />
              <h3 className={style.categoryName}>
                {categoryName}
              </h3>
            </div>
          </div> :
          <div className={style.closedNameContainer}>
            <div className={style.closedNameLeft}>
              <ClosedChevron />
              <h3 className={style.categoryName}>
                {categoryName}
              </h3>
            </div>
            {!isNewDocumentsCategory && <div className={style.closedNameRight}>
              <div className='tooltip-wrapper black w-fit'>
                <Tooltip>{`${totalNumberOfDocuments} document${totalNumberOfDocuments === 1 ? '' : 's'}`}</Tooltip>
                <div className={style.closedNumDocumentsContainer}>
                  <p className={style.totalNumberOfDocuments}>
                    {totalNumberOfDocuments}
                  </p>
                </div>
              </div>
            </div>}
          </div>
      }
    </>
  );
};
