/* eslint-disable max-len */
import style from './Migration.module.scss';
import userApi from 'api/user';
import { LoginBackLink,
  LoginButton,
  LoginHeading,
  LoginParagraph,
  LoginPasswordInput,
  LoginPhoneInput } from 'components';
import { useDocumentTitle } from 'hooks';
import { useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { passwordRequirements,
  strongPasswordMessage } from 'resources/constants';
import { notificationsService } from 'services';
import { type AppDispatch } from 'store';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

export const Migration = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  // @ts-expect-error state not typed :(
  const isLoading = useSelector((state) => state.global.loading) as boolean;

  const [
    phone,
    setPhone,
  ] = useState('');
  const [
    password,
    setPassword,
  ] = useState('');
  const [
    confirmPassword,
    setConfirmPassword,
  ] = useState('');

  useDocumentTitle('Sign Up');

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!phone) {
      notificationsService.error('Phone number is required');
      return;
    }

    if (password !== confirmPassword) {
      notificationsService.error('Password and confirm password does not match');
      return;
    }

    if (!isStrongPassword(password, passwordRequirements)) {
      notificationsService.error(strongPasswordMessage);
      return;
    }

    // eslint-disable-next-line no-warning-comments -- Disabled for now, apply fixes later accordingly
    // TODO: remove token
    const payload = { password,
      phone: `+${phone}` };
    dispatch(userApi.migration(payload, navigate));
  };

  return <div className={style.loginWrapper}>
    <LoginBackLink />
    <LoginHeading>Update Password and Phone Number</LoginHeading>
    <LoginParagraph>We have upgraded Investor Portal, as part of this upgrade, you are required to update password and your mobile number for a two-factor authenication.</LoginParagraph>
    <form onSubmit={onSubmit}>
      <div className={style.inputsWrapper}>
        <LoginPhoneInput name='migration-phone-input' onChange={setPhone} value={phone} />
        <LoginPasswordInput name='migration-create-password-input' onChange={setPassword} placeholder='Create Password' value={password} />
        <LoginPasswordInput name='migration-confirm-password-input' onChange={setConfirmPassword} placeholder='Confirm Password' value={confirmPassword} />
      </div>
      <LoginButton isLoading={isLoading} name='migration-button' text='Sign In' type='submit' />
    </form>
  </div>;
};
