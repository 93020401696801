import { type FC,
  useCallback,
  useMemo} from 'react';
import { createContext,
  useState } from 'react';

type ModalActivationContextType = {
  activeDocumentGroupName: string | null,
  fileViewModal: {fileName: string | null, id: string | null, show: boolean,},
  showDownloadsModal: boolean,
  showPerformanceReportsModal: boolean,
  showSupportModal: boolean,
  toggleDownloadsModal: (on: boolean, documentGroupName: string | null) => void,
  toggleFileViewModal: (on: boolean, fileData?:
  {fileName: string | null, id: string | null,}) => void,
  togglePerformanceReportsModal: (on: boolean) => void,
  toggleSupportModal: (on: boolean) => void,
};

type ModalActivationProviderProps = {
  children?: React.ReactNode,
};

const MODAL_ACTIVATION_INITIAL_STATE: ModalActivationContextType = {
  activeDocumentGroupName: null,
  fileViewModal: {
    fileName: null,
    id: null,
    show: false,
  },
  showDownloadsModal: false,
  showPerformanceReportsModal: false,
  showSupportModal: false,
  toggleDownloadsModal: () => {},
  toggleFileViewModal: () => {},
  togglePerformanceReportsModal: () => {},
  toggleSupportModal: () => {},
};

export const ModalActivationContext = createContext<ModalActivationContextType>(
  MODAL_ACTIVATION_INITIAL_STATE,
);

export const ModalActivationProvider: FC<
ModalActivationProviderProps> = ({children}) => {
  const [
    showSupportModal,
    setShowSupportModal,
  ] = useState(false);
  const [
    showDownloadsModal,
    setShowDownloadsModal,
  ] = useState(false);
  const [
    activeDocumentGroupName,
    setActiveDocumentGroupName,
  ] = useState<string | null>(null);
  const [
    showPerformanceReportsModal,
    setShowPerformanceReportsModal,
  ] = useState(false);

  const [
    fileViewModal,
    setFileViewModal,
  ] = useState<{fileName: string | null, id: string | null, show: boolean,}>({
    fileName: null,
    id: null,
    show: false,
  });

  const toggleFileViewModal = useCallback(
    (on: boolean, fileData?: {fileName: string | null, id: string | null,}) => {
      setFileViewModal({
        fileName: on && fileData ? fileData.fileName : null,
        id: on && fileData ? fileData.id : null,
        show: on,
      });
    }, [
      setFileViewModal,
    ],
  );

  const toggleDownloadsModal = useCallback(
    (on: boolean, group: string | null) => {
      setShowDownloadsModal(on);
      setActiveDocumentGroupName(group);
    }, [
      setShowDownloadsModal,
      setActiveDocumentGroupName,
    ],
  );

  const toggleSupportModal = useCallback(
    (on: boolean) => setShowSupportModal(on), [
      setShowSupportModal,
    ],
  );

  const togglePerformanceReportsModal = useCallback(
    (on: boolean) => setShowPerformanceReportsModal(on), [
      setShowPerformanceReportsModal,
    ],
  );

  const values = useMemo(() => ({
    activeDocumentGroupName,
    fileViewModal,
    showDownloadsModal,
    showPerformanceReportsModal,
    showSupportModal,
    toggleDownloadsModal,
    toggleFileViewModal,
    togglePerformanceReportsModal,
    toggleSupportModal,
  }), [
    activeDocumentGroupName,
    showDownloadsModal,
    showPerformanceReportsModal,
    showSupportModal,
    fileViewModal,
    toggleFileViewModal,
    toggleDownloadsModal,
    toggleSupportModal,
    togglePerformanceReportsModal,
  ]);

  return <ModalActivationContext.Provider value={values}>
    { children }
  </ModalActivationContext.Provider>;
};

