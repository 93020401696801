import { type TableColumnDisplayValues } from '../../../types';
import {type TotalsChartItem } from '../CapitalAccountsTotalsChart';
import { CapitalAccountsTotalsChart } from '../CapitalAccountsTotalsChart';
import { type FC } from 'react';

export const NavTotals: FC<TableColumnDisplayValues> = ({
  contribution,
  distribution,
  unrealizedGainLoss,
  realizedGainLoss,
  other,
}) => {
  const netGainLoss = unrealizedGainLoss + realizedGainLoss + other;
  const totalValue = contribution + distribution + netGainLoss;
  const items: TotalsChartItem[] = [
    {
      legend: 'Contribution (Net due/advance)',
      value: contribution,
    },
    ...distribution === 0 ? [] : [
      {
        legend: 'Distribution',
        value: distribution,
      },
    ],
    {
      legend: 'Net Gain/(Loss)',
      value: netGainLoss,
    },
  ];

  return <CapitalAccountsTotalsChart
    items={items}
    title='Partners capital (NAV)'
    totalValue={totalValue}
  />;
};
