/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import style from './error.module.scss';
import { useDocumentTitle } from 'hooks';
import { useNavigate } from 'react-router-dom';

export const Error = () => {
  const navigate = useNavigate();

  useDocumentTitle('Error');

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  return <div className={style.container}>
    <div className={style.block}>
      <div className={style.status}>404</div>
      <div className={style.hint}>Page not found!</div>
      <div
        className={style.btn}
        onClick={goBack}
      >Go back</div>
    </div>
  </div>;
};
