import { type NestedOrganization } from '../types';
import { Api } from './Api';
import { Alert,
  unauthorized } from './utils';
import {
  isAxiosError } from 'axios';

const api = new Api('/api/v2/firms');
const publicApi = new Api('/api/v2/public/firms');

const getNestedOrganizations = async () => {
  try {
    const resp = await api.get<NestedOrganization[]>('');
    return resp.data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve organizations');
    }

    return [];
  }
};

const getCurrentOrganizations = async (id: string) => {
  try {
    const resp = await api.get<NestedOrganization[]>(`/${id}`);
    return resp.data[0];
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve organizations');
    }

    return null;
  }
};

// eslint-disable-next-line consistent-return
const getOrgData = async (dataToken: string) => {
  try {
    const path = `/firm/data?dataToken=${dataToken}`;
    const res = await publicApi.get<{ firmId: string, logo: string, }>(path);
    return res;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    } else {
      Alert('Failed to get organization logo');
    }
  }
};

export const organizationsApi = {
  getCurrentOrganizations,
  getNestedOrganizations,
  getOrgData,
};
