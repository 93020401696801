/* eslint-disable max-len */
import ChevronLeft from '../../../assets/icons/global/chevron-left.svg?react';
import {Blocks} from '../../../components';
import style from './about.module.scss';
import {useDocumentTitle} from 'hooks';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export const About = () => {
  const {firm: {details}} = useSelector((state) => state);
  const navigate = useNavigate();

  useDocumentTitle('About');

  return (
    <div className={style.container}>
      <Blocks
        onClick={() => navigate('/firm/settings')}
        // eslint-disable-next-line react/forbid-component-props
        title={<><ChevronLeft style={{marginRight: 10}} /> About</>}
      >
        <div className={style.servc}>
          <p>
            Orca
          </p>
          Orca provides a unique business process solution to VCs and private equities, these include
          all services from back-office to execution, CFO, controller, custom-made software, portfolio management,
          bookkeeping, legal advisory and investor relations by highly experienced venture capital industry
          professionals who are leaders in their field, with a wide-ranging perspective.
        </div>
        <div className={style.portal}>
          <p className={style.header}>
            Orca Portal
          </p>
          Orca has created a unique proprietary software developed especially for VC and PE requirements.
          The digital portal is maintained by our fund administrator and will serve as a secure and efficient hub for
          all the data related to your investment in the firm, such as reports, capital accounts, capital calls,
          financial statements, K1 and other documents relating to your investment.
        </div>
        {details.description ? <div className={style.description}>
          <p>{details.firmName}</p>
          <p>{details.description}</p>
        </div> : ''}
      </Blocks>
    </div>

  );
};
