import { Svgicon } from 'components/Svgicon/Svgicon';
import { Link } from 'react-router-dom';

type Props = {
  className?: string,
  to?: string,
};

export const LoginBackLink = ({ className = '',
  to = '/auth/login'}: Props) => {
  return (
    <Link
      className={
        `flex items-center justify-center gap-0.125 
        text-black-500 hover:text-label-g-300 active:text-label-g-400 dt:justify-start
        ${className}`
      }
      to={to}
    >
      <Svgicon className='size-0.875' id='arrow-left-2f' />
      Back
    </Link>
  );
};
