import {actionCreator} from 'api/utils';
import jwtDecode from 'jwt-decode';
import {DateTime} from 'luxon';
import {authActionTypes,
  rootActionTypes} from 'store/actions';
import {getItemsFromStorage,
  storeItemsInStorage} from 'types';

const SET_TOKEN_TYPES = [
  authActionTypes.SET_ACCESS_TOKEN,
  authActionTypes.SET_REFRESH_TOKEN,
];

const isActionOfSetTokenType = (action) => {
  if (!action.type) {
    return false;
  }

  // eslint-disable-next-line unicorn/prefer-includes
  return SET_TOKEN_TYPES.some((type) => type === action.type);
};

// middleware that intercepts dispatch and checks if action is to set token in storage
const setTokensInStorage = ({dispatch}) => (next) => (action) => {
  // checks if action is of one of the set token types
  if (!isActionOfSetTokenType(action)) {
    return next(action);
  }

  const {setInStorage} = action.payload;

  // checks if set token action is to set in session storage
  if (!setInStorage) {
    return next(action);
  }

  if (action.type === authActionTypes.SET_REFRESH_TOKEN) {
    storeItemsInStorage(
      {refresh_token: action.payload.refresh_token},
      sessionStorage,
    );
    return next(action);
  }

  const {exp} = jwtDecode(action.payload.access_token);
  const expiryDate = DateTime.fromJSDate(new Date(exp * 1_000), {zone: 'Israel'}).toISO();
  const expiresIn = DateTime.fromISO(expiryDate)
    .diff(DateTime.now()).toMillis();
  storeItemsInStorage(
    {
      access_token: action.payload.access_token,
      exp: expiryDate,
    },
    sessionStorage,
  );

  setTimeout(() => {
    const {
      exp: date,
    } = getItemsFromStorage([
      'exp',
    ], sessionStorage);

    if (
      !date ||
      !DateTime.fromISO(date).isValid ||
      DateTime.fromISO(date).equals(DateTime.fromISO(expiryDate))
    ) {
      // if no expiry date or invalid date or stored date is the same as previous date, can just kick out user.
      Promise.resolve()
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(() => {
          sessionStorage.clear();
          dispatch(actionCreator(rootActionTypes.CLEAR_STORE, {}));
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(() => window.location.reload());
    }
  }, expiresIn);

  return next(action);
};

export default setTokensInStorage;
