import { type RefObject,
  useCallback} from 'react';
import {
  useEffect,
  useState } from 'react';

type ModalPosition = {
  left: number,
  top: number,
  width: number,
};

const INITIAL_MODAL_POSITION = {
  left: 0,
  top: 0,
  width: 0,
};

export const useModalPosition = <T extends HTMLElement | SVGSVGElement>(
  modalActivated: boolean,
  ref: RefObject<T>,
  ...refsToTrack: Array<RefObject<T>>
) => {
  const [
    optionsPosition,
    setOptionsPosition,
  ] = useState<ModalPosition>(INITIAL_MODAL_POSITION);

  const updatePosition = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setOptionsPosition({
        left: rect.left,
        top: rect.bottom,
        width: rect.width,
      });
    }
  }, [
    ref,
  ]);

  useEffect(() => {
    if (!modalActivated || !ref.current) {
      return () => {};
    }

    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          updatePosition();
        }
      }
    }, {
      root: ref.current,
      threshold: 0,
    });

    observer.observe(ref.current);

    const containerObservers = refsToTrack.map((containerRef) => {
      if (!containerRef.current) {
        return null;
      }

      const containerObserver = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            updatePosition();
          }
        }
      }, {
        root: containerRef.current,
        threshold: 0,
      });

      return containerObserver;
    });

    for (const containerObserver of containerObservers) {
      containerObserver?.observe(ref.current);
    }

    return () => {
      observer.disconnect();
      for (const containerObserver of containerObservers) {
        containerObserver?.disconnect();
      }
    };
  }, [
    modalActivated,
    ref,
    refsToTrack,
    updatePosition,
  ]);

  return optionsPosition;
};
