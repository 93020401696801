/* eslint-disable react/prop-types */
import Checked from '../../assets/icons/settings/checked.svg?react';
import style from './style.module.scss';

export const SuccessNotification = ({data,
  visible}) => {
  return <div className={[
    style.container,
    'success-notification',
  ].join(' ')}
  >
    <div className={style.block}>
      {
        data ?
          Object.entries(data).map(([
            key,
            value,
          ], index) => {
            return <div
              className={style.list}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <div className={style.header}>
                {/* eslint-disable-next-line react/forbid-component-props */}
                {key} has been changed <Checked style={{marginLeft: 10}} />
              </div>
              <div style={{
                alignItems: 'center',
                display: 'flex',
                fontSize: 20,
                justifyContent: 'space-between',
              }}
              >
                {
                  visible ? <div className={style['changed-data']}>{value}</div> : null
                }
              </div>
            </div>;
          }) :
          ''
      }
    </div>
  </div>;
};
