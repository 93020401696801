/* eslint-disable react/prop-types */
/* eslint-disable unicorn/prefer-code-point */
/* eslint-disable canonical/id-match */
/* eslint-disable unicorn/prefer-query-selector */
import style from './formList.module.scss';

export const FormList = ({label,
  defaultValue,
  onChangeBtn,
  onSave,
  onCancel,
  onChange,
  name,
  hasInputField = true}) => {
  const hidFields = (event) => {
    const target = event.currentTarget.closest('.' + style.container);
    const save_cancelContainer = target.getElementsByClassName(style['save-cancel'])[0];
    const change = target.getElementsByClassName(style.change)[0];
    const inputContainer = target.getElementsByClassName(style['form-text'])[0];
    const input = target.getElementsByClassName(style.input)[0];
    change.classList.remove(style.hidden);
    save_cancelContainer.classList.remove(style.visible);
    save_cancelContainer.classList.add(style.hidden);
    inputContainer.classList.remove(style.hidden);
    input.classList.add(style.hidden);
  };

  const changeButtonEvent = (event) => {
    if (!hasInputField) {
      if (onChangeBtn) {
        onChangeBtn();
      }

      return;
    }

    const target = event.currentTarget.closest('.' + style.container);
    const save_cancelContainer = target.getElementsByClassName(style['save-cancel'])[0];

    const allSaveCancelContainers = [
      ...document.getElementsByClassName(style['save-cancel']),
    ];
    const allChangeBtns = [
      ...document.getElementsByClassName(style.change),
    ];
    const allInputContainers = [
      ...document.getElementsByClassName(style['form-text']),
    ];
    const allInputs = [
      ...document.getElementsByClassName(style.input),
    ];

    for (const item of allSaveCancelContainers) {
      item.classList.add(style.hidden);
    }

    for (const item of allChangeBtns) {
      item.classList.remove(style.hidden);
    }

    for (const item of allInputContainers) {
      item.classList.remove(style.hidden);
    }

    for (const item of allInputs) {
      item.classList.add(style.hidden);
    }

    event.currentTarget.classList.add(style.hidden);
    save_cancelContainer.classList.remove(style.hidden);
    save_cancelContainer.classList.add(style.visible);

    const inputContainer = target.getElementsByClassName(style.text)[0];
    const formText = inputContainer.getElementsByClassName(style['form-text'])[0];

    formText.classList.add(style.hidden);
    inputContainer.getElementsByClassName(style.input)[0].classList
      .remove(style.hidden);
    const input = target.getElementsByClassName(style.input)[0];
    input.focus();
    if (onChangeBtn) {
      onChangeBtn(input);
    }
  };

  const onSaveEvents = (event) => {
    const target = event.currentTarget.closest('.' + style.container);
    const input = target.getElementsByClassName(style.input)[0];
    if (onSave) {
      onSave(input.name);
    }

    hidFields(event);
  };

  const onCancelEvents = (event) => {
    const target = event.currentTarget.closest('.' + style.container);
    const input = target.getElementsByClassName(style.input)[0];
    onCancel(input.name);
    hidFields(event);
  };

  return <div className={style.container}>
    <div className={style['form-block']}>
      <div className={style.label}>
        {label}
      </div>
      <div className={style.text}>
        <input
          className={[
            style.input,
            style.hidden,
          ].join(' ')}
          defaultValue={defaultValue}
          name={name}
          onChange={onChange}
          onKeyPress={(event) => {
            if (name === 'phone') {
              const ch = String.fromCharCode(event.which);
              if (!/^[\d ()+]+$/u.test(ch)) {
                event.preventDefault();
              }
            }
          }}
          type='text'
        />
        <div
          className={[
            style['form-text'],
          ].join(' ')}
        >
          {defaultValue}
        </div>
      </div>
    </div>
    {name === 'email' ?
      null :
      <div className={style['btn-container']}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className={style.change} id={'change-' + name} onClick={changeButtonEvent}>
          Change
        </div>
        <div className={[
          style['save-cancel'],
          style.hidden,
        ].join(' ')}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className={[
              style.save,
              style.btn,
            ].join(' ')}
            id={'save-' + name}
            onClick={onSaveEvents}
          >
            Save
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className={[
              style.cancel,
              style.btn,
            ].join(' ')}
            id={'cancel-' + name}
            onClick={onCancelEvents}
          >
            Cancel
          </div>
        </div>
      </div>}
  </div>;
};
