/* eslint-disable @typescript-eslint/naming-convention */
import {
  getItemsFromStorage,
  storeItemsInStorage,
} from '../types';
import { type FC,
  useMemo} from 'react';
import { createContext,
  useState } from 'react';

enum SESSION_STORAGE_FILTER_FIELDS {
  ACCOUNT_IDS = '_accountIds',
  FIRM_ID = '_firmId',
  FUND_ID = '_fundId',
  SHOW_SELECTOR = '_showSelector'
}

type FilterInStorage = {
  [k in SESSION_STORAGE_FILTER_FIELDS]: string
};

const getFromSessionStorage = (
  key: SESSION_STORAGE_FILTER_FIELDS,
) => {
  const {
    _accountIds,
    _firmId,
    _fundId,
  } = getItemsFromStorage<FilterInStorage>([
    key,
  ], sessionStorage);

  let item: string | undefined;
  if (key === SESSION_STORAGE_FILTER_FIELDS.ACCOUNT_IDS) {
    item = _accountIds;
  } else if (key === SESSION_STORAGE_FILTER_FIELDS.FIRM_ID) {
    item = _firmId;
  } else if (key === SESSION_STORAGE_FILTER_FIELDS.FUND_ID) {
    item = _fundId;
  }

  if (!item) {
    return item;
  }

  return JSON.parse(item);
};

const storeInSessionStorage = (
  key: SESSION_STORAGE_FILTER_FIELDS, value: string[] | string,
) => {
  storeItemsInStorage<FilterInStorage>(
    { [key]: JSON.stringify(value)},
    sessionStorage,
  );
};

const checkIfFundIdExistsForFirm = (
  fundIdToCheck: string, fundIds: string[],
) => {
  return fundIds.includes(fundIdToCheck);
};

type FiltersContextType = {
  accountIds: Set<string>,
  checkIfFundIdExistsForFirm: (
    fundIdToCheck: string, fundIds: string[],
  ) => boolean,
  firmId: string,
  fundId: string,
  setAccountIds: (...newAccountIds: string[]) => void,
  setFirmId: (firmId: string) => void,
  setFundId: (fundId: string) => void,
};

type FiltersProviderProps = {
  children?: React.ReactNode,
};

const FILTERS_INITIAL_STATE = {
  accountIds: getFromSessionStorage(SESSION_STORAGE_FILTER_FIELDS.ACCOUNT_IDS) ?
    new Set<string>(getFromSessionStorage(
      SESSION_STORAGE_FILTER_FIELDS.ACCOUNT_IDS,
    ) as string[]) :
    new Set<string>(),
  checkIfFundIdExistsForFirm: () => false,
  firmId: getFromSessionStorage(SESSION_STORAGE_FILTER_FIELDS.FIRM_ID) ?
    getFromSessionStorage(SESSION_STORAGE_FILTER_FIELDS.FIRM_ID) as string :
    '',
  fundId: getFromSessionStorage(SESSION_STORAGE_FILTER_FIELDS.FUND_ID) ?
    getFromSessionStorage(SESSION_STORAGE_FILTER_FIELDS.FUND_ID) as string :
    '',
  setAccountIds: () => {},
  setFirmId: () => {},
  setFundId: () => {},
};

export const FiltersContext = createContext<FiltersContextType>(
  FILTERS_INITIAL_STATE,
);

export const FiltersProvider: FC<FiltersProviderProps> = ({children}) => {
  const [
    firmIdInContext,
    setFirmIdInContext,
  ] = useState(FILTERS_INITIAL_STATE.firmId);
  const [
    fundIdInContext,
    setFundIdInContext,
  ] = useState(FILTERS_INITIAL_STATE.fundId);
  const [
    accountIdsInContext,
    setAccountIdsInContext,
  ] = useState(FILTERS_INITIAL_STATE.accountIds);

  const setFirmId = (firmId: string): void => {
    setFirmIdInContext(firmId);
    storeInSessionStorage(SESSION_STORAGE_FILTER_FIELDS.FIRM_ID, firmId);
  };

  const setFundId = (fundId: string): void => {
    setFundIdInContext(fundId);
    storeInSessionStorage(SESSION_STORAGE_FILTER_FIELDS.FUND_ID, fundId);
  };

  const setAccountIds = (...newAccountIds: string[]): void => {
    setAccountIdsInContext(new Set<string>(newAccountIds));
    storeInSessionStorage(
      SESSION_STORAGE_FILTER_FIELDS.ACCOUNT_IDS, newAccountIds,
    );
  };

  const values = useMemo(() => ({
    accountIds: accountIdsInContext,
    checkIfFundIdExistsForFirm,
    firmId: firmIdInContext,
    fundId: fundIdInContext,
    setAccountIds,
    setFirmId,
    setFundId,
  }), [
    accountIdsInContext,
    firmIdInContext,
    fundIdInContext,
  ]);

  return (
    <FiltersContext.Provider
      value={values}
    >
      {children}
    </FiltersContext.Provider>
  );
};
