import { calcSubtotalRow,
  getInitialTableColumnDisplayValues,
  setSummaryStatisticsByFund } from '../../../../../store/slices';
import { type ComputedCapitalAccountInfoAccount } from '../../../../../types';
import { formatNumber } from '../../../../Pipes/numberPipes';
import { type TotalsForFilterColumns } from '../CapitalAccountsFundTable';
import { Table } from 'antd';
import { type FC,
  useEffect,
  useState } from 'react';
import { useDispatch } from 'react-redux';

export type CapitalAccountsFundTableSubtotalRowProps = {
  data: ComputedCapitalAccountInfoAccount[],
  fundId: string,
  fundName: string,
  selectedAccountPeriodMap: Record<string, string>,
  totalsForFilterColumns: TotalsForFilterColumns,
  withoutSubtotalSummary?: boolean,
};

export const CapitalAccountsFundTableSubtotalRow:
FC<CapitalAccountsFundTableSubtotalRowProps> = ({
  data,
  fundId,
  fundName,
  selectedAccountPeriodMap,
  withoutSubtotalSummary = false,
  totalsForFilterColumns,
}) => {
  const dispatch = useDispatch();
  const [
    columnValues,
    setColumnValues,
  ] = useState(getInitialTableColumnDisplayValues(fundName));

  useEffect(() => {
    const newSubtotalColumnValues = calcSubtotalRow(
      data,
      selectedAccountPeriodMap,
      fundName,
    );
    dispatch(setSummaryStatisticsByFund({
      fundId,
      subtotal: newSubtotalColumnValues,
    }));
    setColumnValues(newSubtotalColumnValues);
  }, [
    data,
    dispatch,
    fundId,
    fundName,
    selectedAccountPeriodMap,
  ]);
  return withoutSubtotalSummary ? null : <Table.Summary.Row className='bg-black-100'>
    <Table.Summary.Cell className='!border-none !bg-black-100 font-semibold' index={0} />
    <Table.Summary.Cell className='min-w-[21.25rem] !border-none !bg-black-100' index={1}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>Subtotal</p>
    </Table.Summary.Cell>
    <Table.Summary.Cell className='border-b-none !!bg-black-100' index={2} />
    <Table.Summary.Cell className='!border-none !bg-black-100' index={3}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>{formatNumber(columnValues.commitment, 0)}</p>
    </Table.Summary.Cell>
    <Table.Summary.Cell className='border-b-none !bg-white-999' index={4} />
    <Table.Summary.Cell className='!border-none !bg-black-100' index={5}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>{formatNumber(columnValues.contribution, 0)}</p>
    </Table.Summary.Cell>
    {totalsForFilterColumns.distribution !== 0 && <Table.Summary.Cell className='!border-none !bg-black-100' index={6}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>{formatNumber(columnValues.distribution, 0)}</p>
    </Table.Summary.Cell>}
    <Table.Summary.Cell className='!border-none !bg-black-100' index={7}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>{formatNumber(columnValues.unrealizedGainLoss, 0)}</p>
    </Table.Summary.Cell>
    {totalsForFilterColumns.realizedGainLoss !== 0 && <Table.Summary.Cell className='!border-none !bg-black-100' index={8}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>{formatNumber(columnValues.realizedGainLoss, 0)}</p>
    </Table.Summary.Cell>}
    <Table.Summary.Cell className='!border-none !bg-black-100' index={9}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>{formatNumber(columnValues.other, 0)}</p>
    </Table.Summary.Cell>
    <Table.Summary.Cell className='border-b-none !bg-white-999' index={10} />
    <Table.Summary.Cell className='!border-none !bg-black-100' index={11}>
      <p className='my-0 px-0.5 text-title-5 font-medium'>{formatNumber(columnValues.nav, 0)}</p>
    </Table.Summary.Cell>
  </Table.Summary.Row>;
};
