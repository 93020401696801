import style from './Spinner.module.scss';

const Spinner = () => {
  return (
    <div className={style.spinnerOverlay}>
      <div className={style.spinnerContainer} />
    </div>
  );
};

export { Spinner };
