import style from './LoginParagraph.module.scss';

type Props = {
  children: React.ReactNode,
  className?: string,
};

export const LoginParagraph = ({ children,
  className = '' }: Props) => {
  return (
    <p className={
      [
        style.loginParagraph,
        className,
      ].join(' ')
    }
    >
      {children}
    </p>
  );
};
