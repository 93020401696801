export type SelectOption = {
  display: string,
  id: string,
};

export type SelectDisplayOption = {
  id: string,
  name: string,
  selected: boolean,
};

export const INITIAL_SELECT_OPTION_STATE: SelectOption = {
  display: '',
  id: '',
};

export type TreeSelectOption = {
  children: SelectDisplayOption[],
  id: string,
};

export type TreeSelectDisplayOption = {
  children?: TreeSelectDisplayOption[],
  id: string,
  name: string,
  selected: boolean,
};

export const isOptionEmpty = (option: SelectOption) => {
  return option.display === INITIAL_SELECT_OPTION_STATE.display &&
    option.id === INITIAL_SELECT_OPTION_STATE.id;
};
